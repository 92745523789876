import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "sonner";
import "../styles/excersiseEntry.css";
import baseUrl from "../baseUrl"; // Assuming you have a baseUrl file
import Loader from "./loader"; // Import the Loader component
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import emoji1 from "../assets/images/EEEmoji1.svg";
import emoji2 from "../assets/images/EEEmoji2.svg";
import emoji3 from "../assets/images/EEEmoji3.svg";
import emoji4 from "../assets/images/EEEmoji4.svg";
import emoji5 from "../assets/images/EEEmoji5.svg";

const emojis = [
  { image: emoji1, text: "Auchihuahua... deutlich verschlechtert." },
  { image: emoji2, text: "Meh... nicht so toll." },
  { image: emoji3, text: "Solide" },
  { image: emoji4, text: "So kann es weitergehen!" },
  { image: emoji5, text: "VIEL BESSER!!!" },
];

const ExerciseEntryPopup = ({
  isOpen,
  onClose,
  subTestId,
  subTestName,
  onEntrySubmit,
  subTestIndex,
}) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false); // Add loading state
  const [modalMessage, setModalMessage] = useState(""); // State for modal message
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [sliderValue, setSliderValue] = useState(2); // Default slider value
  const [formData, setFormData] = useState({
    result: "",
    testMinutes: "",
    testSeconds: "",
    generalAssessment: "",
    commonAnswer: "",
    answer1: "",
    answer2: "",
    answer3: "",
    additionalAnswer1: "",
    additionalAnswer2: "",
    additionalAnswer3: "",
    timeEfficiency: "",
    improvementMethods: "",
    nextExercisePlan: "",
    additionalObservations: "",
  });
  const [questionLabels, setQuestionLabels] = useState({
    question1: t("Question 1"),
    question2: t("Question 2"),
    question3: t("Question 3"),
  });
  const [editableQuestion, setEditableQuestion] = useState(null);
  const [entryLimitReached, setEntryLimitReached] = useState(false); // State for entry limit

  useEffect(() => {
    const checkEntryLimit = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/statistics/subtest/${subTestId}`,
          {
            headers: {
              "auth-token": Cookies.get("authToken"),
            },
          }
        );
        const data = response.data.data;

        // Group scores by their counts
        const groupedData = data.reduce((acc, item) => {
          if (!acc[item.count]) {
            acc[item.count] = [];
          }
          acc[item.count].push(parseFloat(item.score));
          return acc;
        }, {});

        const scores = [].concat(...Object.values(groupedData));

        const totalExercises = scores.length;

        console.log("total exercise entry: ", totalExercises);
        if (totalExercises > 24) {
          setEntryLimitReached(true);
        } else {
          setEntryLimitReached(false);
        }
      } catch (error) {
        console.error("Error checking entry limit:", error);
      }
    };

    if (isOpen) {
      checkEntryLimit();
    } else {
      // Clear all fields when the popup is closed
      setFormData({
        result: "",
        testMinutes: "",
        testSeconds: "",
        generalAssessment: "",
        commonAnswer: "",
        answer1: "",
        answer2: "",
        answer3: "",
        additionalAnswer1: "",
        additionalAnswer2: "",
        additionalAnswer3: "",
        timeEfficiency: "",
        improvementMethods: "",
        nextExercisePlan: "",
        additionalObservations: "",
      });
      setQuestionLabels({
        question1: t("Question 1"),
        question2: t("Question 2"),
        question3: t("Question 3"),
      });
      setStep(1);
      setEditableQuestion(null);
      setSliderValue(2); // Reset slider value
    }
  }, [isOpen, subTestId, t]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if ((name === "testMinutes" || name === "testSeconds") && value > 59) {
      return;
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLabelChange = (e) => {
    const { name, value } = e.target;
    setQuestionLabels((prevLabels) => ({ ...prevLabels, [name]: value }));
  };

  const handleNext = () => {
    const {
      result,
      testMinutes,
      testSeconds,
      generalAssessment,
      commonAnswer,
      answer1,
      answer2,
      answer3,
      additionalAnswer1,
      additionalAnswer2,
      additionalAnswer3,
      timeEfficiency,
      improvementMethods,
      nextExercisePlan,
      additionalObservations,
    } = formData;

    if (step === 1) {
      if (!result || !testMinutes || !testSeconds) {
        toast.error(t("Please fill all the fields"));
        return;
      }
    } else if (step === 2) {
      if (!generalAssessment) {
        toast.error(t("Please provide a general assessment"));
        return;
      }
    } else if (step === 3) {
      if (!commonAnswer) {
        toast.error(t("Please answer the general question"));
        return;
      }
    } else if (step === 4) {
      if (subTestIndex === 5 || subTestIndex === 6) {
        if (!answer1) {
          toast.error(t("Please answer all questions "));
          return;
        }
      } else {
        if (!answer1 || !answer2 || !answer3) {
          toast.error(t("Please answer all questions "));
          return;
        }
      }
    } else if (step === 5) {
      if (subTestIndex === 4) {
        if (!additionalAnswer1 || !additionalAnswer2) {
          toast.error(t("Please answer all additional questions "));
          return;
        }
      } else if (subTestIndex === 5 || subTestIndex === 6) {
        if (!additionalAnswer1 || !additionalAnswer2 || !additionalAnswer3) {
          toast.error(t("Please answer all additional questions "));
          return;
        }
      }
    } else if (step === 6) {
      if (!timeEfficiency || !improvementMethods || !nextExercisePlan) {
        toast.error(t("Please provide all required information "));
        return;
      }
    } else if (step === 7) {
      if (!additionalObservations) {
        toast.error(t("Please provide additional observations "));
        return;
      }
    }

    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = async () => {
    if (entryLimitReached) {
      toast.error(
        t(
          "You have reached the maximum number of exercise entries for this subtest."
        )
      );
      return;
    }

    const {
      commonAnswer,
      answer1,
      answer2,
      answer3,
      additionalAnswer1,
      additionalAnswer2,
      additionalAnswer3,
      timeEfficiency,
      improvementMethods,
      nextExercisePlan,
      additionalObservations,
    } = formData;

    try {
      setLoading(true); // Set loading to true when saving starts
      const testTime = `${formData.testMinutes}:${formData.testSeconds}`;
      const response = await axios.post(
        `${baseUrl}/subtest/exercise-entry`,
        {
          subTestId,
          subTestName,
          result: formData.result,
          testTime,
          commonAnswer,
          generalAssessment: emojis[sliderValue].text,
          questions: [
            { question: questionLabels.question1, answer: answer1 },
            { question: questionLabels.question2, answer: answer2 },
            { question: questionLabels.question3, answer: answer3 },
          ],
          additionalQuestions:
            subTestIndex === 4
              ? [
                  {
                    question: t(
                      "Welche grundlegenden Prinzipien sollte ich erneut überprüfen?"
                    ),
                    answer: additionalAnswer1,
                  },
                  {
                    question: t(
                      "Gab es Aufgaben, die ich effizienter hätte lösen können, und wie?"
                    ),
                    answer: additionalAnswer2,
                  },
                ]
              : subTestIndex === 5 || subTestIndex === 6
              ? [
                  {
                    question: t(
                      "Welche Schwierigkeiten traten bei der Einprägephase auf?"
                    ),
                    answer: additionalAnswer1,
                  },
                  {
                    question: t(
                      "Welche Probleme traten bei der Reproduktionsphase auf?"
                    ),
                    answer: additionalAnswer2,
                  },
                  {
                    question: t(
                      "Wie kann ich mein Einpräge- und Reproduktionsstrategien verbessern?"
                    ),
                    answer: additionalAnswer3,
                  },
                ]
              : [],
          timeEfficiency,
          improvementMethods,
          nextExercisePlan,
          additionalObservations,
        },
        {
          headers: {
            "auth-token": Cookies.get("authToken"),
          },
        }
      );
      // Call archive API to save the subTestId
      await axios.post(
        `${baseUrl}/archive/post-archive`,
        { subTestId },
        {
          headers: {
            "auth-token": Cookies.get("authToken"),
          },
        }
      );
      setModalMessage(t("Your Entry is saved"));
      setIsModalOpen(true); // Show modal
      onEntrySubmit(); // Call the passed-in function to update the count
    } catch (error) {
      setModalMessage(t("Error submitting exercise entry. Please try again."));
      setIsModalOpen(true); // Show modal
    } finally {
      setLoading(false); // Set loading to false when saving is complete
    }
  };

  const isValidResult = (value) => {
    const num = Number(value);
    return !isNaN(num) && num >= 0 && num <= 24;
  };

  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="popupOverlay">
        {loading && <Loader />} {/* Show loader when loading is true */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2 className="successjj">{t("Success!")} </h2>
              <p className="kjhjj">{modalMessage}</p>
              <button className="yuioi" onClick={closeModal}>
                {t("Close")}
              </button>
            </div>
          </div>
        )}
        <div className="popupContent">
          <button
            className="closeButton"
            onClick={() => {
              onClose();
              setFormData({
                result: "",
                testMinutes: "",
                testSeconds: "",
                generalAssessment: "",
                commonAnswer: "",
                answer1: "",
                answer2: "",
                answer3: "",
                additionalAnswer1: "",
                additionalAnswer2: "",
                additionalAnswer3: "",
                timeEfficiency: "",
                improvementMethods: "",
                nextExercisePlan: "",
                additionalObservations: "",
              });
              setQuestionLabels({
                question1: t("Question 1"),
                question2: t("Question 2"),
                question3: t("Question 3"),
              });
              setStep(1);
              setEditableQuestion(null);
            }}
            title={t("Close")}
          >
            <IoIosCloseCircleOutline
              className="button-animate"
              cursor={"pointer"}
              size={"2rem"}
              onClick={() => onClose()}
            />
          </button>
          <h1 className="popuphTithle">{t("New Exercise Entry")}</h1>
          <hr />
          {step === 1 && (
            <div className="lkjhjj">
              <div className="nbhvgvjjj">
                <div className="formGroup1">
                  <label className="labelentry1">{t("Subtest Name")}</label>
                  <label className="labelentry1">{t("Result")}</label>
                  <label className="labelentry1">{t("Test time")}</label>
                </div>
                <div className="lkjhg">
                  <p className="entryjh">{subTestName}</p>
                  <input
                    type="number"
                    name="result"
                    value={formData.result}
                    onChange={(e) =>
                      isValidResult(e.target.value) &&
                      setFormData((prevData) => ({
                        ...prevData,
                        result: e.target.value,
                      }))
                    }
                    placeholder={t("Result (0-24)")}
                    className="kjhjjj"
                    required
                  />
                  <div className="timeInputs">
                    <input
                      type="number"
                      name="testMinutes"
                      value={formData.testMinutes}
                      onChange={handleChange}
                      placeholder="MM"
                      className="kjhjjj timeInput"
                      min="0"
                      max="59"
                      title={t("Test Minutes")}
                      required
                    />
                    <span>:</span>
                    <input
                      type="number"
                      name="testSeconds"
                      value={formData.testSeconds}
                      onChange={handleChange}
                      placeholder="SS"
                      className="kjhjjj timeInput"
                      min="0"
                      max="59"
                      title={t("Test Seconds")}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="lkjhgg">
                <button
                  className="nexthhButton button-animate"
                  onClick={handleNext}
                  title={t("Next Step")}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="popupBqody">
              <h2 className="kjkhjuuo">
                {t("Generelle Einschätzung meiner Leistung:")}
              </h2>
              <div className="emoji-container">
                <div className="emoji">
                  <img
                    src={emojis[sliderValue].image}
                    alt={emojis[sliderValue].text}
                    className="emoji-tooltip"
                  />
                  <span className="tooltip-texts">
                    {emojis[sliderValue].text}
                  </span>
                </div>
                <input
                  type="range"
                  min="0"
                  max="4"
                  value={sliderValue}
                  onChange={(e) => setSliderValue(Number(e.target.value))}
                  className="slider1"
                  required
                />
              </div>
              <div className="buttonGroup">
                <button
                  className="previogusButton button-animate"
                  onClick={handlePrevious}
                  title={t("Previous Step")}
                >
                  {t("Previous")}
                </button>
                <button
                  className="nexthhButton button-animate"
                  onClick={() => setStep(3)}
                  title={t("Next Step")}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="popupBqody">
              <div className="formGroupw">
                <label className="labelentry">
                  {t(
                    "Auf welche Aspekte meiner Leistung bin ich besonders stolz?"
                  )}
                </label>
                <textarea
                  name="commonAnswer"
                  value={formData.commonAnswer}
                  onChange={handleChange}
                  placeholder={t("Your answer (optional)")}
                  className="mhujhh"
                  title={t("General Assessment")}
                  required
                />
              </div>
              <div className="buttonGroup">
                <button
                  className="previogusButton button-animate"
                  onClick={handlePrevious}
                  title={t("Previous Step")}
                >
                  {t("Previous")}
                </button>
                <button
                  className="nexthhButton button-animate"
                  onClick={handleNext}
                  title={t("Next Step")}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="popupBqody">
              <div className="formGroupw">
                {subTestIndex === 5 || subTestIndex === 6 ? (
                  <>
                    <label className="labelentry">
                      {t(
                        "Welche Aufgaben konnte ich erfolgreich und effizient lösen?"
                      )}
                    </label>
                    <textarea
                      name="answer1"
                      value={formData.answer1}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 1")}
                      required
                    />
                  </>
                ) : (
                  <>
                    <label className="labelentry">
                      {t(
                        "Welche Aufgaben konnte ich erfolgreich und effizient lösen?"
                      )}
                    </label>
                    <textarea
                      name="answer1"
                      value={formData.answer1}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 1")}
                      required
                    />
                    <label className="labelentry">
                      {t("Welche Schwierigkeiten sind mir begegnet?")}
                    </label>
                    <textarea
                      name="answer2"
                      value={formData.answer2}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 2")}
                      required
                    />
                    <label className="labelentry">
                      {t("Welche Fallen kamen vor?")}
                    </label>
                    <textarea
                      name="answer3"
                      value={formData.answer3}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 3")}
                      required
                    />
                  </>
                )}
              </div>
              <div className="buttonGroup">
                <button
                  className="previogusButton button-animate"
                  onClick={handlePrevious}
                  title={t("Previous Step")}
                >
                  {t("Previous")}
                </button>
                <button
                  className="nexthhButton button-animate"
                  onClick={() => {
                    if (
                      subTestIndex === 1 ||
                      subTestIndex === 2 ||
                      subTestIndex === 3 ||
                      subTestIndex === 7 ||
                      subTestIndex === 8
                    ) {
                      setStep(6);
                    } else {
                      setStep(5);
                    }
                  }}
                  title={t("Next Step")}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          )}
          {step === 5 && (
            <div className="popupBqody">
              <div className="formGroupw">
                {subTestIndex === 4 ? (
                  <>
                    <label className="labelentry">
                      {t(
                        "Welche grundlegenden Prinzipien sollte ich erneut überprüfen?"
                      )}
                    </label>
                    <textarea
                      name="additionalAnswer1"
                      value={formData.additionalAnswer1}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 1")}
                      required
                    />
                    <label className="labelentry">
                      {t(
                        "Gab es Aufgaben, die ich effizienter hätte lösen können, und wie?"
                      )}
                    </label>
                    <textarea
                      name="additionalAnswer2"
                      value={formData.additionalAnswer2}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 2")}
                      required
                    />
                  </>
                ) : (
                  <>
                    <label className="labelentry">
                      {t(
                        "Welche Schwierigkeiten traten bei der Einprägephase auf?"
                      )}
                    </label>
                    <textarea
                      name="additionalAnswer1"
                      value={formData.additionalAnswer1}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 1")}
                      required
                    />
                    <label className="labelentry">
                      {t(
                        "Welche Probleme traten bei der Reproduktionsphase auf?"
                      )}
                    </label>
                    <textarea
                      name="additionalAnswer2"
                      value={formData.additionalAnswer2}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 2")}
                      required
                    />
                    <label className="labelentry">
                      {t(
                        "Wie kann ich mein Einpräge- und Reproduktionsstrategien verbessern?"
                      )}
                    </label>
                    <textarea
                      name="additionalAnswer3"
                      value={formData.additionalAnswer3}
                      onChange={handleChange}
                      placeholder={t("Your answer")}
                      className="mhujhh"
                      title={t("Answer 3")}
                      required
                    />
                  </>
                )}
              </div>
              <div className="buttonGroup">
                <button
                  className="previogusButton button-animate"
                  onClick={handlePrevious}
                  title={t("Previous Step")}
                >
                  {t("Previous")}
                </button>
                <button
                  className="nexthhButton button-animate"
                  onClick={handleNext}
                  title={t("Next Step")}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          )}
          {step === 6 && (
            <div className="popupBqody">
              <div className="formGroupw">
                <label className="labelentry">
                  {t(
                    "Wie effektiv habe ich meine Zeit genutzt und gibt es Möglichkeiten zur Optimierung?"
                  )}
                </label>
                <textarea
                  name="timeEfficiency"
                  value={formData.timeEfficiency}
                  onChange={handleChange}
                  placeholder={t("Your answer")}
                  className="mhujhh"
                  title={t("Time Efficiency")}
                  required
                />
                <label className="labelentry">
                  {t(
                    "Welche Übungsmethoden oder Techniken könnten meine Fähigkeiten weiter verbessern?"
                  )}
                </label>
                <textarea
                  name="improvementMethods"
                  value={formData.improvementMethods}
                  onChange={handleChange}
                  placeholder={t("Your answer")}
                  className="mhujhh"
                  title={t("Improvement Methods")}
                  required
                />
                <label className="labelentry">
                  {t("Was nehme ich mir für die nächste Übung vor?")}
                </label>
                <textarea
                  name="nextExercisePlan"
                  value={formData.nextExercisePlan}
                  onChange={handleChange}
                  placeholder={t("Your answer")}
                  className="mhujhh"
                  title={t("Next Exercise Plan")}
                  required
                />
              </div>
              <div className="buttonGroup">
                <button
                  className="previogusButton button-animate"
                  onClick={handlePrevious}
                  title={t("Previous Step")}
                >
                  {t("Previous")}
                </button>
                <button
                  className="nexthhButton button-animate"
                  onClick={handleNext}
                  title={t("Next Step")}
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          )}
          {step === 7 && (
            <div className="popupBqody">
              <div className="formGroupw">
                <label className="labelentry">
                  {t(
                    "Gibt es weitere Beobachtungen, die ich festhalten möchte?"
                  )}
                </label>
                <textarea
                  name="additionalObservations"
                  value={formData.additionalObservations}
                  onChange={handleChange}
                  placeholder={t("Your answer")}
                  className="mhujhh"
                  title={t("Additional Observations")}
                  required
                />
              </div>
              <div className="buttonGroup">
                <button
                  className="previogusButton"
                  onClick={handlePrevious}
                  title={t("Previous Step")}
                >
                  {t("Previous")}
                </button>
                <button
                  className="submitgButton button-animate"
                  onClick={handleSubmit}
                  title={t("Save & Exit")}
                >
                  {t("Save & Exit")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ExerciseEntryPopup;
