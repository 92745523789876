import React, { useEffect, useState } from "react";
import "../styles/journalPopup.css"; // Make sure to create and style this CSS file

// Emojis corresponding to each range value from 1 to 5
const moodEmojis = {
  1: "😞", // worst
  2: "😟",
  3: "😐",
  4: "🙂",
  5: "😃", // best
};

const JournalPopup = ({ isOpen, onClose, journal }) => {
  const {
    wellBeingFactor,
    stressLevelFactor,
    happinessFactor,
    note,
    createdAt,
  } = journal;

  const [emoji, setEmoji] = useState("🙂");

  const getEmoji = (happinessFactor) => {
    if (happinessFactor <= 2) return moodEmojis[1];
    if (happinessFactor <= 4) return moodEmojis[2];
    if (happinessFactor === 3) return moodEmojis[3];
    if (happinessFactor === 4) return moodEmojis[4];
    return moodEmojis[5];
  };

  useEffect(() => {
    setEmoji(getEmoji(happinessFactor));
  }, [happinessFactor]);

  if (!isOpen) return null;

  return (
    <div className="popupOverlay1">
      <div className="popupContent1">
        <button className="closeButton" onClick={onClose}>
          &times;
        </button>
        <h1 className="popupTitle">Journal Entry</h1>
        {/* <p className="date">Date: {new Date(createdAt).toLocaleDateString()}</p> */}
        <hr />
        <div className="popupBody1">
          <div className="ghjkl">
            <div className="wellBeingContainer">
              <h2 className="headij">Well-Being</h2>
              <div className="pojuu">
                <input
                  type="range"
                  min="1"
                  max="5"
                  value={wellBeingFactor}
                  readOnly
                  className="wellBeingSlider"
                />
                <span className="sliderValue">{wellBeingFactor}</span>
              </div>
            </div>
            <div className="stressLevelContainer">
              <h2 className="headij">Stress Level</h2>
              <div className="pojuu">
                <input
                  type="range"
                  min="1"
                  max="5"
                  value={stressLevelFactor}
                  readOnly
                  className="stressLevelSlider"
                />
                <span className="sliderValue">{stressLevelFactor}</span>
              </div>
            </div>
            <div className="emojiSliderContainer">
              <h2 className="headij">Mood</h2>
              <div className="emojiDisplay">
                <span className="emoji">{emoji}</span>
              </div>
              <div className="pojuu">
                <input
                  type="range"
                  min="1"
                  max="5"
                  value={happinessFactor}
                  readOnly
                  className="emojiSlider"
                />
                <span className="sliderValue">{happinessFactor}</span>
              </div>
            </div>
          </div>
          <hr />
          <div className="noteContainer">
            <h2 className="writenote">Note:</h2>
            <textarea value={note} readOnly className="noteTextarea"></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JournalPopup;
