import React, { useEffect, useState } from "react";
import "../styles/changePassword.css";
import { Eye, EyeOff } from "react-feather";
import { toast } from "sonner";
import { TailSpin } from "react-loader-spinner";
import baseUrl from "../baseUrl";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const ChangePassword = () => {
  const { t } = useTranslation();

  const [currPassword, setCurrPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPasswordMatch, setConfirmPasswordMatch] = useState(true);

  const toggleCurrPasswordVisibility = () =>
    setShowCurrPassword(!showCurrPassword);
  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);

  const validatePassword = (password) => {
    if (password.length < 8) {
      setValidationMessage(t("Password must be at least 8 characters long."));
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setValidationMessage(
        t("Password must include at least one special character.")
      );
    } else if (!/\d/.test(password)) {
      setValidationMessage(t("Password must include at least one number."));
    } else {
      setValidationMessage(t("Password is strong"));
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (validationMessage !== t("Password is strong")) {
      toast.error(t("Please ensure the password meets all criteria."));
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error(t("New password and confirmation password do not match."));
      return;
    }
    try {
      setLoading(true);

      const response = await axios.patch(
        `${baseUrl}/auth/change-password`,
        {
          currentPassword: currPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            "auth-token": Cookies.get("authToken"),
          },
        }
      );

      console.log(response);

      if (response.status === 200) {
        toast.success(t("Successfully changed password!"));
      } else {
        toast.error(response.data.message);
      }
      setCurrPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setValidationMessage("");
      setConfirmPasswordMatch(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error(t("Error in changing password"));
      }
    }
  };

  useEffect(() => {
    setConfirmPasswordMatch(newPassword === confirmPassword);
  }, [newPassword, confirmPassword]);

  return (
    <div>
      <div className="password-change-page">
        {loading ? (
          <div className="loader-container">
            <TailSpin
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <div>
            <form
              className="password-change-form"
              onSubmit={handleChangePassword}
            >
              <h1 className="password-change-title">{t("Change Password")}</h1>

              <div className="password-field">
                <label
                  htmlFor="current-password"
                  className="password-change-label"
                >
                  {t("Current password")}:
                </label>
                <div className="input-group">
                  <input
                    type={showCurrPassword ? "text" : "password"}
                    id="current-password"
                    name="current-password"
                    value={currPassword}
                    onChange={(e) => setCurrPassword(e.target.value)}
                    className="password-change-input"
                  />
                  <span className="icon" onClick={toggleCurrPasswordVisibility}>
                    {showCurrPassword ? <Eye /> : <EyeOff />}
                  </span>
                </div>
              </div>

              <div className="password-field">
                <label htmlFor="new-password" className="password-change-label">
                  {t("New Password")}:
                </label>
                <div className="input-group">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    id="new-password"
                    name="new-password"
                    value={newPassword}
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                      validatePassword(e.target.value);
                    }}
                    className={`password-change-input ${
                      validationMessage !== t("Password is strong") &&
                      validationMessage !== ""
                        ? "error-border"
                        : ""
                    }`}
                  />
                  <span className="icon" onClick={toggleNewPasswordVisibility}>
                    {showNewPassword ? <Eye /> : <EyeOff />}
                  </span>
                </div>
                <div className="password-msg">
                  {validationMessage === t("Password is strong") ? (
                    <p
                      className="password-validation-message"
                      style={{ color: "green" }}
                    >
                      {validationMessage}
                    </p>
                  ) : (
                    <p className="password-validation-message">
                      {validationMessage}
                    </p>
                  )}
                </div>
              </div>

              <div className="password-field">
                <label
                  htmlFor="confirm-password"
                  className="password-change-label"
                >
                  {t("Confirm New Password")}:
                </label>
                <div className="input-group">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm-password"
                    name="confirm-password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setConfirmPasswordMatch(e.target.value === newPassword);
                    }}
                    className={`password-change-input ${
                      !confirmPasswordMatch && "password-input-error"
                    }`}
                  />
                  <span
                    className="icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <Eye /> : <EyeOff />}
                  </span>
                </div>
                {!confirmPasswordMatch && (
                  <div className="error-message" style={{ fontSize: "14px" }}>
                    {t("Passwords do not match.")}
                  </div>
                )}
              </div>

              <div className="password-change-submit-button">
                <button
                  className="password-change-submit button-animate"
                  type="submit"
                >
                  {t("Proceed")}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
