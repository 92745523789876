import React, { useEffect, useState } from "react";
import Header from "./header";
import baseUrl from "../baseUrl";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { UserTable } from "./UserTable";
import Cookies from "js-cookie";

export const AdminPanel = () => {
  const { t } = useTranslation();

  const [exercises, setExercises] = useState([]);
  const token = Cookies.get("authToken");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);

  const getAllExercises = async () => {
    try {
      const response = await axios.get(`${baseUrl}/admin/all-exercises`, {
        headers: {
          "auth-token": token,
        },
      });
      if (response.data) {
        console.log(response.data);
        setExercises(response.data.allExercises);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/admin/exercise`, {
        data: { _id: id },
        headers: {
          "auth-token": token,
        },
      });
      if (response.data) {
        toast.success(t("Entry deleted successfully"));
        setExercises(exercises.filter((exercise) => exercise._id !== id));
      }
    } catch (err) {
      toast.error(t("Something went wrong"));
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAllExercises();
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentExercises = exercises.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="dashContainer">
      <Header title={t("Admin Panel")} />
      <div className="userListContainer">
        <div className="user-table">
          <h2 style={{ padding: "10px", margin: "0 30px" }}>
            {t("User Entries")}
          </h2>
          <table className="userList">
            <thead>
              <tr>
                <th>{t("Name")}</th>
                <th>{t("Email")}</th>
                <th>{t("Subtest")}</th>
                <th>{t("Created At")}</th>
                <th>{t("Result")}</th>
                <th>{t("Delete")}</th>
              </tr>
            </thead>
            <tbody>
              {currentExercises?.map((exercise) => (
                <tr key={exercise._id}>
                  <td>{exercise.user.name}</td>
                  <td>{exercise.user.email}</td>
                  <td>{exercise.subTestName}</td>
                  <td>
                    {new Date(exercise.createdAt).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </td>
                  <td>{exercise.result}</td>
                  <td style={{ textAlign: "center" }}>
                    <MdDelete
                      onClick={() => handleDelete(exercise._id)}
                      color="red"
                      size={"1.25rem"}
                      cursor={"pointer"}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {t("Previous")}
            </button>
            <span className="pagination-info">
              {t("Page")} {currentPage} {t("of")}{" "}
              {Math.ceil(exercises.length / itemsPerPage)}
            </span>
            <button
              className="pagination-button"
              onClick={() => paginate(currentPage + 1)}
              disabled={
                currentPage === Math.ceil(exercises.length / itemsPerPage)
              }
            >
              {t("Next")}
            </button>
          </div>
        </div>
      </div>
      <UserTable />
    </div>
  );
};
