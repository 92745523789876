import React from "react";
// CSS in popup.css
import { IoMdCloseCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export const SuccessScreen = ({
  title,
  onClose,
  registerScreen = false,
  credentials = {},
  associationScreen = false,
}) => {
  const navigate = useNavigate();
  return (
    <div className="popup">
      <div className="success-screen">
        <div className="popup-header">
          <h3>{title}</h3>
          <IoMdCloseCircleOutline
            className="close-btn"
            cursor={"pointer"}
            title="Close"
            size={"1.5rem"}
            onClick={onClose}
          />
        </div>

        <div className="popup-main-heading">
          {associationScreen ? (
            <>
              <h5>Deine Anfrage wurde gestellt.</h5>
              <h6> Es wird sich bald jemand melden</h6>
            </>
          ) : (
            <>
              <h3>Success</h3>
              <h6>Your entry is saved.</h6>
            </>
          )}
          {registerScreen && (
            <div className="creds-container">
              <div className="cred-item">
                <label htmlFor="name" className="lkjh">
                  Name:
                </label>
                <div className="ooii">{credentials.name}</div>
              </div>
              <div className="cred-item">
                <label htmlFor="email" className="lkjh">
                  Email:
                </label>
                <div className="ooii">{credentials.email}</div>
              </div>
              <div className="cred-item">
                <label htmlFor="password" className="lkjh">
                  Password:
                </label>
                <div className="ooii">{credentials.password}</div>
              </div>
            </div>
          )}
        </div>
        <div className="btn-container-edit">
          {registerScreen ? (
            <button
              className="btn-submit button-animate"
              onClick={() => navigate("/dashboard")}
            >
              Go to dashboard
            </button>
          ) : (
            <button className="btn-submit button-animate" onClick={onClose}>
              Schließen
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
