import React from "react";
import { useTranslation } from "react-i18next";
// CSS in popup.css

export const FailureScreen = ({ onClose, data }) => {
  const { t } = useTranslation();
  return (
    <div className="popup">
      <div
        className="success-screen"
        style={{ padding: "20px 10px", paddingTop: "20px" }}
      >
        <div className="popup-header">
          <h3> {t("Failure")} </h3>
        </div>

        <div className="popup-main-heading" style={{ height: "auto" }}>
          <h3>{t("Oops..")}</h3>
          <h6 style={{ fontFamily: "Montserrat", fontWeight: 500 }}>
            {t("You have not attempted these subtests")}{" "}
          </h6>
          <div className="subtest-name-container">
            {data &&
              data.map((subtest, id) => (
                <div className="sub-btn" key={id}>
                  {subtest}
                </div>
              ))}
          </div>
        </div>

        <div className="btn-container-edit">
          <button className="btn-submit button-animate" onClick={onClose}>
            {t("Go to Dashboard")}
          </button>
        </div>
      </div>
    </div>
  );
};
