import React, { useEffect, useState } from "react";
import "../styles/subsets.css";
import { FiPlusCircle } from "react-icons/fi";
import { AddSubtestPopup } from "./AddSubtestPopup";
import axios from "axios";
import baseUrl from "../baseUrl";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import back from "../assets/images/back.svg";
import { Droppable } from "react-beautiful-dnd";
import { SingleSubtest } from "./SingleSubtest";
import Cookies from "js-cookie";

const StudyPlan = ({ studyPlanData, setStudyPlanData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [manageClicked, setManageClicked] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const token = Cookies.get("authToken");
  const [selectedSubsets, setSelectedSubtests] = useState([]);
  const fromStudyPlan = location.state?.fromStudyPlan || false;

  const getStudyPlan = async () => {
    try {
      const response = await axios.get(`${baseUrl}/dashboard/get-study-plan`, {
        headers: {
          "auth-token": token,
        },
      });
      if (response && response.data) {
        setStudyPlanData(response.data.studyPlan);
        console.log(response.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteSubtest = async (day, subtest) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/dashboard/delete-from-studyplan`,
        {
          data: { day, subtest },
          headers: {
            "auth-token": token,
          },
        }
      );
      if (response.data) {
        console.log(response.data);
      }
    } catch (error) {
      console.error('Error deleting subtest:', error.message);
    }
  };
  

  useEffect(() => {
    getStudyPlan();
  }, []);

  const getTodayDay = () => {
    const days = [
      t("Sunday"),
      t("Monday"),
      t("Tuesday"),
      t("Wednesday"),
      t("Thursday"),
      t("Friday"),
      t("Saturday"),
    ];
    const today = new Date().getDay();
    return days[today];
  };

  const handleSave = () => {
    setManageClicked(false);
    setSelectedDay(null);
  };

  const handleManage = (day) => {
    setManageClicked(true);
    setSelectedDay(day);
  };

  const handleEdit = (subtests) => {
    setSelectedSubtests(subtests);
    setEditOpen(true);
  };

  const translateDay = (day) => {
    const daysMapping = {
      Sunday: t("Sunday"),
      Monday: t("Monday"),
      Tuesday: t("Tuesday"),
      Wednesday: t("Wednesday"),
      Thursday: t("Thursday"),
      Friday: t("Friday"),
      Saturday: t("Saturday"),
    };
    return daysMapping[day] || day;
  };

  const handleBackClick = () => {
    if (fromStudyPlan) {
      navigate(-1);
    } else {
      navigate("/settings/generateStudy");
    }
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
        <h1 className="courseName">{t("Study Plan1")}</h1>
      </div>
      <hr />

      <div className="studyPlanContainer">
        <div className="studyPlanGrid">
          {studyPlanData.map(({day, subtests}) => (
            <Droppable droppableId={`${day}`}>
              {(provided) => (
                <div
                  key={day}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`day-entry ${
                    manageClicked && selectedDay !== day
                      ? "overlay-applied"
                      : ""
                  }`}
                >
                  {translateDay(day) === getTodayDay() && (
                    <span className="todayLabel">{t("TODAY")}</span>
                  )}
                  <div
                    className={`dayColumn ${
                      translateDay(day) === getTodayDay() ? "today" : ""
                    }`}
                  >
                    <div className="dayHeader">
                      <span>{translateDay(day)}</span>
                      {manageClicked ? (
                        selectedDay === day ? (
                          <div
                            style={{
                              display: "flex",
                              gap: "0.5rem",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FiPlusCircle
                              onClick={() => handleEdit(subtests)}
                              cursor={"pointer"}
                              color="white"
                              size={"1.5rem"}
                              className="button-animate"
                            />
                            <button
                              className="manageButton button-animate"
                              onClick={() => handleSave()}
                            >
                              {t("Save")}
                            </button>
                          </div>
                        ) : (
                          <button
                            className="manageButton button-animate"
                            onClick={() => handleManage(day)}
                          >
                            {t("Manage")}
                          </button>
                        )
                      ) : (
                        <button
                          className="manageButton button-animate"
                          onClick={() => handleManage(day)}
                        >
                          {t("Manage")}
                        </button>
                      )}
                    </div>
                    <ul className="subtestList">
                      {subtests.map((subtest, idx) => (
                        <SingleSubtest
                          subtest={subtest}
                          index={idx}
                          selectedDay={selectedDay}
                          deleteSubtest={deleteSubtest}
                          day={day}
                          key={idx}
                        />
                      ))}
                    </ul>
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
          <div className="button-grid">
            <button
              className="save-and-exit button-animate"
              onClick={() => navigate("/dashboard")}
            >
              {t("Save & Exit")}
            </button>
          </div>
        </div>
      </div>

      {editOpen && (
        <AddSubtestPopup
          onClose={() => setEditOpen(false)}
          passedSubsets={selectedSubsets}
          day={selectedDay}
          getStudyPlan={getStudyPlan}
        />
      )}
    </div>
  );
};

export default StudyPlan;
