import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setUser, setToken } from "../redux/actions/userActions";
import "../styles/register.css";
import medgang_logo from "../assets/images/TMSLogo.png";
import baseUrl from "../baseUrl";
import Loader from "./loader"; // Import the Loader component
import { SuccessScreen } from "./SuccessScreen";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { Eye, EyeOff } from "react-feather"; // Import Eye and EyeOff icons

const RegisterPage = () => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const [isAdmin, setIsAdmin] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Add state for password visibility


  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when registration is initiated
    try {
      const role = isAdmin ? "admin" : "student";
      const response = await axios.post(`${baseUrl}/auth/register`, {
        name,
        email,
        password,
        role,
      });
      const data = response.data;
      if (data.token) {
        setOnSuccess(true);
        toast.success("User registered successfully!");
      }
    } catch (error) {
      console.error(
        "Registration failed:",
        error.response?.data?.message || error
      );
      toast.error("Registration failed!");
    } finally {
      setLoading(false); // Set loading to false when registration is complete
    }
  };

  const handleOnClose = () => {
    setOnSuccess(false);
    setPassword("");
    setEmail("");
    setName("");
    setIsAdmin(false);
  };

  const generateRandomPassword = async () => {
    try {
      const response = await axios.get(`${baseUrl}/auth/generate-password`);
      setGeneratedPassword(response.data.password);
      setPassword(response.data.password);
      toast.success("Random password generated");
    } catch (error) {
      console.error("Failed to generate password:", error);
    }
  };

  return (
    <div className="dashContainer" style={{ marginTop: "60px" }}>
      {loading && <Loader />} {/* Show loader when loading is true */}
      <div className={`loginContainer ${loading ? "blur-background" : ""}`}>
        <div className="logos">
          <img src={medgang_logo} alt="Medgang Logo" className="medgang" />
        </div>

        <div className="loginsys">
          <form className="login_form" onSubmit={handleRegister}>
            <h1 className="welcome_back">{t("REGISTER")}</h1>

            <label htmlFor="name" className="lkjh">
              {t("Name")}:
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="ooii"
            />

            <label htmlFor="email" className="lkjh">
              {t("Email")}:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="ooii"
            />

            <label htmlFor="password" className="lkjh">
              {t("Password")}:
            </label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="ooii"
              />
              <div
                className="toggle-password"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff /> : <Eye />}
              </div>
            </div>
            <div className="switch-container">
              <div>
                <label className="switch" style={{ marginRight: "10px" }}>
                  <input
                    type="checkbox"
                    checked={isAdmin}
                    onChange={() => setIsAdmin(!isAdmin)}
                  />
                  <span className="slider"></span>
                </label>
                {isAdmin
                  ? t("User will also be an admin now")
                  : t("Make user an Admin?")}
              </div>
              <div
                className="generate-password-text"
                onClick={generateRandomPassword}
              >
                <p style={{ fontSize: "15px" }}>{t("Generate Password")}</p>
              </div>
            </div>
            <div className="submitbutton" style={{ marginTop: "2vh" }}>
              <button className="submit" type="submit">
                {t("Register")}
              </button>
            </div>
          </form>
        </div>
      </div>
      {onSuccess && (
        <SuccessScreen
          onClose={handleOnClose}
          title={t("Registration Successful")}
          registerScreen={true}
          credentials={{ name, email, password }}
        />
      )}
    </div>
  );
};

export default RegisterPage;
