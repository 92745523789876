import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "./header";
import Card from "./cards";
import JournalPopup from "./journalPopup";
import EditQuotePopup from "./editQuotePopup";
import "../styles/dashboard.css";
import frame4 from "../assets/images/logos.png";
import book from "../assets/images/journals.svg";
import shape1 from "../assets/images/shape1.svg";
import shape2 from "../assets/images/shape2.svg";
import shape3 from "../assets/images/shape3.svg";
import shape4 from "../assets/images/shape4.svg";
import frame from "../assets/images/journal.svg";
import dash1 from "../assets/images/dash1.svg";
import { TailSpin } from "react-loader-spinner";
import baseUrl from "../baseUrl";
import { SuccessScreen } from "./SuccessScreen";
import Cookies from "js-cookie";

const Dashboard = () => {
  const { t } = useTranslation();
  const [isJournalPopupOpen, setIsJournalPopupOpen] = useState(false);
  const [isQuotePopupOpen, setIsQuotePopupOpen] = useState(false);
  const [quote, setQuote] = useState("");
  const [name, setName] = useState("");
  const [subTests, setSubTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = Cookies.get("authToken");
  const role = localStorage.getItem("Role");
  const [successScreen, setSuccessScreen] = useState(false);
  const [activeCard, setActiveCard] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const response = await axios.get(`${baseUrl}/dashboard/get-dashboard`, {
          headers: {
            "auth-token": token,
          },
        });
        if (response.data.message === "success") {
          const data = response.data;
          console.log(data);
          setQuote(data.quote);
          setName(data.name);
          setSubTests(data.subTests.slice(0, 3));
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuote();
  }, [token]);

  useEffect(() => {
    const fetchPersonalize = async () => {
      console.log("Fetch personalized data");
      try {
        const authToken = Cookies.get("authToken");
        const response = await axios.get(`${baseUrl}/support/personalize`, {
          headers: {
            "auth-token": authToken,
          },
        });

        const data = response.data;
        const obj = {
          "Active Since": data.Active,
          "Ø Hours/Week": data.hoursWeek,
          "Ø Hours/Day": data.HoursDay,
          Strength: data.Strength,
          Weakness: data.Weakness,
          "Absolved Exercises": data.AbsolvedExercise,
          "Well-being": data.Wellbeing,
          "Ø/24": data.Avg24,
          "24/24": data.By24,
          "Stress Level": data.StressLevel,
          Countdown: data.Countdown,
        };
        localStorage.setItem("personalize", JSON.stringify(obj));
      } catch (error) {
        console.error(error);
      }
    };
    fetchPersonalize();
  }, []);

  const todaysDate = () => {
    const days = [
      t("Sunday"),
      t("Monday"),
      t("Tuesday"),
      t("Wednesday"),
      t("Thursday"),
      t("Friday"),
      t("Saturday"),
    ];
    const months = [
      t("January"),
      t("February"),
      t("March"),
      t("April"),
      t("May"),
      t("June"),
      t("July"),
      t("August"),
      t("September"),
      t("October"),
      t("November"),
      t("December"),
    ];

    const today = new Date();
    const dayOfWeek = days[today.getDay()];
    const dayOfMonth = today.getDate();
    const month = months[today.getMonth()];
    const year = today.getFullYear();

    const formattedDate = `${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;

    return formattedDate;
  };

  const handleGenerate = () => {
    navigate("/settings/generateStudy");
  };

  const handleShowJournalPopup = () => {
    setIsJournalPopupOpen(true);
  };

  const handleCloseJournalPopup = () => {
    setIsJournalPopupOpen(false);
  };

  const handleShowQuotePopup = () => {
    setIsQuotePopupOpen(true);
  };

  const handleCloseQuotePopup = () => {
    setIsQuotePopupOpen(false);
  };

  const handleSaveQuote = (updatedQuote) => {
    setQuote(updatedQuote);
    setIsQuotePopupOpen(false);
  };

  const handleCardClick = (subTestId, subTestName) => {
    setActiveCard(subTestId);
    setTimeout(() => {
      navigate(`/subtestOverviewLearning`, {
        state: { subTestId, subTestName },
      });
      setActiveCard(null);
    }, 100);
  };

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return t("Good Morning");
    } else if (currentHour < 17) {
      return t("Good Afternoon");
    } else {
      return t("Good Evening");
    }
  };

  return (
    <div className="dashContainer">
      <Header title={t("Dashboard")} />
      <div className="dashMain">
        <h1 className="greeting">
          {getGreeting()}, {name}!
        </h1>

        <div className="board">
          <div className="boardicuy">
            {/* <img
              src={shape1}
              alt=""
              className="shape1"
              onContextMenu={(e) => e.preventDefault()}
            />
            <img
              src={shape2}
              alt=""
              className="shape2"
              onContextMenu={(e) => e.preventDefault()}
            />
            <img
              src={shape3}
              alt=""
              className="shape3"
              onContextMenu={(e) => e.preventDefault()}
            />
            <img
              src={shape4}
              alt=""
              className="shape4"
              onContextMenu={(e) => e.preventDefault()}
            /> */}
            <div className="fl">
              <div className="oiwuy">
                <img
                  src={frame4}
                  alt=""
                  className="kjhjh"
                  onContextMenu={(e) => e.preventDefault()}
                />
                <div className="poio">
                  <h1 className="llkk">MedGang</h1>
                  <p className="kjhg">@medgangde</p>
                </div>
              </div>
              <div className="iuhyui">
                {role === "admin" && (
                  <div className="button-with-tooltip">
                    <button
                      className="editTextBujtton button-animate"
                      onClick={handleShowQuotePopup}
                    >
                      {t("Edit")}
                    </button>
                  </div>
                )}
              </div>
            </div>
            {loading ? (
              <div className="quote-loader">
                <TailSpin
                  height="40"
                  width="40"
                  color="#fff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <h1 className="oiugii">{quote}</h1>
            )}
          </div>
          <div className="howarey">
            {/* <img
              src={dash1}
              alt=""
              className="hhhb"
              onContextMenu={(e) => e.preventDefault()}
            /> */}
            <img
              src={book}
              alt=""
              className="frojj"
              onContextMenu={(e) => e.preventDefault()}
            />
            <h1 className="poriuii">{t("How are you today?")}</h1>
            <div className="lhg">
              <div className="button-with-tooltip">
                <button
                  className="kjshshn button-animate"
                  onClick={handleShowJournalPopup}
                >
                  {t("Submit")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="quote-loader">
            <TailSpin
              height="40"
              width="40"
              color="#fff"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <div className="study-plan-container">
            <div className="study-plan">
              <h2 className="jhhhhhj">{t("Study Plan")}</h2>
              <div className="dash-date">{todaysDate()}</div>
              {subTests?.length === 0 ? (
                <div
                  className="study-plan-container"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "6vh",
                  }}
                >
                  <div
                    className="view-btn button-animate"
                    style={{ width: "fit-content" }}
                    onClick={handleGenerate}
                  >
                    {t("Generate StudyPlan")}
                  </div>
                </div>
              ) : (
                <div className="labCards1">
                  {subTests?.map((subtest, index) => (
                    <div
                      key={index}
                      className={
                        activeCard === subtest.subTestId
                          ? "card-click-animation"
                          : "card-normal"
                      }
                      onClick={() =>
                        handleCardClick(subtest.subTestId, subtest.subTestName)
                      }
                    >
                      <Card
                        image={subtest.subTestImage}
                        title={subtest.subTestName}
                        description={subtest.subTestDescription}
                      />
                    </div>
                  ))}
                </div>
              )}
              {subTests?.length !== 0 && (
                <div
                  className="all-subtests"
                  onClick={() => navigate("/view-study-plan")}
                >
                  <button className="gfhfh button-animate">
                    {t("All Subtests")}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <JournalPopup
        isOpen={isJournalPopupOpen}
        onClose={handleCloseJournalPopup}
        setSuccessScreen={() => setSuccessScreen(true)}
      />
      {isQuotePopupOpen && (
        <EditQuotePopup
          quote={quote}
          onClose={handleCloseQuotePopup}
          onSave={handleSaveQuote}
        />
      )}
      {successScreen && (
        <SuccessScreen
          onClose={() => setSuccessScreen(false)}
          title={t("New Journal Entry")}
        />
      )}
    </div>
  );
};

export default Dashboard;
