import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import baseUrl from "../baseUrl";
import Loader from "./loader"; // Import the Loader component
import "../styles/textPopup.css";
import { useTranslation } from "react-i18next";

const TextEditorPopup = ({
  id,
  initialHeader,
  initialContent,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const [header, setHeader] = useState(initialHeader);
  const [content, setContent] = useState(initialContent);
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when saving starts

    try {
      console.log("header : ", header);
      console.log("content : ", content);
      const response = await axios.patch(
        `${baseUrl}/skills/lessons/${id}/text`,
        { textHeader: header, textContent: content },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Text updated successfully:", response.data);
      onSave(id, header, content); // Update the text in the parent component
      onClose(); // Close the popup
    } catch (error) {
      console.error("Error updating text:", error);
    } finally {
      setLoading(false); // Set loading to false when saving is complete
    }
  };

  return (
    <div className="popup">
      {loading && <Loader />} {/* Show loader when loading is true */}
      <div className={`popup-inner ${loading ? "blur-background" : ""}`}>
        <h2>{t("Edit Text")}</h2>
        <label className="labhjj">
          {t("Header")}:
          <input
            type="text"
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            className="oiuyuu"
          />
        </label>
        <label className="labhjj">{t("Content")}:</label>
        <ReactQuill theme="snow" value={content} onChange={setContent} />
        <div className="bgfd">
          <button className="bito" onClick={handleSubmit} type="submit">
            {t("Save")}
          </button>
          <button className="bito" onClick={onClose} type="button">
            {t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

TextEditorPopup.propTypes = {
  id: PropTypes.string.isRequired,
  initialHeader: PropTypes.string.isRequired,
  initialContent: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TextEditorPopup;
