import React, { useEffect, useState, useContext } from "react";
import "../styles/associationBox.css";
import { useNavigate } from "react-router-dom";
import { RequestModal } from "./RequestModal";
import { RequestView } from "./RequestView";
import axios from "axios";
import baseUrl from "../baseUrl";
import { SuccessScreen } from "./SuccessScreen";
import { IoIosSend } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { CiSearch } from "react-icons/ci";
import back from "../assets/images/back.svg";
import { FaCheck } from "react-icons/fa6";
import comment from "../assets/images/comment1.svg";
import request from "../assets/images/request1.svg";
import filterBlack from "../assets/images/filterB.svg";
import filterWhite from "../assets/images/filterW.svg";
import { ThemeContext } from "../ThemeContext";

const AssociationBox = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterSelected, setFilterSelected] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [selectedRequest, setSSelectedRequest] = useState(null);
  const [successScreen, setSuccessScreen] = useState(false);
  const [requests, setRequests] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredRequests, setFilteredRequests] = useState([]);
  const navigate = useNavigate();

  const getRequests = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/association/get-all-request`
      );
      if (response && response.data) {
        setRequests(response.data.requests);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getRequests();
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSearch = (searchText) => {
    setQuery(searchText);
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = requests;

      if (filterSelected === 1) {
        filtered = filtered.filter((request) =>
          isRequestNew(request.createdAt)
        );
      } else if (filterSelected === 2) {
        filtered = filtered.filter((request) => request.imageUrl);
      } else if (filterSelected === 3) {
        filtered = filtered.filter((request) => request.facts);
      }

      if (query.trim() !== "") {
        filtered = filtered.filter((request) => {
          const facts = request?.facts?.toLowerCase();
          const search = query?.toLowerCase();
          return facts?.includes(search);
        });
      }

      setFilteredRequests(filtered);
    };

    applyFilters();
  }, [requests, filterSelected, query]);

  const onClose = () => {
    setClicked(false);
  };

  const onCloseReq = () => {
    setSSelectedRequest(null);
  };

  const handleFilterSelected = (option) => {
    if (filterSelected === option) {
      setFilterSelected(null);
    } else {
      setFilterSelected(option);
    }
  };

  function isRequestNew(updatedAt) {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const today = new Date();
    const libraryDate = new Date(updatedAt);
    const ageInDays = Math.round((today - libraryDate) / oneDay);
    return ageInDays <= 7;
  }

  const trimFacts = (facts, maxLength) => {
    if (facts.length <= maxLength) return facts;
    return facts.slice(0, maxLength) + "...";
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer" style={{ justifyContent: "start" }}>
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
      </div>

      <div className="poiyt">
        <div className="heahder">
          <h1 className="title">{t("Association Box")}</h1>
          <div className="groww"></div>
          <div className="btn-container">
            <button
              className="requestButton button-animate"
              title={t("New Request")}
              onClick={() => setClicked(true)}
            >
              <img src={request} alt="" className="request" />
              {t("Request")}
            </button>
            <div className="filterContainer">
              <button
                className="filterButton button-animate"
                title={t("Filter Requests")}
                onClick={toggleFilter}
              >
                <img
                  src={theme === "dark" ? filterWhite : filterBlack}
                  alt=""
                  className="request"
                />
                {t("Filter")}
              </button>
              {filterOpen && (
                <div className="filterDropdown">
                  <div
                    className="filterOption"
                    onClick={() => handleFilterSelected(1)}
                  >
                    {filterSelected === 1 && <FaCheck />}
                    {t("New Requests")}
                  </div>
                  <div
                    className="filterOption"
                    onClick={() => handleFilterSelected(2)}
                  >
                    {filterSelected === 2 && <FaCheck />}
                    {t("Figures")}
                  </div>
                  <div
                    className="filterOption"
                    onClick={() => handleFilterSelected(3)}
                  >
                    {filterSelected === 3 && <FaCheck />}
                    {t("Facts")}
                  </div>
                </div>
              )}
            </div>

            <div
              className="search-bar-association"
              title={t("Search Requests")}
            >
              <CiSearch size={"1.5rem"} color="#A8A8A8" />
              <input
                type="text"
                className="searchInput"
                placeholder={t("Search")}
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value); // Update the query state
                  handleSearch(e.target.value); // Call handleSearch with the updated query value
                }}
              />
            </div>
          </div>
        </div>
        <div className="cardsGrid">
          {filteredRequests.map((card, index) =>
            card.imageUrl ? (
              <div
                className="card1 associationbox button-animate"
                key={index}
                onClick={() => setSSelectedRequest(card)}
                title={t("Click to view details")}
              >
                {isRequestNew(card.createdAt) && (
                  <div className="cardNewBadge new-badge">{t("NEW")}</div>
                )}
                <div className="cardImage">
                  <img src={card.imageUrl} alt="" />
                </div>
                <span className="flex-container-card">
                  <div className="cardReplies">
                    {card.comments?.length} {t("Replies")}
                  </div>
                  <div className="cardReplyIcon">
                    <img src={comment} alt="" className="request" />
                  </div>
                </span>
              </div>
            ) : (
              <div
                className="card1"
                key={index}
                onClick={() => setSSelectedRequest(card)}
              >
                {isRequestNew(card.createdAt) && (
                  <div className="cardNewBadge new-badge">{t("NEW")}</div>
                )}
                <div className="cardImage" style={{ padding: "5px" }}>
                  <p>{trimFacts(card.facts, 40)}</p>
                </div>
                <span className="flex-container-card">
                  <div className="cardReplies">
                    {card.comments?.length} {t("Replies")}
                  </div>
                  <div className="cardReplyIcon">
                    <img src={comment} alt="" className="request" />
                  </div>
                </span>
              </div>
            )
          )}
        </div>
      </div>

      {clicked && (
        <RequestModal
          onClose={onClose}
          setRequests={setRequests}
          requests={requests}
          setSuccessScreen={() => setSuccessScreen(true)}
        />
      )}
      {selectedRequest && (
        <RequestView onClose={onCloseReq} selectedRequest={selectedRequest} />
      )}
      {successScreen && (
        <SuccessScreen
          associationScreen={true}
          onClose={() => setSuccessScreen(false)}
          title={t("New Association Entry")}
        />
      )}
    </div>
  );
};

export default AssociationBox;
