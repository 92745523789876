import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        Dashboard: "Dashboard",
        "Skills Lab": "Skills Lab",
        Subtests: "Subtests",
        Statistics: "Statistics",
        Archive: "Archive",
        Settings: "Settings",
        Back: "Back",
        "Study plan": "Study plan",
        Personalization: "Personalization",
        "Cashback Promotion": "Cashback Promotion",
        Report: "Report",
        Register: "Register",
        Logout: "Logout",
        German: "German",
        "Total Users": "Total Users",
        "Total Cashback": "Total Cashback",
        "Average Sessions": "Average Sessions",
        "Active Users": "Active Users",
        "Weekly Users": "Weekly Users",
        "Monthly Users": "Monthly Users",
        "User List": "User List",
        Name: "Name",
        Email: "Email",
        Passwort: "Password",
        "Last Login": "Last Login",
        "Days created": "Days created",
        Previous: "Previous",
        Next: "Next",
        Page: "Page",
        of: "of",
        English: "English",
        "Cashback List": "Cashback List",
        Url: "Url",
        Action: "Action",
        "Cashback deleted successfully": "Cashback deleted successfully",
        "Error deleting cashback": "Error deleting cashback",
        Loading: "Loading",
        "Daily Journal Statistics": "Daily Journal Statistics",
        "Well Being Factors": "Well Being Factors",
        "Stress Level Factors": "Stress Level Factors",
        "Happiness Factors": "Happiness Factors",
        "Ticket Statistics": "Ticket Statistics",
        "Number of Tickets": "Number of Tickets",
        "User Creation Statistics": "User Creation Statistics",
        "User Creations Per Month": "User Creations Per Month",
        "Daily usage": "Daily usage",
        "SubTest Scores": "SubTest Scores",
        "Select a SubTest": "Select a SubTest",
        Scores: "Scores",
        "Activation period (Registration)": "Activation period (Registration)",
        "Average Daily Sessions": "Average Daily Sessions",
        "Average Weekly Sessions": "Average Weekly Sessions",
        "Average Well-Being Factor": "Average Well-Being Factor",
        "Average Stress Level Factor": "Average Stress Level Factor",
        Strength: "Strength",
        Weakness: "Weakness",
        "Absolved Exercises": "Absolved Exercises",
        "Average Exercise Score": "Average Exercise Score",
        "Full Score exercises": "Full Score exercises",
        "Average Happiness Level Factor": "Average Happiness Level Factor",
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December",
        Edit: "Edit",
        Close: "Close",
        "Not logged": "Not logged",
        "Failed to fetch data": "Failed to fetch data",
        "Study Plan": "Study Plan",
        View: "View",
        Generate: "Generate",
        Customize: "Customize",
        Notification: "Notification",
        Close: "Close",
        "Form submitted successfully!": "Form submitted successfully!",
        "Error submitting form. Please try again.":
          "Error submitting form. Please try again.",
        "Promotion Icon": "Promotion Icon",
        "Post a video about our service on YouTube or TikTok.":
          "Post a video about our service on YouTube or TikTok.",
        "Insert your personal ID (see below) in the description of your post.":
          "Insert your personal ID (see below) in the description of your post.",
        "Insert a link to your video in the box below.":
          "Insert a link to your video in the box below.",
        "Submit! Your coupon code is on the way.":
          "Submit! Your coupon code is on the way.",
        "Your personal ID": "Your personal ID",
        "URL of your post": "URL of your post",
        Submit: "Submit",
        "Active Since": "Active Since",
        "Ø Hours/Week": "Ø Hours/Week",
        "Ø Hours/Day": "Ø Hours/Day",
        "24/24": "24/24",
        "Well-being": "Well-being",
        "Stress Level": "Stress Level",
        Countdown: "Countdown",
        "updated successfully": "updated successfully",
        "Failed to update": "Failed to update",
        "Reported Content": "Reported Content",
        "Reported Association Content": "Reported Association Content",
        "Request ID": "Request ID",
        "User Email": "User Email",
        Title: "Title",
        Description: "Description",
        Action: "Action",
        "No reported requests found": "No reported requests found",
        "Reported Medical Libraries": "Reported Medical Libraries",
        "Library ID": "Library ID",
        "PDF URL": "PDF URL",
        "PDF Link": "PDF Link",
        "No reported medical libraries found":
          "No reported medical libraries found",
        "Request report flag updated successfully":
          "Request report flag updated successfully",
        "Error updating request report flag":
          "Error updating request report flag",
        "Library report flag updated successfully":
          "Library report flag updated successfully",
        "Error updating library report flag":
          "Error updating library report flag",
        "Loading data...": "Loading data...",
        Lessons: "Lektionen",
        Journals: "Journals",
        Subtests: "Subtests",
        "Daily Journal": "Daily Journal",
        "Lesson added to favorites": "Lektion zu Favoriten hinzugefügt",
        "Lesson removed from favorites": "Lektion aus Favoriten entfernt",
        "Journal added to favorites": "Tagebuch zu Favoriten hinzugefügt",
        "Journal removed from favorites": "Tagebuch aus Favoriten entfernt",
        "Subtest added to favorites": "Untertest zu Favoriten hinzugefügt",
        "Subtest removed from favorites": "Untertest aus Favoriten entfernt",
        "Failed to update favorites": "Fehler beim Aktualisieren der Favoriten",
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        st: "st",
        nd: "nd",
        rd: "rd",
        th: "th",
        "Good Morning": "Good Morning",
        "Good Afternoon": "Good Afternoon",
        "Good Evening": "Good Evening",
        "How are you today?": "How are you today?",
        Submit: "Submit",
        "New Journal Entry": "Neuer Tagebucheintrag",
        "Success!": "Success!",
        Exit: "Exit",
        "Help and Support": "Help and Support",
        "Raise a Ticket": "Raise a Ticket",
        "Your E-Mail": "Your E-Mail",
        "email@medgang.com": "Your E-Mail",
        "Select Category": "Select Category",
        "Please Select a Category": "Please Select a Category",
        "Explain your issue in brief": "Explain your issue in brief",
        hbhjbgh: "Explain your issue in brief",
        "Find us at": "Find us at",
        "Phone number:": "Phone number:",
        Connect: "Connect",
        "Daily Journal Entry": "Daily Journal Entry",
        "Today's Well-Being": "Today's Well-Being",
        "Today's Stress Level": "Today's Stress Level",
        Mood: "Mood",
        "Write A Note:": "Write A Note:",
        Save: "Save",
        "You can only save one journal entry per day.":
          "You can only save one journal entry per day.",
        "Journal saved and archived successfully!":
          "Journal saved and archived successfully!",
        "There was an error archiving the journal entry.":
          "There was an error archiving the journal entry.",
        "There was an error submitting the form.":
          "There was an error submitting the form.",
        "Add Lesson": "Add Lesson",
        "No lessons available": "No lessons available",
        "Lesson deleted successfully!": "Lesson deleted successfully!",
        "Error deleting lesson.": "Error deleting lesson.",
        "Are you sure you want to delete this lesson?":
          "Are you sure you want to delete this lesson?",
        "Loading...": "Loading...",
        "Edit Video": "Edit Video",
        "Edit Text": "Edit Text",
        "No content available": "No content available",
        "Add Video": "Add Video",
        OR: "OR",
        "Add Text": "Add Text",
        Done: "Done",
        Subtests: "Subtests",
        "General Information": "General Information",
        "Add SubTest": "Add SubTest",
        Previous: "Previous",
        Page: "Page",
        of: "of",
        Next: "Next",
        "Edit General Information": "Edit General Information",
        Save: "Save",
        Cancel: "Cancel",
        "Are you sure you want to delete this subtest?":
          "Are you sure you want to delete this subtest?",
        "Subtest deleted successfully!": "Subtest deleted successfully!",
        "Error deleting subtest. Please try again.":
          "Error deleting subtest. Please try again.",
        "Skills Lab": "Skills Lab",
        "Add Skill": "Add Skill",
        Previous: "Previous",
        Page: "Page",
        of: "of",
        Next: "Next",
        "Skill deleted successfully!": "Skill deleted successfully!",
        "Error deleting skill. Please try again.":
          "Error deleting skill. Please try again.",
        "Are you sure you want to delete this skill?":
          "Are you sure you want to delete this skill?",
        "Select a Video": "Select a Video",
        Save: "Save",
        Cancel: "Cancel",
        "No video selected": "No video selected",
        "Video updated successfully:": "Video updated successfully:",
        "Error updating video:": "Error updating video:",
        "Edit Lab": "Edit Lab",
        "Lab Title:": "Lab Title:",
        "Description:": "Description:",
        "Upload Image:": "Upload Image:",
        Selected: "Selected",
        Save: "Save",
        Cancel: "Cancel",
        "Skill updated:": "Skill updated:",
        "There was an error updating the skill!":
          "There was an error updating the skill!",
        "Edit Text": "Edit Text",
        Header: "Header",
        Content: "Content",
        Save: "Save",
        Cancel: "Cancel",
        " Overview": " Overview",
        Characteristics: "Characteristics",
        Duration: "Duration",
        Skills: "Skills",
        Points: "Points",
        Logs: "Logs",
        Termination: "Termination",
        "Description 1": "Description 1",
        "Description 2": "Description 2",
        "Description 3": "Description 3",
        "No characteristics available": "No characteristics available",
        Complete: "Complete",
        "New Exercise Entry": "New Exercise Entry",
        Course: "Course",
        "Continue learning": "Continue learning",
        Section: "Section",
        Description: "Description",
        "Edit Fact": "Edit Fact",
        Fact: "Fact",
        Cancel: "Cancel",
        Save: "Save",
        "Edit Characteristics": "Edit Characteristics",
        "Loading chart...": "Loading chart...",
        "Absolved Exercises": "Absolved Exercises",
        "Maximum Score": "Maximum Score",
        "Minimum Score": "Minimum Score",
        Chart: "Chart",
        Statistics: "Statistics",
        Page: "Page",
        of: "of",
        Previous: "Previous",
        Next: "Next",
        Loading: "Loading",
        "Subtest deleted successfully!": "Subtest deleted successfully!",
        "Error deleting subtest. Please try again.":
          "Error deleting subtest. Please try again.",
        "Tatsache bearbeiten": "Edit Fact",
        "Merkmale bearbeiten": "Edit Characteristics",
        Abbrechen: "Cancel",
        Speichern: "Save",
        "Video auswählen": "select video",
        "Diagramm wird geladen...": "Loading chart...",
        Abschnitt: "Section",
        Kurs: "Course",
        "Weiter lernen": "Continue learning",
        Bearbeiten: "Edit",
        "Allgemeine Informationen": "General Information",
        Fähigkeiten: "Skills",
        Punkte: "Points",
        Protokolle: "Logs",
        Beendigung: "Termination",
        "Beschreibung 1": "Description 1",
        "Beschreibung 2": "Description 2",
        "Beschreibung 3": "Description 3",
        "Keine Merkmale verfügbar": "No characteristics available",
        Abgeschlossen: "Complete",
        "Neuer Übungseintrag": "New Exercise Entry",
        "Loading...": "Loading...",
        "Edit Video": "Edit Video",
        "Edit Text": "Edit Text",
        "No content available": "No content available",
        "Add Video": "Add Video",
        OR: "OR",
        "Add Text": "Add Text",
        Done: "Done",
        "Success!": "Success!",
        Close: "Close",
        "New Exercise Entry": "New Exercise Entry",
        "Subtest Name": "Subtest Name",
        Result: "Result",
        "Result (0-24)": "Result (0-24)",
        "Test time": "Test time",
        Next: "Next",
        "Question 1": "Question 1",
        "Question 2": "Question 2",
        "Question 3": "Question 3",
        Answer: "Answer",
        Previous: "Previous",
        Submit: "Submit",
        "Please fill all the fields in Step 1":
          "Please fill all the fields in Step 1",
        "Please fill all the answers before submitting":
          "Please fill all the answers before submitting",
        "Your Entry is saved": "Your Entry is saved",
        "Error submitting exercise entry. Please try again.":
          "Error submitting exercise entry. Please try again.",
        "Edit Subtest": "Edit Subtest",
        "Subtest Title": "Subtest Title",
        Description: "Description",
        "Upload Image": "Upload Image",
        Save: "Save",
        Cancel: "Cancel",
        "Edit Text": "Edit Text",
        Header: "Header",
        Content: "Content",
        Save: "Save",
        Cancel: "Cancel",
        "Edit Fact": "Edit Fact",
        Fact: "Fact",
        Save: "Save",
        Cancel: "Cancel",
        "Edit Quote": "Edit Quote",
        "Drop excel file containing quotes":
          "Drop excel file containing quotes",
        "File Uploaded": "File Uploaded",
        "excel-file-logo": "excel-file-logo",
        Save: "Save",
        Cancel: "Cancel",
        "Edit Lab": "Edit Lab",
        "Lab Title": "Lab Title",
        Description: "Description",
        "Upload Image": "Upload Image",
        Selected: "Selected",
        Save: "Save",
        Cancel: "Cancel",
        "Add Lesson for": "Add Lesson for",
        "Lesson Title": "Lesson Title",
        Save: "Save",
        Cancel: "Cancel",
        "Association Box": "Assoziationskiste",
        Request: "Request",
        Filter: "Filter",
        "Number of Replays": "Number of Replays",
        Replays: "Replays",
        Search: "Search",
        NEW: "NEW",
        Replies: "Replies",
        "New Association Entry": "New Association Entry",
        "Create Skill": "Create Skill",
        "Skill Title": "Skill Title",
        Description: "Description",
        Create: "Create",
        Cancel: "Cancel",
        "Add Lesson for": "Add Lesson for",
        "Lesson Title": "Lesson Title",
        Save: "Save",
        Cancel: "Cancel",
        "Add SubTest": "Add SubTest",
        "SubTest Topic": "SubTest Topic",
        "SubTest Description": "SubTest Description",
        Save: "Save",
        Cancel: "Cancel",
        "Edit Lesson": "Edit Lesson",
        "Lesson Topic": "Lesson Topic",
        Save: "Save",
        Cancel: "Cancel",
        "Admin Panel": "Admin Panel",
        "User Entries": "User Entries",
        Name: "Name",
        Email: "Email",
        Subtest: "Subtest",
        "Created At": "Created At",
        Result: "Result",
        Previous: "Previous",
        Next: "Next",
        Page: "Page",
        of: "of",
        Numbers: "Numbers",
        Graph: "Graph",
        "Entry deleted successfully": "Entry deleted successfully",
        "Something went wrong": "Something went wrong",
        "Change Password": "Change Password",
        "Current password": "Current password",
        "New Password": "New Password",
        "Confirm New Password": "Confirm New Password",
        "Passwords do not match.": "Passwords do not match.",
        Proceed: "Proceed",
        "Password must be at least 8 characters long.":
          "Password must be at least 8 characters long.",
        "Password must include at least one special character.":
          "Password must include at least one special character.",
        "Password must include at least one number.":
          "Password must include at least one number.",
        "Password is strong": "Password is strong",
        "Please ensure the password meets all criteria.":
          "Please ensure the password meets all criteria.",
        "New password and confirmation password do not match.":
          "New password and confirmation password do not match.",
        "Successfully changed password!": "Successfully changed password!",
        "Error in changing password": "Error in changing password",
        REGISTER: "REGISTRIEREN",
        Name: "Name",
        Email: "Email",
        Password: "Password",
        "User will also be an admin now": "User will also be an admin now",
        "Make user an Admin?": "Make user an Admin?",
        Register: "Register",
        "Registration Successful": "Registration Successful",
        "Study Plan": "Study Plan",
        "Study Plan1": "Study Plan",
        TODAY: "TODAY",
        Save: "Save",
        Manage: "Manage",
        Sunday: "Sunday",
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        "Medical Library": "Medical Library",
        "Search for library": "Search for library",
        "Only Verified": "Only Verified",
        "Add Content": "Add Content",
        NEW: "NEW",
        Admin: "Admin",
        MedgangMember: "Medgang Member",
        "New Medical Entry": "New Medical Entry",
        "Library item reported!": "Library item reported!",
        "Failed to update library item report flag":
          "Failed to update library item report flag",
        "New Request": "New Request",
        Facts: "Facts",
        Figures: "Figures",
        "Upload photo": "Upload photo",
        "Max file size 2mb": "Max file size 2mb",
        "File Uploaded": "File Uploaded",
        Publish: "Publish",
        "New Content": "New Content",
        Title: "Title",
        Description: "Description",
        "Upload photo": "Upload photo",
        "Max file size 2mb": "Max file size 2mb",
        "File Uploaded": "File Uploaded",
        Publish: "Publish",
        "No file selected.": "No file selected.",
        "Error uploading file:": "Error uploading file:",
        "Uploaded on": "Uploaded on",
        "Verified by Medgang": "Verifiziert von der MedGang",
        Download: "Download",
        "Skool-Community": "Skool-Community",
        "Choose Attachment": "Choose Attachment",
        "All Subtests": "All Subtests",
        "Medgang Logo": "Medgang Logo",
        "Welcome Back!": "Welcome Back!",
        Email: "Email",
        Password: "Password",
        "Stay logged in": "Stay logged in",
        Login: "Login",
        "Please fill all the fields": "Please fill all the fields",
        "Please provide a general assessment":
          "Please provide a general assessment",
        "Please answer the general question":
          "Please answer the general question",
        "Please answer all questions": " Please answer all questions",
        "Please answer all additional questions":
          "Please answer all additional questions",
        "Please provide all required information":
          "Please provide all required information",
        "Please provide additional observations":
          "Please provide additional observations",
        "User not logged in": "User not logged in",
        "You have to complete the previous course units before you can open this one.":
          "You have to complete the previous course units before you can open this one.",
      },
    },
    de: {
      translation: {
        "New Requests": "Neue Anfragen",
        "Be the first one to help out!": "Leiste erste Hilfe!",
        "How this works": "Wie es funktioniert",
        "This is where you can explain in detail how this works and why should he/she use it to.":
          "Basierend auf deinen bisherigen Leistungen in den Übungen wird ein individueller Lernplan für dich erstellt. Solltest du nicht genügend Zeit haben, um alle vorgeschlagenen Untertests in vollem Umfang zu trainieren, kannst du den Lernplan später selbstständig anpassen und einzelne Untertests entfernen. Wir empfehlen, deinen Lernplan alle zwei Wochen zu aktualisieren.",
        Figures: "Figuren",
        Failure: "Fehler",
        "Go to Dashboard": "Zum Dashboard",
        "Generate Study Plan": "Lernplan generieren",
        "Hold On Tight": "Einen Augenblick",
        "We are generating a study plan for you. It may take some time. Do not close this tab.":
          "Wir generieren einen Lernplan für dich. Schließe dieses Fenster nicht.",
        Facts: "Fakten",
        "Oops..": "Ups!",
        "You have not attempted these subtests":
          "Du hast noch keine Übungen in den folgenden Untertests absolviert.",
        Dashboard: "Zentrale",
        "Skills Lab": "Fähigkeiten Labor",
        Subtests: "Untertests",
        Statistics: "Statistiken",
        Statistic: "Diese Änderungen betreffen nicht die Untertest-Sektionen",
        Request: "Assoziationsanfrage",
        Archive: "Archiv",
        Settings: "Einstellungen",
        "Study plan": "Lernplan",
        Personalization: "Personalisierung",
        "Cashback Promotion": "Cashback-Aktion",
        Report: "Bericht",
        Register: "Registrieren",
        Logout: "Ausloggen",
        German: "Deutsch",
        "Total Users": "Gesamtbenutzer",
        "Total Cashback": "Gesamt-Cashback",
        "Average Sessions": "Durchschnittliche Sitzungen",
        "Active Users": "Aktive Benutzer",
        "Weekly Users": "Wöchentliche Benutzer",
        "Monthly Users": "Monatliche Benutzer",
        "User List": "Benutzerliste",
        Name: "Name",
        Email: "E-Mail",
        "Last Login": "Letzte Anmeldung",
        "Days created": "Erstellte Tage",
        Previous: "Vorherige",
        Next: "Nächste",
        Page: "Seite",
        of: "von",
        Back: "Zurück",
        "Cashback List": "Cashback-Liste",
        Url: "URL",
        Action: "Aktion",
        "Cashback deleted successfully": "Cashback erfolgreich gelöscht",
        "Error deleting cashback": "Fehler beim Löschen des Cashbacks",
        Loading: "Laden",
        "Daily Journal Statistics": "Tägliche Tagebuchstatistiken",
        "Well Being Factors": "Wohlbefindensfaktoren",
        "Stress Level Factors": "Stressniveau-Faktoren",
        "Happiness Factors": "Glücksfaktoren",
        "Ticket Statistics": "Ticket-Statistiken",
        "Number of Tickets": "Anzahl der Tickets",
        "User Creation Statistics": "Benutzererstellungsstatistiken",
        "User Creations Per Month": "Benutzererstellungen pro Monat",
        "Daily usage": "Tägliche Nutzung",
        "SubTest Scores": "Untertest Ergebnisse",
        "Select a SubTest": "Wählen Sie einen Untertest",
        Scores: "Ergebnisse",
        "All Subtests": "Alle Untertests",
        "Activation period (Registration)":
          "Aktivierungszeitraum (Registrierung)",
        "Average Daily Sessions": " Ø tägliche Sitzungen",
        "Average Weekly Sessions": "Durchschnittliche wöchentliche Sitzungen",
        "Average Well-Being Level": "Ø Wohlbefinden",
        "Average Stress Level": " Ø Stresslevel",
        Strength: "Stärke",
        Weakness: "Schwäche",
        "Absolved Exercises": "Absolvierte Übungen",
        "Average Exercise Score": "Ø Punktzahl",
        "Full Score exercises": "24/24-Übungen",
        "Average Happiness Level": "Ø Glückslevel",
        January: "Januar",
        February: "Februar",
        March: "März",
        April: "April",
        May: "Mai",
        June: "Juni",
        July: "Juli",
        August: "August",
        September: "September",
        October: "Oktober",
        November: "November",
        December: "Dezember",
        "Not logged": "Nicht eingeloggt",
        "Failed to fetch data": "Daten konnten nicht abgerufen werden",
        "Study Plan": "Lernplan",
        View: "Ansehen",
        Generate: "Generieren",
        Customize: "Anpassen",
        Notification: "Benachrichtigung",
        Close: "Schließen",
        "Form submitted successfully!": "Formular erfolgreich eingereicht!",
        "Error submitting form. Please try again.":
          "Fehler beim Einreichen des Formulars. Bitte versuche es erneut.",
        "Promotion Icon": "Werbesymbol",
        "Post a video about our service on YouTube or TikTok.":
          "Veröffentliche ein Video über den TMS-Blueprint auf YouTube oder TikTok.",
        "Insert your personal ID (see below) in the description of your post.":
          "Füge deine persönliche ID (s. unten) in die Videobeschreibung ein.",
        "Insert a link to your video in the box below.":
          "Füge den Link zu deinem Video in das unten stehende Feld ein.",
        "Submit! Your coupon code is on the way.":
          "Absenden! Dein Gutscheincode ist unterwegs.",
        "Your personal ID": "Deine persönliche ID",
        "URL of your post": "URL deines Beitrags",
        Submit: "Absenden",
        "Active Since": "Aktiv seit",
        "Ø Hours/Week": "Ø Stunden/Woche",
        "Ø Hours/Day": "Ø Stunden/Tag",
        "24/24": "24/24",
        "Well-being": "Wohlbefinden",
        "Stress Level": "Stressniveau",
        Countdown: "Countdown",
        "updated successfully": "erfolgreich aktualisiert",
        "Failed to update": "Aktualisierung fehlgeschlagen",
        "Reported Content": "Gemeldete Inhalte",
        "Reported Association Content": "Gemeldete Verbandsinhalte",
        "Request ID": "Anfrage-ID",
        "User Email": "Benutzer-E-Mail",
        Title: "Titel",
        Description: "Beschreibung",
        Action: "Aktion",
        "No reported requests found": "Keine gemeldeten Anfragen gefunden",
        "Reported Medical Libraries": "Gemeldete medizinische Bibliotheken",
        "Library ID": "Bibliotheks-ID",
        "PDF URL": "PDF-URL",
        "PDF Link": "PDF-Link",
        "No reported medical libraries found":
          "Keine gemeldeten medizinischen Bibliotheken gefunden",
        "Request report flag updated successfully":
          "Meldungskennzeichen der Anfrage erfolgreich aktualisiert",
        "Error updating request report flag":
          "Fehler beim Aktualisieren des Meldungskennzeichens der Anfrage",
        "Library report flag updated successfully":
          "Meldungskennzeichen der Bibliothek erfolgreich aktualisiert",
        "Error updating library report flag":
          "Fehler beim Aktualisieren des Meldungskennzeichens der Bibliothek",
        "Loading data...": "Daten werden geladen...",
        Lessons: "Lektionen",
        Journals: "Tägliches Journal",
        Subtests: "Untertests",
        "Daily Journal": "Tägliches Journal",
        "Lesson added to favorites": "Lektion zu Favoriten hinzugefügt",
        "Lesson removed from favorites": "Lektion aus Favoriten entfernt",
        "Journal added to favorites": "Tagebuch zu Favoriten hinzugefügt",
        "Journal removed from favorites": "Tagebuch aus Favoriten entfernt",
        "Subtest added to favorites": "Untertest zu Favoriten hinzugefügt",
        "Subtest removed from favorites": "Untertest aus Favoriten entfernt",
        "Failed to update favorites": "Fehler beim Aktualisieren der Favoriten",
        Sunday: "Sonntag",
        Monday: "Montag",
        Tuesday: "Dienstag",
        Wednesday: "Mittwoch",
        Thursday: "Donnerstag",
        Friday: "Freitag",
        Saturday: "Samstag",
        st: "er",
        nd: "ter",
        rd: "ter",
        th: "ter",
        "Good Morning": "Guten Morgen",
        "Good Afternoon": "Guten Tag",
        "Good Evening": "Guten Abend",
        "How are you today?": "Wie geht es dir heute?",
        Submit: "Einreichen",
        "New Journal Entry": "Neuer Tagebucheintrag",
        "Success!": "Erfolg!",
        Exit: "Beenden",
        "Help and Support": "Hilfe und Unterstützung",
        "Raise a Ticket": "Wende dich gerne an unseren Support!",
        "Your E-Mail": "Deine E-Mail",
        "email@medgang.com": "email@beispiel.de",
        "Select Category": "Kategorie auswählen",
        "Please Select a Category": "Bitte wählen Sie eine Kategorie",
        "Explain your issue in brief": "Wie können wir dir helfen?",
        hbhjbgh: "Beschreibe dein Anliegen…",
        "Find us at": "Impressum",
        "Phone number:": "Telefonnummer:",
        Connect: "Verbinden",
        "Daily Journal Entry": "Täglicher Journaleintrag",
        "Today's Well-Being": "Heutiges Wohlbefinden",
        "Today's Stress Level": " Heutiges Stressniveau",
        Mood: "Stimmung",
        "Write A Note:": "Schreibe eine Notiz:",
        Save: "Speichern",
        "You can only save one journal entry per day.":
          "Sie können nur einen Tagebucheintrag pro Tag speichern.",
        "Journal saved and archived successfully!":
          "Tagebuch erfolgreich gespeichert und archiviert!",
        "There was an error archiving the journal entry.":
          "Beim Archivieren des Tagebucheintrags ist ein Fehler aufgetreten.",
        "There was an error submitting the form.":
          "Beim Absenden des Formulars ist ein Fehler aufgetreten.",
        "Add Lesson": "Lektion hinzufügen",
        "No lessons available": "Keine Lektionen verfügbar",
        "Lesson deleted successfully!": "Lektion erfolgreich gelöscht!",
        "Error deleting lesson.": "Fehler beim Löschen der Lektion.",
        "Are you sure you want to delete this lesson?":
          "Sind Sie sicher, dass Sie diese Lektion löschen möchten?",
        "Loading...": "Laden...",
        "Edit Video": "Video bearbeiten",
        "Edit Text": "Text bearbeiten",
        "No content available": "Kein Inhalt verfügbar",
        "Add Video": "Video hinzufügen",
        OR: "ODER",
        "Add Text": "Text hinzufügen",
        Done: "Fertig",
        Subtests: "Untertests",
        "General Information": "Allgemeine Informationen",
        "Add SubTest": "Untertest hinzufügen",
        Previous: "Vorherige",
        Page: "Seite",
        of: "von",
        Next: "Nächste",
        "Edit General Information": "Allgemeine Informationen bearbeiten",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Save & Exit": "Speichern & Verlassen",
        "Are you sure you want to delete this subtest?":
          "Sind Sie sicher, dass Sie diesen Untertest löschen möchten?",
        "Subtest deleted successfully!": "Untertest erfolgreich gelöscht!",
        "Error deleting subtest. Please try again.":
          "Fehler beim Löschen des Untertests. Bitte versuchen Sie es erneut.",
        "Skills Lab": "Skills Lab",
        "Add Skill": "Fähigkeit hinzufügen",
        Previous: "Vorherige",
        Page: "Seite",
        of: "von",
        Next: "Nächste",
        "Skill deleted successfully!": "Fähigkeit erfolgreich gelöscht!",
        "Error deleting skill. Please try again.":
          "Fehler beim Löschen der Fähigkeit. Bitte versuchen Sie es erneut.",
        "Are you sure you want to delete this skill?":
          "Sind Sie sicher, dass Sie diese Fähigkeit löschen möchten?",
        "Select a Video": "Video auswählen",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "No video selected": "Kein Video ausgewählt",
        "Video updated successfully:": "Video erfolgreich aktualisiert:",
        "Error updating video:": "Fehler beim Aktualisieren des Videos:",
        "Edit Lab": "Lab bearbeiten",
        "Lab Title:": "Lab-Titel:",
        "Description:": "Beschreibung:",
        "Upload Image:": "Bild hochladen:",
        Selected: "Ausgewählt",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Skill updated:": "Skill aktualisiert:",
        "There was an error updating the skill!":
          "Es gab einen Fehler beim Aktualisieren der Fähigkeiten!",
        "Edit Text": "Text bearbeiten",
        Header: "Überschrift",
        Content: "Inhalt",
        Save: "Speichern",
        Cancel: "Abbrechen",
        " Overview": " Übersicht",
        Characteristics: "Eigenschaften",
        Duration: "Dauer",
        Skills: "Fähigkeiten",
        Points: "Punkte",
        Logs: "Protokolle",
        Termination: "Beendigung",
        "Description 1": "Beschreibung 1",
        "Description 2": "Beschreibung 2",
        "Description 3": "Beschreibung 3",
        "No characteristics available": "Keine Merkmale verfügbar",
        Complete: "Abgeschlossen",
        "Exercise Entries": "Übungseinträge",
        "New Exercise Entry": "Neuer Übungseintrag",
        Course: "Kurs",
        "Continue learning": "Weiter lernen",
        Section: "Abschnitt",
        Description: "Beschreibung",
        "Edit Fact": "Tatsache bearbeiten",
        Fact: "Tatsache",
        Cancel: "Abbrechen",
        Save: "Speichern",
        "Edit Characteristics": "Merkmale bearbeiten",
        "Loading chart...": "Diagramm wird geladen...",
        "Absolved Exercises": "Absolvierte Übungen",
        "Maximum Score": "Maximale Punktzahl",
        "Minimum Score": "Minimale Punktzahl",
        Chart: "Diagramm",
        Statistics: "Statistiken",
        Page: "Seite",
        of: "von",
        Previous: "Vorherige",
        Next: "Nächste",
        Loading: "Laden",
        "Subtest deleted successfully!": "Untertest erfolgreich gelöscht!",
        "Error deleting subtest. Please try again.":
          "Fehler beim Löschen des Subtests. Bitte versuchen Sie es erneut.",
        "Tatsache bearbeiten": "Tatsache bearbeiten",
        "Merkmale bearbeiten": "Merkmale bearbeiten",
        Abbrechen: "Abbrechen",
        Speichern: "Speichern",
        "Diagramm wird geladen...": "Diagramm wird geladen...",
        Abschnitt: "Kreuzblick 101",
        Kurs: "Kurs",
        "Weiter lernen": "Weiter lernen",
        Bearbeiten: "Bearbeiten",
        "Allgemeine Informationen": "Allgemeines zum TMS",
        Fähigkeiten: "Fähigkeiten",
        Punkte: "Punkte",
        Protokolle: "Protokolle",
        Beendigung: "Beendigung",
        "Beschreibung 1": "Beschreibung 1",
        "Beschreibung 2": "Beschreibung 2",
        "Beschreibung 3": "Beschreibung 3",
        "Keine Merkmale verfügbar": "Keine Merkmale verfügbar",
        Abgeschlossen: "Abgeschlossen",
        "Neuer Übungseintrag": "Neuer Übungseintrag",
        "Loading...": "Laden...",
        "Edit Video": "Video bearbeiten",
        "Edit Text": "Text bearbeiten",
        "No content available": "Kein Inhalt verfügbar",
        "Add Video": "Video hinzufügen",
        OR: "ODER",
        "Add Text": "Text hinzufügen",
        Done: "Fertig",
        "Success!": "Erfolg!",
        Close: "Schließen",
        "New Exercise Entry": "Neuer Übungseintrag",
        "Subtest Name": "Untertestname",
        Result: "Ergebnis",
        "Result (0-24)": "Ergebnis (0-24)",
        "Test time": "Testzeit",
        Next: "Weiter",
        "Question 1": "Frage 1",
        "Question 2": "Frage 2",
        "Question 3": "Frage 3",
        Answer: "Antwort",
        Previous: "Zurück",
        Submit: "Absenden",
        "Please fill all the fields in Step 1":
          "Bitte füllen Sie alle Felder in Schritt 1 aus",
        "Please fill all the answers before submitting":
          "Bitte füllen Sie alle Antworten aus, bevor Sie einreichen",
        "Your Entry is saved": "Ihr Eintrag ist gespeichert",
        "Error submitting exercise entry. Please try again.":
          "Fehler beim Einreichen des Übungseintrags. Bitte versuchen Sie es erneut.",
        "Edit Subtest": "Untertest bearbeiten",
        "Subtest Title": "Untertesttitel",
        Description: "Beschreibung",
        "Upload Image": "Bild hochladen",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Edit Text": "Text bearbeiten",
        Header: "Überschrift",
        Content: "Inhalt",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Edit Fact": "Tatsache bearbeiten",
        Fact: "Tatsache",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Edit Quote": "Zitat bearbeiten",
        "Drop excel file containing quotes":
          "Excel-Datei mit Zitaten hier ablegen",
        "File Uploaded": "Datei hochgeladen",
        "excel-file-logo": "Excel-Datei-Logo",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Edit Lab": "Labor bearbeiten",
        "Lab Title": "Labortitel",
        Description: "Beschreibung",
        "Upload Image": "Bild hochladen",
        Selected: "Ausgewählt",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Add Lesson for": "Lektion hinzufügen für",
        "Lesson Title": "Lektionen-Titel",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Association Box": "Assoziationsbox",
        Request: "Assoziationsanfrage",
        Filter: "Filter",
        "Number of Replays": "Anzahl der Wiedergaben",
        Replays: "Wiedergaben",
        Search: "Suche",
        NEW: "NEU",
        Replies: "Antworten",
        "New Association Entry": "Neuer Assoziationseintrag",
        "Create Skill": "Fähigkeit erstellen",
        "Skill Title": "Fähigkeitstitel",
        Description: "Beschreibung",
        Create: "Erstellen",
        Cancel: "Abbrechen",
        "Add Lesson for": "Lektion hinzufügen für",
        "Lesson Title": "Lektionstitel",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Add SubTest": "Untertest hinzufügen",
        "SubTest Topic": "Untertest Thema",
        "SubTest Description": "Untertest Beschreibung",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Edit Lesson": "Lektion bearbeiten",
        "Lesson Topic": "Thema der Lektion",
        Save: "Speichern",
        Cancel: "Abbrechen",
        "Admin Panel": "Admin-Bereich",
        "User Entries": "Benutzereinträge",
        Name: "Name",
        Email: "E-Mail",
        Subtest: "Untertest",
        "Created At": "Erstellt am",
        Result: "Ergebnis",
        Previous: "Zurück",
        Next: "Weiter",
        Page: "Seite",
        of: "von",
        "Entry deleted successfully": "Eintrag erfolgreich gelöscht",
        "Something went wrong": "Etwas ist schief gelaufen",
        "Change Password": "Passwort ändern",
        "Current password": "Aktuelles Passwort",
        "New Password": "Neues Passwort",
        "Confirm New Password": "Neues Passwort bestätigen",
        "Passwords do not match.": "Passwörter stimmen nicht überein.",
        Proceed: "Fortfahren",
        "Password must be at least 8 characters long.":
          "Das Passwort muss mindestens 8 Zeichen lang sein.",
        "Password must include at least one special character.":
          "Das Passwort muss mindestens ein Sonderzeichen enthalten.",
        "Password must include at least one number.":
          "Das Passwort muss mindestens eine Zahl enthalten.",
        "Password is strong": "Das Passwort ist stark",
        "Please ensure the password meets all criteria.":
          "Bitte stelle sicher, dass das Passwort alle Kriterien erfüllt.",
        "New password and confirmation password do not match.":
          "Das neue Passwort und das Bestätigungspasswort stimmen nicht überein.",
        "Successfully changed password!": "Passwort erfolgreich geändert!",
        "Error in changing password": "Fehler beim Ändern des Passworts",
        REGISTER: "REGISTRIEREN",
        Name: "Name",
        Email: "Email",
        Password: "Passwort",
        "User will also be an admin now":
          "Benutzer wird jetzt auch ein Admin sein",
        "Make user an Admin?": "Benutzer zum Admin machen?",
        Register: "Registrieren",
        "Registration Successful": "Registrierung erfolgreich",
        "Study Plan": "Lernplan",
        "Study Plan1": "Lernplan anpassen",
        TODAY: "HEUTE",
        Save: "Speichern",
        Manage: "Anpassen",
        Sunday: "Sonntag",
        Monday: "Montag",
        Tuesday: "Dienstag",
        Wednesday: "Mittwoch",
        Thursday: "Donnerstag",
        Friday: "Freitag",
        Saturday: "Samstag",
        "Medical Library": "GangBib",
        "Search for library": "In Bibliothek suchen...",
        "Only Verified": "Nur Verifiziert",
        "Add Content": "Inhalt hinzufügen",
        "Generate Password": "Generiere Passwort",
        NEW: "NEU",
        Admin: "Admin",
        MedgangMember: "Medgang-Mitglied",
        "New Medical Entry": "Neuer medizinischer Eintrag",
        "Library item reported!": "Bibliothekseintrag gemeldet!",
        "Failed to update library item report flag":
          "Fehler beim Aktualisieren der Meldungskennzeichnung des Bibliothekseintrags",
        "New Request": "Neue Assoziationsanfrage",
        Facts: "Fakten",
        Figures: "Figuren",
        "Upload photo": "Foto hochladen",
        "Max file size: 2mb": "Maximale Dateigröße: 2 MB",
        "File Uploaded": "Datei hochgeladen",
        Publish: "Veröffentlichen",
        "New Content": "Neuer Inhalt",
        Title: "Titel",
        Description: "Beschreibung",
        "Upload photo": "Foto hochladen",
        "Max file size: 2mb": "Maximale Dateigröße: 2 MB",
        "File Uploaded": "Datei hochgeladen",
        Publish: "Veröffentlichen",
        "No file selected.": "Keine Datei ausgewählt.",
        "Error uploading file:": "Fehler beim Hochladen der Datei:",
        "Uploaded on": "Hochgeladen am",
        "Verified by Medgang": "Verifiziert von der MedGang",
        Download: "Herunterladen",
        "Skool-Community": "Skool-Community",
        "Choose Attachment": "Anlage ",
        "Video auswählen": "Video auswählen",
        Numbers: "Zahlen",
        Graph: "Grafik",
        Graphs: "Graphen",
        Edit: "Bearbeiten",
        Close: "Schließen",
        "Medgang Logo": "Medgang-Logo",
        "Welcome Back!": "Willkommen zurück!",
        Email: "E-Mail",
        Password: "Passwort",
        "Stay logged in": "Eingeloggt bleiben",
        Login: "Anmelden",
        "User not logged in": "Benutzer nicht eingeloggt",
        English: "Englisch",
        "Favorites updated...": "Einen Augenblick…",
        "Click here or drag and drop your file":
          "Klicke hier oder ziehe deine Datei hierhin",
        "Write comments here...": "Was fällt dir dazu ein?",
        "You have to complete the previous course units before you can open this one.":
          "Du musst die vorherigen Kurseinheiten absolvieren, bevor du diese öffnen kannst.",
        "Please fill all the fields": "Bitte füllen Sie alle Felder aus.",
        "Please provide a general assessment":
          "Bitte geben Sie eine allgemeine Einschätzung ab.",
        "Please answer the general question":
          "Bitte beantworten Sie die allgemeine Frage.",
        "Please answer all questions": " Bitte beantworten Sie alle Fragen.",
        "Please answer all additional questions":
          "Bitte beantworten Sie alle zusätzlichen Fragen.",
        "Please provide all required information":
          "Bitte geben Sie alle erforderlichen Informationen an.",
        "Please provide additional observations":
          "Bitte geben Sie zusätzliche Beobachtungen an.",
      },
    },
  },
  lng: localStorage.getItem("language") || "de", // Set initial language
  fallbackLng: "en", // Fallback language if translation is not available
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
