import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { MdDelete } from "react-icons/md";

export const SingleSubtest = ({ subtest, deleteSubtest, selectedDay, day, index }) => {
  const [iconColor, setIconColor] = useState("#E15D5D"); // Initial color

  const handleClick = () => {
    deleteSubtest(day, subtest);
    setIconColor("#00FF00");
  };

  return (
    <Draggable
      key={`${day}-${subtest.subTestId}`}
      draggableId={`${day}-${subtest.subTestId}`}
      index={index}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="subtestItem"
        >
          <div className="first-list-item" style={{ width: "70%" }}>
            <div className="subtestIcon">
              <img src={subtest.subTestImage} alt="img" />
            </div>
            <span className="subtest-title">{subtest.subTestName}</span>
          </div>
          {selectedDay === day && (
            <div className="first-list-item list-icons">
              <MdDelete
                size={"1.5rem"}
                color={iconColor}
                cursor={"pointer"}
                onClick={handleClick}
              />
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default SingleSubtest;