import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import baseUrl from "../baseUrl";
import "../styles/addLessonPopup.css";
import Loader from "./loader"; // Import the Loader component
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

const CreateSubTestLessonPopup = ({
  subTestId,
  subTestName,
  onClose,
  onCreate,
}) => {
  const { t } = useTranslation(); // Use the useTranslation hook
  const [lessonTitle, setLessonTitle] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when saving starts
    console.log("subTestName: ", subTestName);
    console.log("lesson: ", lessonTitle);
    try {
      const response = await axios.post(
        `${baseUrl}/subtest/post-lesson-subtest`,
        {
          subTestName,
          lessonTopic: lessonTitle,
        }
      );
      console.log("Lesson created successfully:", response.data);
      onCreate(response.data.lesson); // Pass the new lesson to the parent component
      onClose(); // Close the popup
    } catch (error) {
      console.error("Error creating lesson:", error);
    } finally {
      setLoading(false); // Set loading to false when saving is complete
    }
  };

  return (
    <div className="popup">
      {loading && <Loader />} {/* Show loader when loading is true */}
      <div className={`popup-inner ${loading ? "blur-background" : ""}`}>
        <h2>
          {t("Add Lesson for")} {subTestName}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="lessonInput">
            <label className="labhjj">
              {t("Lesson Title")}:
              <input
                type="text"
                value={lessonTitle}
                onChange={(e) => setLessonTitle(e.target.value)}
                className="oiuyuu"
              />
            </label>
          </div>
          <button className="bito" type="submit">
            {t("Save")}
          </button>
          <button className="bito" type="button" onClick={onClose}>
            {t("Cancel")}
          </button>
        </form>
      </div>
    </div>
  );
};

CreateSubTestLessonPopup.propTypes = {
  subTestId: PropTypes.string.isRequired,
  subTestName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateSubTestLessonPopup;
