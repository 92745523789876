import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/cashback.css";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import promotionIcon from "../assets/images/atm.png"; // Assuming you have an icon
import baseUrl from "../baseUrl";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next"; // Import useTranslation
import Cookies from "js-cookie";

const CashbackPromotion = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false); // State for loader
  const [modalMessage, setModalMessage] = useState(""); // State for modal message
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const token = Cookies.get("authToken");
  const [Id, setId] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Show loader

    const data = {
      url,
      Id,
    };

    try {
      const response = await axios.post(`${baseUrl}/settings/cashback`, data, {
        headers: {
          "Content-Type": "application/json",
          "auth-token": token,
        },
      });
      console.log(response.data);
      setModalMessage(t("Form submitted successfully!")); // Set success message
      setIsModalOpen(true); // Show modal
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setModalMessage(t("Error submitting form. Please try again.")); // Set error message
      setIsModalOpen(true); // Show modal
    } finally {
      setLoading(false); // Hide loader
      window.location.reload();
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setId(uuidv4().slice(0, 13));
  }, []);

  return (
    <div className="dashContainer">
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="successjj">{t("Notification")}</h2>
            <p className="kjhjj">{modalMessage}</p>
            <button className="yuioi" onClick={closeModal}>
              {t("Close")}
            </button>
          </div>
        </div>
      )}
      <Header title={t("Cashback Promotion")} />
      <section className="promotionSection">
        <img
          src={promotionIcon}
          alt={t("Promotion Icon")}
          className="promotionIcon"
        />
        <h2 className="cashnab">{t("Cashback Promotion")}</h2>
        <ol className="promotionInstructions">
          <li className="gagchhg">
            {t("Post a video about our service on YouTube or TikTok.")}
          </li>
          <li className="gagchhg">
            {t(
              "Insert your personal ID (see below) in the description of your post."
            )}
          </li>
          <li className="gagchhg">
            {t("Insert a link to your video in the box below.")}
          </li>
          <li className="gagchhg">
            {t("Submit! Your coupon code is on the way.")}
          </li>
        </ol>
        <form onSubmit={handleSubmit} className="promotionForm">
          <div className="personalId">
            {t("Your personal ID")}: {Id}
          </div>
          <input
            type="text"
            placeholder={t("URL of your post")}
            className="urlInput"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
          />
          <button type="submit" className="submitjjButton button-animate">
            {t("Submit")}
          </button>
        </form>
      </section>
    </div>
  );
};

export default CashbackPromotion;
