import React, { useState, useEffect } from "react";
import "../styles/personalization.css";
import Header from "./header";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import baseUrl from "../baseUrl";
import { toast } from "sonner";
import { useTranslation } from "react-i18next"; // Import useTranslation
import Cookies from "js-cookie";

const Personalization = () => {
  const { t } = useTranslation();
  const [personalize, setPersonalize] = useState({});
  const [loading, setLoading] = useState(true);
  const objectToSend = {
    "Active Since": "Active",
    "Ø Hours/Week": "hoursWeek",
    "Ø Hours/Day": "HoursDay",
    Strength: "Strength",
    Weakness: "Weakness",
    "Absolved Exercises": "AbsolvedExercise",
    "Well-being": "Wellbeing",
    "Stress Level": "StressLevel",
    Countdown: "Countdown",
    "Ø/24": "Avg24",
    "24/24": "By24",
  };

  useEffect(() => {
    const fetchPersonalize = async () => {
      try {
        const authToken = Cookies.get("authToken");
        const response = await axios.get(`${baseUrl}/support/personalize`, {
          headers: {
            "auth-token": authToken,
          },
        });

        const data = response.data;
        console.log("Data:", data);
        const obj = {
          "Active Since": data.Active,
          "Ø Hours/Week": data.hoursWeek,
          "Ø Hours/Day": data.HoursDay,
          Strength: data.Strength,
          Weakness: data.Weakness,
          "Absolved Exercises": data.AbsolvedExercise,
          "Well-being": data.Wellbeing,
          "Ø/24": data.Avg24,
          "24/24": data.By24,
          "Stress Level": data.StressLevel,
          Countdown: data.Countdown,
        };
        setPersonalize(obj);
        localStorage.setItem("personalize", JSON.stringify(obj));

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchPersonalize();
  }, []);

  const handleCheckboxChange = (stat) => async (event) => {
    try {
      const authToken = Cookies.get("authToken");

      // Update the local state after successful API call
      setPersonalize((prevPersonalize) => {
        const updatedPersonalize = {
          ...prevPersonalize,
          [stat]: event.target.checked,
        };

        // Update localStorage
        localStorage.setItem("personalize", JSON.stringify(updatedPersonalize));
        console.log(updatedPersonalize);
        return updatedPersonalize;
      });

      const updatedData = { [objectToSend[stat]]: event.target.checked };
      await axios.patch(`${baseUrl}/support/personalize`, updatedData, {
        headers: {
          "auth-token": authToken,
        },
      });

      // Display a success toast notification
      // Display a success toast notification
      toast.success(`${t(stat)} ${t("updated successfully")}`);
    } catch (error) {
      console.error(error);
      // Display an error toast notification
      toast.error(`${t("Failed to update")} "${stat}"`);
    }
  };

  return (
    <div className="dashContainer">
      <Header title={t("Personalization")} />
      <section className="statisticsSection">
        <h1>Statistiken</h1>
        <h2>{t("Statistic")}</h2>

        {loading ? (
          <div className="loader-container">
            <TailSpin
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <div className="statisticsGrid">
            {[
              //"Ø Hours/Week", // Active Since: removed from the array
              "Ø Hours/Day",
              "Well-being",
              "Stress Level",
              "Absolved Exercises",
              "Strength",
              "Weakness",
              "Ø/24",
              "24/24",
              // "Countdown",
            ].map((stat, index) => (
              <div key={index} className="statItem">
                <span>{t(stat)}</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={personalize[stat]}
                    onChange={handleCheckboxChange(stat)}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            ))}
          </div>
        )}
      </section>
    </div>
  );
};

export default Personalization;
