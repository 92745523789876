import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../styles/sidePanel.css";
import logo from "../assets/images/logoName.svg";
import logout from "../assets/images/Logout.svg";
import logos from "../assets/images/sideLogo.png";
import closeIcon from "../assets/images/cross.png"; // Add a close icon image
import { useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../baseUrl";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { TailSpin } from "react-loader-spinner";
import dashboardWhite from "../assets/images/Dashboard-w.svg";
import dashboardBlack from "../assets/images/Dashboard-b.svg";
import skillWhite from "../assets/images/SkillsLab-w.svg";
import skillBlack from "../assets/images/SkillsLab-b.svg";
import subtestWhite from "../assets/images/Subtests-w.svg";
import subtestBlack from "../assets/images/Subtests-b.svg";
import statisticsWhite from "../assets/images/statistics-w.svg";
import statisticsBlack from "../assets/images/statistics-b.svg";
import archiveWhite from "../assets/images/archive-w.svg";
import archiveBlack from "../assets/images/archive-b.svg";
import settingWhite from "../assets/images/settings-w.svg";
import settingBlack from "../assets/images/settings-b.svg";
import logoutWhite from "../assets/images/logout-w.svg";
import logoutBlack from "../assets/images/logout-b.svg";
import dashboard from "../assets/images/dashboad.svg";
import skill from "../assets/images/skill.svg";
import subtest from "../assets/images/subtest.svg";
import statistics from "../assets/images/statistic.svg";
import archive from "../assets/images/archieve.svg";
import setting from "../assets/images/settings.svg";
import { ThemeContext } from "../ThemeContext"; // Import ThemeContext

const SidePanel = ({ isOpen, onClose, isScreenSmall }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const currentPath = location.pathname;

  const [active, setActive] = useState("");
  const [activeDropdown, setActiveDropdown] = useState("");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const mode = useSelector((state) => state.user.mode);
  const role = localStorage.getItem("Role"); // Get role from localStorage
  const { theme } = useContext(ThemeContext); // Use theme context

  const routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: theme === "dark" ? dashboardWhite : dashboardBlack,
      activeIcon: dashboard,
    },
    {
      path: "/skillsLab",
      name: "Skills Lab",
      icon: theme === "dark" ? skillWhite : skillBlack,
      activeIcon: skill,
    },
    {
      path: "/subtests",
      name: t("Subtests"),
      icon: theme === "dark" ? subtestWhite : subtestBlack,
      activeIcon: subtest,
    },
    {
      path: "/statistics",
      name: t("Statistics"),
      icon: theme === "dark" ? statisticsWhite : statisticsBlack,
      activeIcon: statistics,
    },
    {
      path: "/archive",
      name: t("Archive"),
      icon: theme === "dark" ? archiveWhite : archiveBlack,
      activeIcon: archive,
    },
    {
      path: "/settings",
      name: t("Settings"),
      icon: theme === "dark" ? settingWhite : settingBlack,
      activeIcon: setting,
    },
    {
      path: "/logout",
      name: t("Logout"),
      icon: theme === "dark" ? logoutWhite : logoutBlack,
      className: "logout-item",
    }, // Added Logout with a unique class name
  ].filter(Boolean); // Filter out null values

  const settingsRoutes = [
    { path: "/settings/studyPlan", name: t("Study plan") },
    { path: "/settings/personalization", name: t("Personalization") },
    { path: "/settings/cashbackPromotion", name: t("Cashback Promotion") },
    role === "admin" && { path: "/report", name: t("Report") },
    role === "admin" && { path: "/register", name: t("Register") },
    role === "admin" && { path: "/admin-panel", name: "Admin Panel" },
    { path: "/settings/change-password", name: t("Change Password") },
  ].filter(Boolean); // Filter out null values

  useEffect(() => {
    const activeRoute = routes.find((route) => route.path === currentPath);
    if (activeRoute) {
      setActive(activeRoute.name);
      setActiveDropdown(""); // Clear dropdown active state when navigating to main routes
      setSettingsOpen(false); // Close settings dropdown when navigating to other routes
    } else {
      const activeSettingsRoute = settingsRoutes.find(
        (route) => route.path === currentPath
      );
      if (activeSettingsRoute) {
        setActive(t("Settings")); // Keep "Settings" active
        setActiveDropdown(activeSettingsRoute.name);
        setSettingsOpen(true); // Keep settings dropdown open when navigating within settings
      }
    }
  }, [currentPath, t]);

  useEffect(() => {
    if (role === "student") {
      i18n.changeLanguage("de");
      localStorage.setItem("language", "de");
    }
  }, [role, i18n]);

  const handleNavigation = (route, name) => {
    if (route === "/logout") {
      handleLogout();
      return;
    }

    navigate(route);
    if (routes.some((r) => r.path === route)) {
      setActive(name);
      setActiveDropdown("");
      setSettingsOpen(false); // Close settings dropdown when navigating to non-settings routes
    } else {
      setActive(t("Settings"));
      setActiveDropdown(name);
      setSettingsOpen(true); // Open settings dropdown when navigating to settings
    }
    if (isScreenSmall && route !== "/settings") {
      onClose(); // Close side panel if screen is small and navigating to non-settings route
    }
  };

  const handleLogout = async () => {
    setLogoutLoading(true);
    const authToken = Cookies.get("authToken"); // Get token from cookies

    if (authToken) {
      try {
        const response = await axios.patch(
          `${baseUrl}/auth/logout`,
          {},
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );

        // Clear user session only if the logout request is successful
        Cookies.remove("authToken");
        localStorage.removeItem("authToken");
        localStorage.removeItem("Username");
        localStorage.removeItem("Role");
        localStorage.removeItem("login");
        localStorage.removeItem("personalize");

        // Redirect to login page
        navigate("/");
        if (isScreenSmall) {
          onClose(); // Close side panel if screen is small
        }
      } catch (error) {
        console.error("Error logging out:", error);
        // Optionally, you can handle the error, such as showing a toast notification
      } finally {
        setLogoutLoading(false);
        if (isScreenSmall) {
          onClose(); // Close side panel if screen is small
        }
      }
    } else {
      setLogoutLoading(false);
      if (isScreenSmall) {
        onClose(); // Close side panel if screen is small
      }
    }
  };

  const toggleSettingsDropdown = () => {
    setActive(t("Settings"));
    setSettingsOpen(!settingsOpen);
  };

  const handleLogoClick = () => {
    navigate("/dashboard");
    if (isScreenSmall) {
      onClose();
    }
  };

  const handleLanguageToggle = () => {
    const newLanguage = i18n.language === "de" ? "en" : "de";
    i18n.changeLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  return (
    <div className={`side-panel ${mode} ${isOpen ? "open" : ""}`}>
      <div className="sidebar">
        <div className="side-panel-header">
          <img
            src={logos}
            alt=""
            className="logo"
            onClick={handleLogoClick}
            onContextMenu={(e) => e.preventDefault()}
          />
          {/* <img
            src={logo}
            alt="logo"
            className="logo"
            onClick={handleLogoClick}
            onContextMenu={(e) => e.preventDefault()}
          />
          <img
            src={logonames}
            alt="logo name"
            className="logonames"
            onClick={handleLogoClick}
            onContextMenu={(e) => e.preventDefault()}
          /> */}
          {isScreenSmall && (
            <img
              src={closeIcon}
              alt="Close Icon"
              className="close-icon"
              onClick={onClose}
            />
          )}
        </div>
        <div className="side-panel-content">
          <ul className="sidePanelUl">
            {routes.map((route) => (
              <React.Fragment key={route.name}>
                <div
                  className={`flexList ${
                    active === route.name ||
                    (route.name === t("Settings") && settingsOpen)
                      ? "active"
                      : ""
                  } ${route.className || ""}`} // Add the unique class name if exists
                  onClick={() =>
                    route.name === t("Settings")
                      ? toggleSettingsDropdown()
                      : handleNavigation(route.path, route.name)
                  }
                >
                  {route.icon && (
                    <img
                      src={
                        active === route.name ||
                        (route.name === t("Settings") && settingsOpen)
                          ? route.activeIcon
                          : route.icon
                      }
                      alt="icons"
                      className="icons"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  )}
                  <li
                    className={`side-panel-list ${mode} ${
                      active === route.name ||
                      (route.name === t("Settings") && settingsOpen)
                        ? "active"
                        : ""
                    }`}
                  >
                    {route.name}
                  </li>
                </div>
                {route.name === t("Settings") && settingsOpen && (
                  <ul
                    className={`settingsDropdown ${settingsOpen ? "open" : ""}`}
                  >
                    {settingsRoutes.map((subRoute) => (
                      <li
                        key={subRoute.name}
                        className={`settingsDropdownItem ${
                          activeDropdown === subRoute.name ? "active" : ""
                        }`}
                        onClick={() =>
                          handleNavigation(subRoute.path, subRoute.name)
                        }
                      >
                        {subRoute.name}
                      </li>
                    ))}
                    {role === "admin" && (
                      <li className="settingsDropdownItem">
                        <div
                          className="language-toggle"
                          onClick={handleLanguageToggle}
                        >
                          <span className="njhbvg">{t("English")}</span>
                          <div className="switch">
                            <input
                              type="checkbox"
                              checked={i18n.language === "en"}
                              readOnly
                            />
                            <span className="slider"></span>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                )}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidePanel;
