import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "../styles/videoPopup.css";
import baseUrl from "../baseUrl";
import { useTranslation } from "react-i18next"; // Import useTranslation

const VideoSelectorPopup = ({ id, onClose, onSave }) => {
  const { t } = useTranslation();
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [progress, setProgress] = useState(0);

  const handleVideoChange = (e) => {
    setSelectedVideo(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedVideo) {
      console.error("Kein Video ausgewählt");
      return;
    }
    setIsLoading(true);
    setProgress(0);

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 95) {
          clearInterval(interval);
          return prev;
        }
        return prev + 5;
      });
    }, 1000);

    try {
      const formData = new FormData();
      formData.append("video", selectedVideo);

      const response = await axios.patch(
        `${baseUrl}/skills/lessons/${id}/video`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Video erfolgreich aktualisiert:", response.data);
      setProgress(100);
      onSave(id, response.data.videoUrl);
      onClose(); // Close the popup
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Videos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="popup1">
      <div className="popup-inner">
        <h2>{t("Video auswählen")}</h2>
        <form onSubmit={handleSubmit} className="formVideo">
          <input
            type="file"
            accept="video/*"
            onChange={handleVideoChange}
            className="input_video"
            ref={fileInputRef}
          />
          <div className="bitonBtn">
            <button className="bito" type="submit" disabled={isLoading}>
              {isLoading ? (
                <div style={{ display: "flex", gap: "0.5rem" }}>
                  <span class="loader-video"></span>
                  {progress}%
                </div>
              ) : (
                t("Speichern")
              )}
            </button>
            <button className="bito" type="button" onClick={onClose}>
              {t("Abbrechen")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

VideoSelectorPopup.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default VideoSelectorPopup;
