import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import baseUrl from "../baseUrl";
import Loader from "./loader"; // Import the Loader component
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

const CreateSubTestPopup = ({ onClose, onCreate }) => {
  const { t } = useTranslation(); // Use the useTranslation hook
  const [subTestTopic, setSubTestTopic] = useState("");
  const [subTestDescription, setSubTestDescription] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when saving starts

    try {
      const response = await axios.post(`${baseUrl}/subtest/post-subtest`, {
        subTestName: subTestTopic,
        subTestDescription: subTestDescription,
      });
      console.log("SubTest created successfully:", response.data);
      onCreate(response.data.subTest); // Pass the new subTest to the parent component
      onClose(); // Close the popup
    } catch (error) {
      console.error("Error creating subtest:", error);
    } finally {
      setLoading(false); // Set loading to false when saving is complete
    }
  };

  return (
    <div className="popup">
      {loading && <Loader />} {/* Show loader when loading is true */}
      <div className={`popup-inner ${loading ? "blur-background" : ""}`}>
        <h2>{t("Add SubTest")}</h2>
        <form onSubmit={handleSubmit}>
          <label className="labhjj">
            {t("SubTest Topic")}:
            <input
              type="text"
              value={subTestTopic}
              onChange={(e) => setSubTestTopic(e.target.value)}
              className="oiuyuu"
            />
          </label>
          <label className="labhjj">
            {t("SubTest Description")}:
            <textarea
              value={subTestDescription}
              onChange={(e) => setSubTestDescription(e.target.value)}
              className="oiuyuu"
            ></textarea>
          </label>
          <button className="bito" type="submit">
            {t("Save")}
          </button>
          <button className="bito" type="button" onClick={onClose}>
            {t("Cancel")}
          </button>
        </form>
      </div>
    </div>
  );
};

CreateSubTestPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateSubTestPopup;
