import React, { useState } from "react";
import PropTypes from "prop-types";
import defaultCardImage from "../assets/images/cardImage.png"; // Adjust the path as necessary

const truncate = (text, maxLength) => {
  if (!text) return ""; // Ensure text is a string
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const Card = ({ title, description, image }) => {
  const truncatedTitle = truncate(title, 16); // Adjust the length as needed
  const truncatedDescription = truncate(description, 70); // Adjust the length as needed
  const [isHovered, setIsHovered] = useState(false);

  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click menu
  };

  return (
    <div
      className="card button-animate"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="labbox">
        <img
          src={image || defaultCardImage}
          alt="cardImage"
          loading="lazy"
          className="cardimg"
          onContextMenu={handleContextMenu} // Add event listener to prevent right-click
        />
        <div className="labTitle">{truncatedTitle}</div>
        <div className="description">{truncatedDescription}</div>
        {/* Tooltip element */}
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

Card.defaultProps = {
  title: "",
  description: "",
  image: defaultCardImage,
};

export default Card;
