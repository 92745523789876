import React from 'react'
import { IoMdCloseCircleOutline } from "react-icons/io";

export const ConfirmationScreen = ({data, onClose, onOkay}) => {
    return (
    <div className='popup'>
        <div className="success-screen">
            <div className="popup-header">
                <h3>Confirmation</h3>
                <IoMdCloseCircleOutline cursor={"pointer"} size={"1.5rem"} onClick={onClose}/>
            </div>

            <div className="popup-main-heading">
                <h3>Are you sure?</h3>
                <h6>You are going to remove <i>{data?.name}</i> </h6>
            
            </div>
            <div className="btn-container-edit" style={{justifyContent:"space-evenly"}}>
                
                <button className="btn-submit" style={{width:"40%"}} onClick={onOkay}>
                    Yes
                </button> 
                <button className="btn-submit" style={{width:"40%"}} onClick={onClose}>
                    Cancel
                </button> 
            </div>
        </div>
    </div>
  )
}
