import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import "../styles/archive.css";
import defaultCardImage from "../assets/images/cardImage.png"; // Placeholder icon, replace with actual path
import baseUrl from "../baseUrl";
import axios from "axios";
import JournalPopup from "./staticJournalPopup"; // Import the JournalPopup component
import ExerciseEntryPopup from "./excersisePopup"; // Import the ExerciseEntryPopup component
import { FaRegStar, FaStar } from "react-icons/fa";
import { toast } from "sonner";
import { useTranslation } from "react-i18next"; // Import useTranslation
import Cookies from "js-cookie";
import filter from "../assets/images/filter.svg";
import filterWhite from "../assets/images/filterWhite.svg";
import { ThemeContext } from "../ThemeContext"; // Import ThemeContext

const LoadingDots = () => {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(dots => dots.length < 3 ? dots + '.' : '.');
    }, 500); // Change dots every 500 milliseconds

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <div>
      Einen Augenblick
      <span style={{ display: 'inline-block', width: '30px', textAlign: 'left' }}>{dots}</span>
    </div>
  );
};

const Archive = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const token = Cookies.get("authToken");
  const [archives, setArchives] = useState([]);
  const [favorites, setFavorites] = useState({
    lessons: [],
    journals: [],
    exercises: [],
  });
  const [selectedJournal, setSelectedJournal] = useState(null);
  const [selectedSubtest, setSelectedSubtest] = useState(null); // State for selected subtest
  const [lessons, setLessons] = useState([]);
  const [journals, setJournals] = useState([]);
  const [subtests, setSubtests] = useState([]);
  const [exerciseEntries, setExerciseEntries] = useState([]); // State for exercise entries
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filterClick, setFilterClick] = useState(false);
  const [filterStar, setFilterStar] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { theme } = useContext(ThemeContext); // Use theme context

  const navigate = useNavigate();

  const getArchives = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${baseUrl}/archive/get-archive`, {
        headers: {
          "auth-token": token,
        },
      });
      console.log("response: ", response);
      if (response.data) {
        const updatedArchives = response.data.archive;
        const journals = updatedArchives.journals.map((item) => ({
          ...item,
          type: "journal",
        }));
        const lessons = updatedArchives.lessons.map((item) => ({
          ...item,
          type: "lesson",
        }));
        const exercises = updatedArchives.exerciseEntries.map((item) => ({
          ...item,
          type: "exercise",
        }));
        const allArchives = [...journals, ...lessons, ...exercises];
        setLessons(lessons);
        setJournals(journals);
        setExerciseEntries(exercises);

        const userResponse = await axios.get(
          `${baseUrl}/archive/user-archive`,
          {
            headers: {
              "auth-token": token,
            },
          }
        );

        setFavorites({
          lessons: userResponse.data.favoriteLessons || [],
          journals: userResponse.data.favoriteJournals || [],
          exercises: userResponse.data.favoriteExercise || [],
        });

        const initialItems = allArchives.slice(0, itemsPerPage);
        setCurrentItems(initialItems);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArchives();
  }, []);

  // PAGINATION LOGIC
  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = archives.slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(currentItems);
  }, [archives, currentPage]);

  const handleStarClick = async (item) => {
    const itemType = item.type;
    let itemId;
    if (itemType === "lesson") {
      itemId = item.lessonId;
    } else if (itemType === "journal") {
      itemId = item.journalId;
    } else if (itemType === "exercise") {
      itemId = item.subTestId;
    }

    const originalFavorites = { ...favorites };
    const updatedFavorites = { ...favorites };

    // Ensure the array exists before accessing it
    if (!updatedFavorites[itemType + "s"]) {
      updatedFavorites[itemType + "s"] = [];
    }

    const itemIndex = updatedFavorites[itemType + "s"].indexOf(itemId);
    const action = itemIndex > -1 ? "removed from" : "added to";
    if (itemIndex > -1) {
      updatedFavorites[itemType + "s"].splice(itemIndex, 1);
    } else {
      updatedFavorites[itemType + "s"].push(itemId);
    }
    setFavorites(updatedFavorites);

    try {
      const response = await axios.patch(
        `${baseUrl}/archive/favorite-item`,
        { itemId, itemType },
        { headers: { "auth-token": token } }
      );

      setFavorites((f) => ({
        ...f,
        [itemType + "s"]: response.data.favorites,
      }));
      toast.success(`Item successfully ${action} favorites`);
    } catch (error) {
      console.error("Error:", error);
      setFavorites(originalFavorites);
      toast.error(t("Failed to update favorites"));
    }
  };

  const handleFilterStar = () => {
    try {
      setFilterStar(!filterStar);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const renderStar = (item) => {
    const isFavorite =
      (item.type === "lesson" && favorites?.lessons?.includes(item.lessonId)) ||
      (item.type === "journal" &&
        favorites?.journals?.includes(item.journalId)) ||
      (item.type === "exercise" &&
        favorites?.exercises?.includes(item.subTestId));

    return isFavorite ? (
      <FaStar size={24} color="#00BA8C" className="star-scale" />
    ) : (
      <FaRegStar size={24} className="star-scale" />
    );
  };

  // NAVIGATION FEATURE
  const handleCardClick = async (item) => {
    if (item.type === "lesson") {
      navigate(`/combinedLesson/${item._id}`, { state: { lesson: item } });
    } else if (item.type === "journal") {
      setSelectedJournal(item);
    } else if (item.type === "exercise") {
      try {
        console.log("rjknjfsn:", item.subTestId);
        const response = await axios.get(
          `${baseUrl}/subtest/getExerciseEntries`,
          {
            params: { subTestId: item.subTestId },
            headers: {
              "auth-token": token,
            },
          }
        );
        console.log("response for exercise: ", response.data);
        setSelectedSubtest({
          ...item,
          exerciseEntries: response.data.entries,
        });
      } catch (error) {
        console.error("Error fetching exercise entries:", error);
      }
    }
  };

  const handleClosePopup = () => {
    setSelectedJournal(null);
    setSelectedSubtest(null);
  };

  const renderArchiveItems = (items, type) => {
    let filteredItems = items;
    if (filterStar) {
      if (type === "lessons") {
        filteredItems = items.filter((item) =>
          favorites.lessons.includes(item.lessonId)
        );
      } else if (type === "exercise") {
        filteredItems = items.filter((item) =>
          favorites.exercises.includes(item.subTestId)
        );
      }
    }

    return filteredItems.map((item) => (
      <div
        className="archiveItem"
        key={item._id}
        onClick={() => handleCardClick(item)}
      >
        <div className="oiu">
          <img src={defaultCardImage} alt="" className="archiveImg" />
          <div className="archiveText">
            {item.type === "exercise" && (
              <p className="entryResult">Übungseintrag</p>
            )}
            <h1 className="daily">
              {item.lessonTopic || item.subtestTopic || item.subTestName}
            </h1>

            <p className="date">
              {new Date(item.updatedAt).toLocaleDateString()}
            </p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleStarClick(item);
          }}
        >
          {renderStar(item)}
        </div>
      </div>
    ));
  };

  const renderJournal = (items) => {
    let filteredItems = items;
    if (filterStar) {
      filteredItems = items.filter((item) =>
        favorites.journals.includes(item.journalId)
      );
    }

    return filteredItems.map((item, index) => (
      <div
        className="archiveItem"
        key={item._id}
        onClick={() => handleCardClick(item)}
      >
        <div className="oiu">
          <img src={defaultCardImage} alt="" className="archiveImg" />
          <div className="archiveText">
            <h1 className="daily">
              {t("Daily Journal")} #{item.rank}
            </h1>
            <p className="date">
              {new Date(item.updatedAt).toLocaleDateString()}
            </p>
            <p className="date">{item.note}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleStarClick(item);
          }}
        >
          {renderStar(item)}
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="dashContainer">
        <Header title={t("Archive")} />
        <div className="archive">
          <div className="star1">
            <div></div>
            <div className="starfilter">
              <div
                className="frame button-animate"
                onClick={() => handleFilterStar()}
              >
                {filterStar ? (
                  <FaStar size={24} color="#00BA8C" />
                ) : (
                  <FaRegStar size={24} />
                )}
              </div>
              <button
                className="gvgvh button-animate"
                onClick={() => setFilterClick(!filterClick)}
              >
                <img
                  src={theme === "dark" ? filterWhite : filter}
                  alt="Filter"
                  className="bagr"
                />
                <span className="kuu">{t("Filter")}</span>
              </button>

              {/* FILTER BOX */}
              {filterClick && (
                <div className="filter-box">
                  <div
                    className="filter-item"
                    style={{
                      backgroundColor:
                        selected === 2 ? "#00BA8C" : "transparent",
                      color: selected === 2 ? "#FFF" : "inherit",
                      borderRadius: "7px",
                    }}
                    onClick={() => setSelected(selected === 2 ? 0 : 2)}
                  >
                    {t("Journals")}
                  </div>
                  <div
                    className="filter-item"
                    style={{
                      backgroundColor:
                        selected === 4 ? "#00BA8C" : "transparent",
                      color: selected === 4 ? "#FFF" : "inherit",
                      borderRadius: "7px",
                    }}
                    onClick={() => setSelected(selected === 4 ? 0 : 4)}
                  >
                    {t("Exercise Entries")}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="archiveList">
            {selected === 1 ? (
              renderArchiveItems(lessons, "lessons")
            ) : selected === 2 ? (
              renderJournal(journals)
            ) : selected === 3 ? (
              renderArchiveItems(
                lessons.filter((lesson) => lesson.subTestId),
                "lessons"
              )
            ) : selected === 4 ? (
              renderArchiveItems(exerciseEntries, "exercise")
            ) : selected === 5 ? (
              renderArchiveItems(
                lessons.filter((lesson) => lesson.skillId),
                "lessons"
              )
            ) : loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoadingDots />
              </div>
            ) : (
              <>
                {renderArchiveItems(lessons, "lessons")}
                {renderJournal(journals)}
                {renderArchiveItems(exerciseEntries, "exercise")}
              </>
            )}
          </div>
        </div>
      </div>
      {selectedJournal && (
        <JournalPopup
          isOpen={!!selectedJournal}
          onClose={handleClosePopup}
          journal={selectedJournal}
        />
      )}
      {selectedSubtest && (
        <ExerciseEntryPopup
          isOpen={!!selectedSubtest}
          onClose={handleClosePopup}
          subTestId={selectedSubtest.subTestId}
          subTestName={selectedSubtest.subTestName}
          exerciseEntries={selectedSubtest.exerciseEntries} // Pass exercise entries to the popup
        />
      )}
    </>
  );
};

export default Archive;
