import React from "react";
import "../styles/popup.css"; // Create and style this CSS file
import { useTranslation } from "react-i18next";

const EditFactPopup = ({ fact, onChange, onSave, onCancel }) => {
  const { t } = useTranslation();

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2 className="popupTitle">{t("Edit Fact")}</h2>
        <form
          className="editForm"
          onSubmit={(e) => {
            e.preventDefault();
            onSave();
          }}
        >
          <div className="formGroup">
            <label className="formLabel">{t("Fact")}:</label>
            <input
              type="text"
              className="formInput"
              value={fact}
              onChange={onChange}
            />
          </div>
          <div className="formActions">
            <button className="cancelButton" type="button" onClick={onCancel}>
              {t("Cancel")}
            </button>
            <button className="saveButton" type="submit">
              {t("Save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditFactPopup;
