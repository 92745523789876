import React from "react";
import { MdDelete } from "react-icons/md";

export const SingleSubtestNormal = ({subtest, deleteSubtest, selectedDay, day, index}) => {

  return (
    
        <div
          className="subtestItem"
        >
          <div className="first-list-item" style={{ width: "90%" }}>
            <div className="subtestIcon">
              <img src={subtest.subTestImage} alt="img" />
            </div>
            <span>{subtest.subTestName}</span>
          </div>
          {selectedDay === day && (
            <div className="first-list-item list-icons">
              <MdDelete
                size={"1.5rem"}
                color="#E15D5D"
                cursor={"pointer"}
                onClick={() => deleteSubtest(day, subtest)}
              />
            </div>
          )}
        </div>
  );
};
