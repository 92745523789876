import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../styles/subtestOverview.css"; // Make sure to create and style this CSS file
import ExerciseEntryPopup from "./excersiseEntry"; // Import the new popup component
import bookIcon from "../assets/images/books.png"; // Placeholder icon, replace with actual path
import axios from "axios";
import baseUrl from "../baseUrl"; // Assuming you have a baseUrl file
import { Line } from "react-chartjs-2"; // Import Line from react-chartjs-2
import "chart.js/auto"; // Import chart.js
import { IoMdArrowBack } from "react-icons/io";
import back from "../assets/images/back.svg";
import Cookies from "js-cookie";

const SubtestOverview = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editableContent, setEditableContent] = useState({
    duration: "",
    skills: "",
    points: "",
    logs: "",
    termination: "",
    descriptions: ["", "", ""],
  });
  const [tempContent, setTempContent] = useState({ ...editableContent });
  const [chartData, setChartData] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState([
    false,
    false,
    false,
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const { skillId, skillTopic } = location.state || {};
  const role = localStorage.getItem("Role"); // Get role from localStorage
  const authToken = Cookies.get("authToken");

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/statistics/subtest`, {
          headers: {
            "auth-token": authToken,
          },
        });
        const data = response.data.data;

        // Format data for Chart.js
        const labels = data.map((entry) =>
          new Date(entry.date).toLocaleDateString()
        );
        const dataset = data.map((entry) => 1); // Each entry counts as 1 occurrence

        const chartData = {
          labels: labels,
          datasets: [
            {
              label: "Skill Activity",
              data: dataset,
              fill: false,
              backgroundColor: "rgb(75, 192, 192)",
              borderColor: "rgba(75, 192, 192, 0.2)",
            },
          ],
        };

        setChartData(chartData);
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    const fetchCharacteristics = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/subtest/getCharacteristics/${skillId}`,
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );
        const data = response.data.characteristics;
        if (data) {
          setEditableContent(data);
          setTempContent(data);
        } else {
          setEditableContent({
            duration: "",
            skills: "",
            points: "",
            logs: "",
            termination: "",
            descriptions: ["", "", ""],
          });
          setTempContent({
            duration: "",
            skills: "",
            points: "",
            logs: "",
            termination: "",
            descriptions: ["", "", ""],
          });
        }
      } catch (error) {
        console.error("Error fetching characteristics:", error);
      }
    };

    fetchChartData();
    fetchCharacteristics();
  }, [authToken, skillId]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleEditPopupOpen = () => {
    setTempContent({ ...editableContent });
    setIsEditPopupOpen(true);
  };

  const handleEditPopupClose = () => {
    setIsEditPopupOpen(false);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.patch(
        `${baseUrl}/subtest/characteristics`,
        {
          skillId,
          ...tempContent,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setEditableContent({ ...tempContent });
      setIsEditPopupOpen(false);
      console.log("Characteristics updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating characteristics:", error);
    }
  };

  const handleInputChange = (e, key, index) => {
    if (index !== undefined) {
      const newDescriptions = [...tempContent.descriptions];
      newDescriptions[index] = e.target.value;
      setTempContent((prevContent) => ({
        ...prevContent,
        descriptions: newDescriptions,
      }));
    } else {
      setTempContent((prevContent) => ({
        ...prevContent,
        [key]: e.target.value,
      }));
    }
  };

  const handleContinueLearningClick = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/skills/get-lessons/${skillId}`
      );
      const lessons = response.data.lessons;
      navigate(`/labLesson/${skillId}`, { state: { lessons, skillTopic } });
    } catch (error) {
      console.error("Error fetching lessons:", error);
      // Navigate to LabLesson even if no lessons are fetched
      navigate(`/labLesson/${skillId}`, { state: { lessons: [], skillTopic } });
    }
  };

  const toggleDescription = (index) => {
    setShowFullDescription((prevState) => {
      const newShowFullDescription = [...prevState];
      newShowFullDescription[index] = !newShowFullDescription[index];
      return newShowFullDescription;
    });
  };

  const truncateDescription = (desc, index) => {
    if (showFullDescription[index]) {
      return (
        <>
          {desc}{" "}
          <span className="readMore" onClick={() => toggleDescription(index)}>
            (read less)
          </span>
        </>
      );
    } else {
      return (
        <>
          {desc.slice(0, 50)}...{" "}
          <span className="readMore" onClick={() => toggleDescription(index)}>
            (read more)
          </span>
        </>
      );
    }
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
        <h1 className="courseName">Subtest Overview</h1>
      </div>
      <div className="figmaDesignContent">
        <div className="ppoo">
          <div className="characteristics">
            <div className="editButtonContainer"></div>
            <h2 className="ghjkj">Characteristics</h2>
            {role === "admin" && ( // Show edit button only if the role is admin
              <button className="editButton" onClick={handleEditPopupOpen}>
                Edit
              </button>
            )}
            <div className="mnbi">
              <p className="hello">Duration:</p>
              <span className="hello12">{editableContent.duration}</span>
            </div>
            <div className="mnbi">
              <p className="hello">Skills:</p>
              <span className="hello12">{editableContent.skills}</span>
            </div>
            <div className="mnbi">
              <p className="hello">Points:</p>
              <span className="hello12">{editableContent.points}</span>
            </div>
            <div className="mnbi">
              <p className="hello">Logs:</p>
              <span className="hello12">{editableContent.logs}</span>
            </div>
            <div className="mnbi">
              <p className="hello">Termination:</p>
              <span className="hello12">{editableContent.termination}</span>
            </div>
            {editableContent.descriptions.map((desc, index) => (
              <p className="hello1" key={index}>
                {truncateDescription(desc, index)}
              </p>
            ))}
          </div>
          <div className="factscard">
            <div className="facts">
              <h2>Facts about unit tests</h2>
              <p>Make this carousel during development</p>
            </div>
            <div className="completion">
              <h2>Complete</h2>
              <p>&gt;15/24</p>
            </div>
          </div>
          <div className="newExerciseEntry" onClick={handlePopupOpen}>
            <img src={bookIcon} alt="Books" />
            <h3 className="newexc">New Exercise Entry</h3>
          </div>
        </div>
        <div className="poiuii">
          {chartData ? (
            <div className="chagrsrt">
              <Line data={chartData} />
            </div>
          ) : (
            <p>Loading chart...</p>
          )}
          <div className="iuyht">
            <div
              className="newExerciseEntry1 "
              onClick={handleContinueLearningClick}
            >
              <img src={bookIcon} alt="Books" />
              <h3 className="cioiuu">Course</h3>
              <p className="mkjhg">Continue learning</p>
            </div>
            <div
              className="newExerciseEntry2"
              onClick={() => navigate("/associationbox")}
            >
              <img src={bookIcon} alt="Books" />
              <h3 className="cioiuu">SectionNa</h3>
              <p className="mkjhg">Description</p>
            </div>
          </div>
        </div>
      </div>
      <ExerciseEntryPopup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        subTestId={skillId}
        subTestName={skillTopic}
      />

      {isEditPopupOpen && (
        <div className="popup">
          <div className="popup-inner">
            <h2 className="popupTitle">Edit Characteristics</h2>
            <form
              className="editForm"
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveEdit();
              }}
            >
              <div className="formGroup">
                <label className="formLabel">Duration:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.duration}
                  onChange={(e) => handleInputChange(e, "duration")}
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">Skills:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.skills}
                  onChange={(e) => handleInputChange(e, "skills")}
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">Points:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.points}
                  onChange={(e) => handleInputChange(e, "points")}
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">Logs:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.logs}
                  onChange={(e) => handleInputChange(e, "logs")}
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">Termination:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.termination}
                  onChange={(e) => handleInputChange(e, "termination")}
                />
              </div>
              {tempContent.descriptions.map((desc, index) => (
                <div className="formGroup" key={index}>
                  <label className="formLabel">Description {index + 1}:</label>
                  <input
                    type="text"
                    className="formInput"
                    value={desc}
                    onChange={(e) =>
                      handleInputChange(e, "descriptions", index)
                    }
                  />
                </div>
              ))}
              <div className="formActions">
                <button className="savgeButton" type="submit">
                  Save
                </button>
                <button
                  className="cancejlButton"
                  type="button"
                  onClick={handleEditPopupClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubtestOverview;
