import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./header";
import Card from "./cards";
import "../styles/subtests.css";
import { useNavigate } from "react-router-dom";
import baseUrl from "../baseUrl";
import { FaPen, FaTrash } from "react-icons/fa";
import Popup from "../components/subtestPopup";
import CreateSubTestPopup from "../components/CreateSubTestPopup";
import error from "../assets/images/info.svg";
import Loader from "./loader"; // Import the Loader component
import { TailSpin } from "react-loader-spinner";
import ConfirmationDialog from "./ConfirmationDialog";
import { toast } from "sonner";
import { useTranslation } from "react-i18next"; // Import useTranslation
import Cookies from "js-cookie";

const SubTests = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
  const [isEditInfoPopupOpen, setIsEditInfoPopupOpen] = useState(false);
  const [generalInfo, setGeneralInfo] = useState("");
  const [newGeneralInfo, setNewGeneralInfo] = useState(generalInfo);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [subTests, setSubTests] = useState([]);
  const [currentSubTest, setCurrentSubTest] = useState(null);
  const [subTestToDelete, setSubTestToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [subTestsPerPage] = useState(8);
  const [loading, setLoading] = useState(true); // Add loading state
  const [loadings, setLoadings] = useState(false); // Add loading state
  const navigate = useNavigate();
  const role = localStorage.getItem("Role"); // Get role from localStorage

  useEffect(() => {
    fetchSaveGeneralInfo();
  }, []);

  const togglePopup = (subTest) => {
    setCurrentSubTest(subTest);
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleCreatePopup = () => {
    setIsCreatePopupOpen(!isCreatePopupOpen);
  };

  const toggleEditInfoPopup = () => {
    setNewGeneralInfo(generalInfo);
    setIsEditInfoPopupOpen(!isEditInfoPopupOpen);
  };

  const fetchSubTests = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await axios.get(`${baseUrl}/subtest/get-all-subtest`);
      console.log("subTests: ", response.data.subTest);
      setSubTests(response.data.subTest);
    } catch (error) {
      console.error("Error fetching subtests:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchSubTests();
  }, []);

  const handleCardClick = (subTestId, subTestName, index) => {
    navigate(`/subtestOverviewLearning`, {
      state: { subTestId, subTestName, index: index + 1 },
    });
  };

  const handleSave = (id, title, description, subTestImage) => {
    setSubTests(
      subTests.map((subTest) =>
        subTest._id === id
          ? {
              ...subTest,
              subTestName: title,
              subTestDescription: description,
              subTestImage,
            }
          : subTest
      )
    );
    togglePopup(null);
  };

  const handleDelete = async (subTestId) => {
    const authToken = Cookies.get("authToken");
    try {
      await axios.delete(`${baseUrl}/subtest/delete-subtest/${subTestId}`, {
        headers: {
          "auth-token": authToken,
        },
      });
      fetchSubTests(); // Re-fetch the subtests to update the list

      // Adjust current page if necessary
      const remainingItems = subTests.length - 1;
      const totalPages = Math.ceil(remainingItems / subTestsPerPage);
      if (currentPage > totalPages) {
        setCurrentPage(currentPage - 1);
      }

      toast.success(t("Subtest deleted successfully!")); // Show success toast
    } catch (error) {
      console.error("Error deleting subtest:", error);
      toast.error(t("Error deleting subtest. Please try again.")); // Show error toast
    }
  };

  const confirmDelete = (subTestId, e) => {
    e.stopPropagation(); // Prevent the default behavior
    setSubTestToDelete(subTestId);
    setIsConfirmationDialogOpen(true);
  };

  const handleCreate = (newSubTest) => {
    setSubTests([...subTests, newSubTest]);
    toggleCreatePopup();
  };

  const handleSaveGeneralInfo = async () => {
    try {
      setLoadings(true); // Set loading to true when saving starts
      const response = await axios.patch(`${baseUrl}/subtest/general-info`, {
        generalInfo: newGeneralInfo,
      });
      console.log("response: ", response.data);
      setGeneralInfo(response.data.generalInfo);
      toggleEditInfoPopup();
    } catch (error) {
      console.error("Error updating general information:", error);
    } finally {
      setLoadings(false); // Set loading to false when saving is complete
    }
  };

  const fetchSaveGeneralInfo = async () => {
    try {
      const response = await axios.get(`${baseUrl}/subtest/fetch-general-info`);
      console.log("respbvonse: ", response.data);
      setGeneralInfo(response.data.generalInfo);
    } catch (error) {
      console.error("Error fetching general information:", error);
    }
  };

  const handleConfirm = () => {
    if (subTestToDelete) {
      handleDelete(subTestToDelete);
    }
    setIsConfirmationDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  // Pagination logic
  const indexOfLastSubTest = currentPage * subTestsPerPage;
  const indexOfFirstSubTest = indexOfLastSubTest - subTestsPerPage;
  const currentSubTests = subTests.slice(
    indexOfFirstSubTest,
    indexOfLastSubTest
  );

  const totalPages = Math.ceil(subTests.length / subTestsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="dashContainer">
      <Header title={t("Subtests")} />

      {loading ? (
        <div className="loader-container">
          <TailSpin
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="testcont">
          {/* <div className="generalInfo">
            <img src={error} alt="" className="error" />
            <div className="info">
              <div className="generalInfoHeader">
                <p className="general">{t("General Information")}</p>
                {role === "admin" && ( // Show edit icon only if the role is admin
                  <FaPen className="editIcgon" onClick={toggleEditInfoPopup} />
                )}
              </div>
              <p className="desc">{generalInfo}</p>
            </div>
          </div> */}

          {role === "admin" && ( // Show add subtest button only if the role is admin
            <div className="uuiiii">
              <button className="addSkillButton" onClick={toggleCreatePopup}>
                {t("Add SubTest")}
              </button>
            </div>
          )}

          <div className="labCards">
            {currentSubTests.map((subTest, index) => (
              <div
                onClick={() =>
                  handleCardClick(subTest.subTestId, subTest.subTestName, index)
                }
                key={index}
              >
                {role === "admin" && ( // Show edit and delete icons only if the role is admin
                  <>
                    <FaPen
                      className="editIcon"
                      onClick={(e) => {
                        e.stopPropagation();
                        togglePopup(subTest);
                      }}
                    />{" "}
                    <FaTrash
                      style={{
                        color: "#f87171",
                        cursor: "pointer",
                        hover: "#dc2626",
                        marginLeft: "2px",
                      }}
                      onClick={(e) => confirmDelete(subTest.subTestId, e)}
                    />{" "}
                  </>
                )}
                <Card
                  title={subTest.subTestName}
                  description={subTest.subTestDescription}
                  image={subTest.subTestImage}
                />
              </div>
            ))}

            {isPopupOpen && currentSubTest && (
              <Popup
                id={currentSubTest._id}
                title={currentSubTest.subTestName}
                description={currentSubTest.subTestDescription}
                onClose={() => togglePopup(null)}
                onSave={handleSave}
              />
            )}
            {isCreatePopupOpen && (
              <CreateSubTestPopup
                onClose={toggleCreatePopup}
                onCreate={handleCreate}
              />
            )}
          </div>
          {totalPages > 1 && (
            <div className="pagination">
              <button
                className="pagination-button"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                {t("Previous")}
              </button>
              <span className="pagination-info">
                {t("Page")} {currentPage} {t("of")} {totalPages}
              </span>
              <button
                className="pagination-button"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                {t("Next")}
              </button>
            </div>
          )}
        </div>
      )}
      {isEditInfoPopupOpen && (
        <div className="popup">
          {loading && <Loader />}
          <div className={`popup-inner ${loadings ? "blur-background" : ""}`}>
            <h2>{t("Edit General Information")}</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveGeneralInfo();
              }}
            >
              <label className="labhjj">
                {t("General Information")}:
                <textarea
                  value={newGeneralInfo}
                  onChange={(e) => setNewGeneralInfo(e.target.value)}
                  className="oiuyuu"
                />
              </label>
              <button className="bito" type="submit">
                {t("Save")}
              </button>
              <button
                className="bito"
                type="button"
                onClick={toggleEditInfoPopup}
              >
                {t("Cancel")}
              </button>
            </form>
          </div>
        </div>
      )}
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          message={t("Are you sure you want to delete this subtest?")}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default SubTests;
