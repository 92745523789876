import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../styles/header.css";
import icon7 from "../assets/images/icon7.svg";
import moon_dark from "../assets/images/moon_dark.svg";
import communityWhite from "../assets/images/community-w.svg";
import communityDark from "../assets/images/community-b.svg";
import questionWhite from "../assets/images/questionmark-w.svg";
import questionDark from "../assets/images/questionmark-b.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../ThemeContext";

const Header = ({ title }) => {
  const { t } = useTranslation();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [currentIcon, setCurrentIcon] = useState(icon7);
  const [currentCommunity, setCurrentCommunity] = useState(communityDark);
  const [currentQuestion, setCurrentQuestion] = useState(questionDark);

  useEffect(() => {
    if (theme === "dark") {
      setCurrentIcon(moon_dark);
      setCurrentCommunity(communityWhite);
      setCurrentQuestion(questionWhite);
    } else {
      setCurrentIcon(icon7);
      setCurrentCommunity(communityDark);
      setCurrentQuestion(questionDark);
    }
  }, [theme]);

  return (
    <div className="header">
      <h1 className="header-title">{title}</h1>
      <div className="someitmes">
        <img
          src={currentIcon}
          alt=""
          className="iconing1"
          onClick={toggleTheme}
        />
        <Link to="/raiseTicket">
          <img src={currentQuestion} alt="" className="iconing" />
        </Link>
        <div className="skoolcommunity button-animate">
          <img src={currentCommunity} alt="" className="group" />
          <h1 className="skool">{t("Skool-Community")}</h1>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
