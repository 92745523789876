import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { setUser, setToken } from "../redux/actions/userActions";
import "../styles/loginPage.css";
import medgang_logo from "../assets/images/TMSLogo.png";
import mascot from "../assets/images/mascot.png";
// import mascotText from "../assets/images/belowMascot.svg";
import baseUrl from "../baseUrl";
import Loader from "../components/loader"; // Import the Loader component
import { toast } from "sonner";
import { useTranslation } from "react-i18next"; // Import useTranslation

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialAnimationComplete, setInitialAnimationComplete] =
    useState(false);
  const [isButtonPressed, setIsButtonPressed] = useState(false); // New state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Initialize translation function

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialAnimationComplete(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setIsButtonPressed(true); // Set the button pressed state
    try {
      const response = await axios.post(`${baseUrl}/auth/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        const data = response.data;
        const expiryTime = 8 * 60 * 60; // 8 hrs expiry
        localStorage.setItem("Username", data.name);
        localStorage.setItem("login", keepLoggedIn);
        if (keepLoggedIn) {
          Cookies.set("authToken", data.token);
          Cookies.set("userId", data.payload.user.userId);
        } else {
          Cookies.set("authToken", data.token, {
            expires: expiryTime / (24 * 60 * 60),
          });
          Cookies.set("userId", data.payload.user.userId, {
            expires: expiryTime / (24 * 60 * 60),
          });
        }
        dispatch(setUser({ name: data.name, email }));
        dispatch(setToken(data.token));
        localStorage.setItem("Role", data.payload.user.role);
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        console.error("Login failed:", error.response?.data?.message || error);
        toast.error(t("User not logged in")); // Localized error message
      }
    } finally {
      setLoading(false);
      setIsButtonPressed(false); // Reset the button pressed state
    }
  };

  return (
    <div className="login-page">
      {loading && <Loader />}
      <div className={`loginContainer ${loading ? "blur-background" : ""}`}>
        {!initialAnimationComplete ? (
          <div className="logo-animation">
            <img src={mascot} alt={t("Medgang Logo")} className="medgang" />
          </div>
        ) : (
          <>
            <div className={`logos ${initialAnimationComplete ? "top" : ""}`}>
              <img
                src={medgang_logo}
                alt={t("Medgang Logo")}
                className="medgang"
              />
            </div>

            <div
              className={`loginsys ${initialAnimationComplete ? "show" : ""}`}
            >
              <form
                className={`login_form ${
                  initialAnimationComplete ? "show" : ""
                }`}
                onSubmit={handleLogin}
              >
                <h1 className="welcome_back">Willkommen zurück!</h1>

                <label htmlFor="email" className="lkjh">
                  E-Mail:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="ooii"
                />

                <label htmlFor="password" className="lkjh">
                  Passwort:
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="ooii"
                />

                <div className="check-login">
                  <input
                    type="checkbox"
                    className="checkbox-style"
                    checked={keepLoggedIn}
                    onChange={(e) => setKeepLoggedIn(e.target.checked)}
                  />
                  <label htmlFor="checkbox">Eingeloggt bleiben</label>
                </div>

                <div className="submitbutton">
                  <button
                    className={`submit ${isButtonPressed ? "pressed" : ""}`}
                    type="submit"
                  >
                    Anmelden
                  </button>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
