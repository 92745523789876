import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "../styles/raiseTicketForm.css";
import Header from "./header";
import medgang from "../assets/images/medgang.png";
import insta from "../assets/images/insta.png";
import server from "../assets/images/server.png";
import baseUrl from "../baseUrl";
import { toast } from "sonner";
import clip from "../assets/images/paperclip.svg";
import Cookies from "js-cookie";

const RaiseTicketForm = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [fileName, setFileName] = useState(t("Choose Attachment"));
  const [loading, setLoading] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = Cookies.get("authToken");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !category || !message) {
      toast.error(t("All fields are required!"));
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("category", category);
    formData.append("message", message);
    if (attachment) {
      formData.append("image", attachment);
    }

    try {
      const response = await axios.post(
        `${baseUrl}/support/post-ticket-info`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": token,
          },
        }
      );
      console.log(response.data);
      setModalMessage(t("Ticket submitted successfully!"));
      setIsModalOpen(true);
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setModalMessage(t("There was an error submitting the form."));
      setIsModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAttachment(file);
      setFileName(file.name);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="dashContainer">
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="successjj">{t("Success!")}</h2>
            <p className="kjhjj">{modalMessage}</p>
            <button className="yuioi" onClick={closeModal}>
              {t("Exit")}
            </button>
          </div>
        </div>
      )}
      <Header title={t("Help and Support")} />
      <div className="poiiu">
        <div className="raiseTicketContainer">
          <div className="formSection">
            <h1 className="formTitle">{t("Raise a Ticket")}</h1>
            <form className="ticketForm" onSubmit={handleSubmit}>
              <div className="formGroup">
                <label htmlFor="email" className="ticete">
                  {t("Your E-Mail")}
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder={t("email@medgang.com")}
                  className="herber"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="formGroup">
                <label htmlFor="category" className="ticete">
                  {t("Select Category")}
                </label>
                <select
                  id="category"
                  name="category"
                  className="select herer block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="" disabled selected>
                    {t("Please Select a Category")}
                  </option>
                  <option value="everything">{t("Around TMS")}</option>
                  <option value="billing">{t("Technical")}</option>
                  <option value="criticism">{t("Criticism")}</option>
                  <option value="general">{t("Others")}</option>
                </select>
              </div>
              <div className="formGroup">
                <label htmlFor="issue" className="ticete">
                  {t("Explain your issue in brief")}
                </label>
                <textarea
                  id="issue"
                  name="issue"
                  placeholder={t("hbhjbgh")}
                  className="hehrer"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="formGroup">
                <label className="fileInputLabel">
                  <img src={clip} alt="Clip icon" className="clipIcon" />
                  {fileName} {/* Display the filename here */}
                  <input
                    type="file"
                    id="attachment"
                    name="attachment"
                    className="fileInput"
                    accept=".png,.jpeg,.jpg"
                    onChange={handleFileChange}
                  />
                </label>
              </div>
              <button type="submit" className="submithButton">
                {t("Submit")}
              </button>
            </form>
          </div>
          <div className="contactSection">
            <div className="logo">
              <img src={medgang} alt="MedGang Logo" className="lkjhghj" />
            </div>
            <h2 className="contactTitle">{t("Find us at")}</h2>
            <p>
              Lass Khoshnaw
              <br />
              Tapachstraße 71A
              <br />
              70437 Stuttgart
            </p>
            <h3 className="phone">{t("Phone number:")}</h3>
            <p>+49 152 27698180</p>
            <h3 className="phone">{t("E-Mail:")}</h3>
            <p>support@medgang.de</p>
            <h2 className="contactTitle">Connect</h2>
            <div className="socialIcons">
              <img src={server} alt={t("Website")} />
              <img src={insta} alt="Instagram" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RaiseTicketForm;
