import React, { useState } from "react";
import "../styles/RequestModal.css";
import baseUrl from "../baseUrl.js";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Cookies from "js-cookie";
import { HiOutlineUpload } from "react-icons/hi";
import ImageCropper from "./ImageCropper.jsx";

export const RequestModal = ({
  onClose,
  setRequests,
  requests,
  setSuccessScreen,
}) => {
  const { t } = useTranslation();
  const [fact, setFact] = useState("");
  const token = Cookies.get("authToken");
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const validTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (file && !validTypes.includes(file.type)) {
      alert("Please upload a PNG, JPEG, or JPG file.");
      event.target.value = null; // Clear the input
    } else if (file) {
      setSelectedFile(file);
    }
  };

  const handleCropComplete = (croppedBlob) => {
    const url = URL.createObjectURL(croppedBlob);
    setCroppedImage(url);
    setSelectedFile(null);
  };

  const sendRequest = async (event) => {
    event.preventDefault();

    setLoading(true);
    const formData = new FormData();
    formData.append("facts", fact);

    try {
      if (croppedImage) {
        const tempResponse = await fetch(croppedImage);
        if (!tempResponse.ok) {
          throw new Error("Failed to fetch cropped image");
        }
        const blob = await tempResponse.blob();
        formData.append("image", blob);
      }
      const response = await axios.post(
        `${baseUrl}/association/post-request`,
        formData,
        {
          headers: {
            "auth-token": token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response && response.data) {
        console.log(response.data);
        setRequests([response.data.request, ...requests]);
      }
      setSuccessScreen(true);
      onClose();
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="popup">
      <div className="edit-popup-inner">
        <div className="edit-header" style={{ borderBottom: "1px solid gray" }}>
          <h3 style={{ fontSize: "1rem" }}>{t("New Request")}</h3>
          <IoIosCloseCircleOutline
            className="close-btn button-animate"
            cursor={"pointer"}
            size={"2rem"}
            onClick={() => onClose()}
            title={t("Close")}
          />
        </div>

        <div className="second-field">
          <span className="input-container">
            <h5 className="temp-label">{t("Figures")}</h5>
          </span>

          <div className={`image-uploader ${fact.length !== 0 && "disabled"}`}>
            <h4 className="temp-heading2">{t("Upload photo")}</h4>
            <h6 className="temp-heading2">{t("Max file size: 2mb")}</h6>

            <label className="custom-file-upload">
              <input
                className="file-input"
                type="file"
                accept=".png,.jpeg,.jpg"
                onChange={handleFileChange}
                disabled={fact.length !== 0}
              />

              <div className={`file-input-field `}>
                {croppedImage ? (
                  t("File Uploaded")
                ) : (
                  <div className="lib-upload-container">
                    <HiOutlineUpload size={"2.4rem"} color="#cccccc" />

                    <p
                      style={{
                        wordSpacing: "1px",
                        fontSize: "0.75rem",
                        maxWidth: "200px",
                      }}
                    >
                      {t("Click here or drag and drop your file")}
                    </p>
                  </div>
                )}
              </div>
            </label>
          </div>
          <div className="horizontal-line"></div>

          <span className="input-container">
            <h5 className="temp-label">{t("Facts")}</h5>
          </span>
          <input
            className={`input-field ${!!croppedImage && "disabled"}`}
            type="text"
            value={fact}
            onChange={(e) => setFact(e.target.value)}
            disabled={!!croppedImage}
          />
        </div>

        <div className="btn-container-edit">
          <button
            title={t("Click to Publish")}
            className={`btn-submit button-animate ${
              !croppedImage && fact.length === 0 && "disabled"
            }`}
            onClick={sendRequest}
            disabled={!croppedImage && fact.length === 0}
          >
            {loading ? <span className="loader-video"></span> : t("Publish")}
          </button>
        </div>
      </div>
      {selectedFile && (
        <ImageCropper
          selectedFile={selectedFile}
          onClose={() => setSelectedFile(null)}
          onCropComplete={handleCropComplete}
        />
      )}
    </div>
  );
};
