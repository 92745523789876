import React from "react";
import "../styles/LibraryView.css";
import { MdOutlineVerified } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { IoIosCloseCircleOutline } from "react-icons/io";
import verified from "../assets/icon/verified.svg"

export const LibraryView = ({ onClose, selectedLibrary }) => {
  const { t } = useTranslation();

  function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}.${month}.${year}`;
  }

  return (
    <div className="popup">
      <div className="library-view">
        <div className="edit-header library" style={{borderBottom:"1px solid gray"}}>
        <IoIosCloseCircleOutline
            cursor={"pointer"}
            size={"2rem"}
            onClick={() => onClose()}
            className='close-btn'
          />
        </div>

        <div className="library-main-content">
          <h3 className="temp-heading">{selectedLibrary.title}</h3>
          <p>{selectedLibrary.description}</p>

          <hr style={{color:"lightgray"}}/>

          <p className="lib-date">
            {t("Uploaded on")}: {formatDate(selectedLibrary.createdAt)}{" "}
          </p>
          {selectedLibrary.verified && (
            <span className="verification">
              <img src={verified} alt="verified icon" style={{ width: "1rem" }}/> 
              {t("Verified by Medgang")}
            </span>
          )}
        </div>
        <div className="btn-container-edit in-lib-view">
          <a
            className="btn-submit temp-a"
            href={selectedLibrary?.pdfUrl}
            rel="noreferrer"
            target="_blank"
            download="document.pdf"
          >
            {t("Download")}
          </a>
        </div>
      </div>
    </div>
  );
};
