// actions/userActions.js

export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_MODE = "SET_MODE"

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});

export const setMode = (mode) => ({
    type: SET_MODE,
    payload: mode,
})
