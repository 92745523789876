import React from "react";
import "../styles/ToolTip.css";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";

export const ToolTip = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="tooltip-container">
      <div className="tooltip-header">
        <IoIosCloseCircleOutline
          className="close-icon-tooltip button-animate"
          cursor={"pointer"}
          size={"2rem"}
          onClick={() => onClose()}
        />
      </div>
      <div className="tooltip-main">
        <div className="tooltip-description">
          <h2 style={{ marginBottom: "1vh" }}>{t("How this works")}</h2>
          <p>
            {t(
              "This is where you can explain in detail how this works and why should he/she use it to."
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
