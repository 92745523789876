import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Slider from "react-slick";
import CountUp from "react-countup";
import "../styles/subtestOverview.css";
import ExerciseEntryPopup from "./excersiseEntry";
import EditFactPopup from "./editFactPopup";
import bookIcon from "../assets/images/books.png";
import axios from "axios";
import baseUrl from "../baseUrl";
import Loader from "./loader";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import icon1 from "../assets/icon/1.png";
import icon12 from "../assets/icon/12.png";
import icon29 from "../assets/icon/29.png";
import icon32 from "../assets/icon/32.png";
import bar from "../assets/images/bar.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "sonner";
import { IoMdArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";
import back from "../assets/images/back.svg";
import Cookies from "js-cookie";
import Subtest1_Excersise from "../assets/images/sub1_exc.svg";
import Subtest1_Continue from "../assets/images/sub1_cont.svg";
import Subtest1_secNa from "../assets/images/sub1_secN.svg";
import Subtest2_Continue from "../assets/images/sub2_cont.svg";
import Subtest2_secNa from "../assets/images/sub2_secNa.svg";
import Subtest3_Continue from "../assets/images/sub3_cont.svg";
import Subtest4_Continue from "../assets/images/sub4_cont.svg";
import Subtest4_secNa from "../assets/images/sub4_secNa.svg";
import Subtest5_Continue from "../assets/images/sub5_cont.svg";
import Subtest5_secNa from "../assets/images/sub5_secNa.svg";
import Subtest6_Continue from "../assets/images/sub6_cont.svg";
import Subtest6_secNa from "../assets/images/sub6_secNa.svg";
import Subtest7_Continue from "../assets/images/sub7_cont.svg";
import Subtest7_secNa from "../assets/images/sub7_secNa.svg";
import Subtest8_Continue from "../assets/images/sub8_cont.svg";
import { ThemeContext } from "../ThemeContext";

const SubtestOverviewLearning = () => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [editableContent, setEditableContent] = useState(null);
  const [tempContent, setTempContent] = useState(null);
  const [totalSubtests, setTotalSubtests] = useState(0);
  const [archivedSubtests, setArchivedSubtests] = useState(0);
  const [currentFact, setCurrentFact] = useState(0);
  const [countEntry, setCountEntry] = useState(0);
  const totalSubtest = 24; // Static value
  const [exerciseEntriesCount, setExerciseEntriesCount] = useState(0);
  const [absolved, setAbsolved] = useState(0);
  const [maximum, setMaximum] = useState(0);
  const [minimum, setMinimum] = useState(0);
  const [isChart, setIsChart] = useState(true);
  const [fixedSkillId1, setFixedSkillId1] = useState(null);
  const [fixedSkillId4, setFixedSkillId4] = useState(null);
  const [fixedSkillTopic1, setFixedSkillTopic1] = useState("Kreuzblick101");
  const [fixedSkillTopic4, setFixedSkillTopic4] = useState("Quanti101");
  const {theme} = useContext(ThemeContext);

  const [facts, setFacts] = useState([
    "Fact 1 about unit tests",
    "Fact 2 about unit tests",
    "Fact 3 about unit tests",
  ]);
  const [editFact, setEditFact] = useState({ index: null, text: "" });
  const navigate = useNavigate();
  const location = useLocation();
  const { subTestId, subTestName, index } = location.state || {};
  const role = localStorage.getItem("Role"); // Get role from localStorage
  const authToken = Cookies.get("authToken");
  const [loading, setLoading] = useState(false); // Add loading state

  const subTestIcon = {
    "Subtest 1": icon29,
    "Subtest 2": icon32,
    "Subtest 4": icon1,
    "Subtest 5": icon12,
    "Subtest 6": icon12,
    "Subtest 7": icon32,
  };

  const subTestIcons = {
    1: {
      exc: Subtest1_Excersise,
      cont: Subtest1_Continue,
      secNa: Subtest1_secNa,
      label1: "Kreuzblick101",
      label2: "Der Cheatcode für MZ",
    },
    2: {
      exc: Subtest1_Excersise,
      cont: Subtest2_Continue,
      secNa: Subtest2_secNa,
      label1: "GangBib",
      label2: "Einblicke in die Medizin",
    },
    3: {
      exc: Subtest1_Excersise,
      cont: Subtest3_Continue,
    },
    4: {
      exc: Subtest1_Excersise,
      cont: Subtest4_Continue,
      secNa: Subtest4_secNa,
      label1: "Quanti101",
      label2: "Ein guter Freund",
    },
    5: {
      exc: Subtest1_Excersise,
      cont: Subtest5_Continue,
      secNa: Subtest5_secNa,
      label1: "Assoziationskiste",
      label2: "Wende dich an die Gang",
    },
    6: {
      exc: Subtest1_Excersise,
      cont: Subtest6_Continue,
      secNa: Subtest6_secNa,
      label1: "Assoziationskiste",
      label2: "Wende dich an die Gang",
    },
    7: {
      exc: Subtest1_Excersise,
      cont: Subtest7_Continue,
      secNa: Subtest7_secNa,
      label1: "GangBib",
      label2: "Einblicke in die Medizin",
    },
    8: {
      exc: Subtest1_Excersise,
      cont: Subtest8_Continue,
    },
  };
  const currentIcons = subTestIcons[index] || {};

  const fetchChartData = async () => {
    try {
      const authToken = Cookies.get("authToken");
      const response = await axios.get(
        `${baseUrl}/statistics/subtest/${subTestId}`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      const data = response.data.data;

      // Group scores by their counts
      const groupedData = data.reduce((acc, item) => {
        if (!acc[item.count]) {
          acc[item.count] = [];
        }
        acc[item.count].push(parseFloat(item.score));
        return acc;
      }, {});

      // Prepare dataset for the chart
      const dataset = Object.keys(groupedData).map((count) => ({
        x: parseInt(count),
        y: groupedData[count],
      }));

      const labels = Object.keys(groupedData).map((count) => parseInt(count));
      const scores = [].concat(...Object.values(groupedData));
      let maxScore = Math.max(...scores);
      let minScore = Math.min(...scores);
      const totalExercises = scores.length;

      if (totalExercises === 0) {
        maxScore = 0;
        minScore = 0;
      }

      setAbsolved(totalExercises);
      setExerciseEntriesCount(totalExercises);
      setMaximum(maxScore);
      setMinimum(minScore);

      // Configure the chart data
      const chartData = {
        labels: data.map((_, index) => index + 1),
        datasets: [
          {
            label: "Score",
            data: data.map((item) => parseFloat(item.score)),
            fill: false,
            borderColor: "rgba(0, 186, 140)",
            borderWidth: 2,
            tension: 0.5,
          },
        ],
      };

      setChartData(chartData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching chart data:", error);
      toast.error("Failed to fetch data");
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchCharacteristics = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/subtest/getCharacteristics/${subTestId}`,
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );
        const data = response.data.characteristics;
        if (data) {
          setEditableContent(data);
          setTempContent(data);
        } else {
          setEditableContent(null);
          setTempContent(null);
        }
      } catch (error) {
        console.error("Error fetching characteristics:", error);
      }
    };

    const fetchSubtestData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/subtest/get-all-subtest`, {
          headers: {
            "auth-token": authToken,
          },
        });
        const allSubtests = response.data.subTest;
        console.log("allSubtests:", allSubtests);
        setTotalSubtests(allSubtests.length);

        const archivedResponse = await axios.get(
          `${baseUrl}/archive/get-all-archive`,
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );
        const archivedLessons = archivedResponse.data.archive;
        console.log("archivedLessons:", archivedLessons);

        // Check how many archived lessons contain the subTestId
        const matchingArchivedLessons = archivedLessons.filter(
          (archived) => archived.subTestId
        );
        console.log(
          "Matching archived lessons count:",
          matchingArchivedLessons
        );

        setArchivedSubtests(matchingArchivedLessons.length);
      } catch (error) {
        console.error("Error fetching subtest data:", error);
      }
    };

    const fetchFacts = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/subtest/getFacts/${subTestId}`,
          {
            headers: {
              "auth-token": authToken,
            },
          }
        );
        setFacts(response.data.facts);
      } catch (error) {
        console.error("Error fetching facts:", error);
      }
    };

    const fetchOrCreateFixedSkill1 = async () => {
      try {
        const response = await axios.get(`${baseUrl}/skills/get-skills`);
        const existingFixedSkill1 = response.data.skills.find(
          (skill) => skill.skillTopic === "Kreuzblick101"
        );

        if (existingFixedSkill1) {
          setFixedSkillId1(existingFixedSkill1.skillId);
        } else {
          const createResponse = await axios.post(
            `${baseUrl}/skills/post-skill`,
            {
              skillTopic: "Kreuzblick101",
              skillDescription: "This is a subtest 1 course.",
            },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          );
          setFixedSkillId1(createResponse.data.skill.skillId);
        }
      } catch (error) {
        console.error("Error fetching or creating fixed skill 1:", error);
      }
    };

    const fetchOrCreateFixedSkill4 = async () => {
      try {
        const response = await axios.get(`${baseUrl}/skills/get-skills`);
        const existingFixedSkill4 = response.data.skills.find(
          (skill) => skill.skillTopic === "Quanti101"
        );

        if (existingFixedSkill4) {
          setFixedSkillId4(existingFixedSkill4.skillId);
        } else {
          const createResponse = await axios.post(
            `${baseUrl}/skills/post-skill`,
            {
              skillTopic: "Quanti101",
              skillDescription: "This is a subtest 4 course.",
            },
            {
              headers: {
                "auth-token": authToken,
              },
            }
          );
          setFixedSkillId4(createResponse.data.skill.skillId);
        }
      } catch (error) {
        console.error("Error fetching or creating fixed skill 4:", error);
      }
    };
    getExerciseEntriesCount();
    fetchFacts();
    fetchChartData();
    fetchCharacteristics();
    fetchSubtestData();
    fetchOrCreateFixedSkill1();
    fetchOrCreateFixedSkill4();
  }, [authToken, subTestId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFact((prevFact) => (prevFact + 1) % facts.length);
    }, 10000); // Change fact every 10 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [facts.length]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleEditPopupOpen = () => {
    setTempContent({ ...editableContent });
    setIsEditPopupOpen(true);
  };

  const handleEditPopupClose = () => {
    setIsEditPopupOpen(false);
  };

  const getExerciseEntriesCount = async () => {
    console.log("subTestId: ", subTestId);
    try {
      const response = await axios.get(
        `${baseUrl}/subtest/getExerciseEntries/${subTestId}`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      // console.log("response: ", response.data.entries.length);
      // setExerciseEntriesCount(response.data.entries.length);
    } catch (error) {
      console.error("Error fetching exercise entries count:", error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      setLoading(true); // Set loading to true when saving starts
      const response = await axios.patch(
        `${baseUrl}/subtest/characteristics`,
        {
          subTestId,
          ...tempContent,
        },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setEditableContent({ ...tempContent });
      setIsEditPopupOpen(false);
      console.log("Characteristics updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating characteristics:", error);
    } finally {
      setLoading(false); // Set loading to false when saving is complete
    }
  };

  const handleInputChange = (e, key) => {
    setTempContent((prevContent) => ({
      ...prevContent,
      [key]: e.target.value,
    }));
  };

  const handleContinueLearningClick = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/subtest/post-subtest/${subTestId}`
      );
      const lessons = response.data.lessons;
      navigate(`/subtestLabLesson/${subTestId}`, {
        state: { lessons, subTestName },
      });
    } catch (error) {
      console.error("Error fetching lessons:", error);
      // Navigate to SubtestLabLesson even if no lessons are fetched
      navigate(`/subsetLabLesson/${subTestId}`, {
        state: { lessons: [], subTestName },
      });
    }
  };

  const handleSectionClick = async () => {
    try {
      if (index === 5 || index === 6) {
        navigate("/associationbox");
      } else if (index === 2 || index === 7) {
        navigate("/medicallibrary");
      } else if (index === 1) {
        navigate(`/labLesson/${fixedSkillId1}`, {
          state: { lessons: [], skillTopic: fixedSkillTopic1 },
        });
      } else if (index === 4) {
        navigate(`/labLesson/${fixedSkillId4}`, {
          state: { lessons: [], skillTopic: fixedSkillTopic4 },
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleEditFactClick = (index) => {
    setEditFact({ index, text: facts[index] });
  };

  const handleEditFactChange = (e) => {
    setEditFact((prevEditFact) => ({ ...prevEditFact, text: e.target.value }));
  };

  const handleSaveFact = async () => {
    try {
      const updatedFacts = [...facts];
      updatedFacts[editFact.index] = editFact.text;
      setFacts(updatedFacts);
      setEditFact({ index: null, text: "" });

      const response = await axios.patch(
        `${baseUrl}/subtest/updateFacts/${subTestId}`,
        { facts: updatedFacts },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      console.log("Facts updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating facts:", error);
    }
  };

  const handleEntrySubmit = () => {
    getExerciseEntriesCount(); // Fetch the updated count
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const handleData = () => {
    setIsChart(!isChart);
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
        <div className="jjj">
          <h1 className="hhhbh">{subTestName}</h1>
          <h1 className="courseName">{t("Overview")}</h1>
        </div>
        <div></div>
      </div>
      <div className="poiuyuii">
        <div className="figmaDesignContent">
          {loading && <Loader />} {/* Show loader when loading is true */}
          <div className="ppoo">
            <div className="opooiij">
              <div className="characteristics">
                <div className="editButtonContainer"></div>
                <h2 className="ghjkj">{t("Merkmale")}</h2>
                {role === "admin" && ( // Show edit button only if the role is admin
                  <button className="editButton" onClick={handleEditPopupOpen}>
                    {t("Bearbeiten")}
                  </button>
                )}
                {editableContent ? (
                  <>
                    <div className="mnbi">
                      <div className="lkjhjj">
                        <p className="hello">{t("Dauer")}:</p>
                        <p className="hello">{t("Fähigkeiten")}:</p>
                        <p className="hello">{t("Punkte")}:</p>
                        <p className="hello">{t("Protokolle")}:</p>
                        <p className="hello">{t("Beendigung")}:</p>
                      </div>
                      <div className="lkjuu">
                        <span className="hello12">
                          {editableContent.duration}
                        </span>

                        <span className="hello12">
                          {editableContent.skills}
                        </span>

                        <span className="hello12">
                          {editableContent.points}
                        </span>

                        <span className="hello12">{editableContent.logs}</span>

                        <span className="hello12">
                          {editableContent.termination}
                        </span>
                      </div>
                    </div>
                    <div className="mnbi">
                      <span className="hello121">
                        {editableContent.description1}
                      </span>
                    </div>
                    {/* <div className="mnbi">
                      <p className="hello">{t("Beschreibung 2")}:</p>
                      <span className="hello12">
                        {editableContent.description2}
                      </span>
                    </div>
                    <div className="mnbi">
                      <p className="hello">{t("Beschreibung 3")}:</p>
                      <span className="hello12">
                        {editableContent.description3}
                      </span>
                    </div> */}
                  </>
                ) : (
                  <div className="noCharacteristics">
                    {t("Keine Merkmale verfügbar")}
                  </div>
                )}
              </div>
              <div className="factscard">
                <Slider {...settings}>
                  {facts.map((fact, index) => (
                    <div className="facts" key={index}>
                      {role === "admin" && (
                        <span
                          className="editIcon"
                          onClick={() => handleEditFactClick(index)}
                        >
                          ✎
                        </span>
                      )}
                      <h2 className="factText">{fact}</h2>
                    </div>
                  ))}
                </Slider>

                <div className="completionCard">
                  <div
                    className="progressBackground"
                    style={{
                      width: `${(exerciseEntriesCount / totalSubtest) * 100}%`,
                    }}
                  >
                    <div className="completionText">
                      <h2 className="kjhjjnnmb">
                        Schritt für Schritt zum Erfolg
                      </h2>
                      <p className="jdhcabhb">
                        &gt;
                        <CountUp
                          start={0}
                          end={exerciseEntriesCount}
                          duration={2}
                        />{" "}
                        / <CountUp start={0} end={totalSubtest} duration={2} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {chartData ? (
              <div className="chart-container">
                <div className="stat-toggler-container" onClick={handleData}>
                  <button className="numberf">
                    <img src={bar} alt="" className="bar" />
                    <span className="kuu" style={{ color: theme === "dark" ? "black" : "" }}>
                      {isChart ? t("Numbers") : t("Graph")}
                    </span>
                  </button>
                </div>
                <div className="chart-wrapper">
                  {isChart ? (
                    <Line
                      data={chartData}
                      options={{
                        scales: {
                          x: {
                            ticks: {
                              font: {
                                family: "Montserrat",
                              },
                            },
                          },
                          y: {
                            beginAtZero: true,
                            ticks: {
                              stepSize: 4,
                              font: {
                                family: "Montserrat",
                              },
                              max: 24,
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            display: false,
                            position: "top",
                            labels: {
                              font: {
                                family: "Montserrat",
                              },
                            },
                          },
                          tooltip: {
                            enabled: true,
                            mode: "index",
                            intersect: false,
                            bodyFont: {
                              family: "Montserrat",
                            },
                            titleFont: {
                              family: "Montserrat",
                            },
                          },
                        },
                        hover: {
                          mode: "nearest",
                          intersect: true,
                          axis: "x",
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        interaction: {
                          mode: "nearest", // Makes tooltips and hovering more responsive to actual data points
                          axis: "x",
                        },
                      }}
                    />
                  ) : (
                    <div className="stat-details">
                      <div>
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">{t("Absolvierte Übungen")}</h1>
                            <h1 className="hr">{absolved}</h1>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">{t("Maximale Punktzahl")}</h1>
                            <h1 className="hr">{maximum}</h1>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">{t("Minimale Punktzahl")}</h1>
                            <h1 className="hr">{minimum}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <p
                className="kjhghhnn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10%",
                  fontSize: "25px",
                }}
              >
                {t("Diagramm wird geladen...")}
              </p>
            )}
          </div>
          <div className="poiuii">
            <div className="iuyht">
              <div
                className="newExerciseEntry button-animate"
                onClick={handlePopupOpen}
              >
                <img
                  className="hhggvbgv"
                  src={currentIcons.exc}
                  alt="subtest_exc"
                />
                <h3 className="newexc">{t("Neuer Übungseintrag")}</h3>
              </div>
              <div
                className="newExerciseEntry1 button-animate"
                onClick={handleContinueLearningClick}
              >
                <img
                  src={currentIcons.cont}
                  alt="subtest_cont"
                  className="hhggvbgv"
                />
                <h3 className="cioiuu">{t("Kurs")}</h3>
                <p className="mkjhg">{t("Weiter lernen")}</p>
              </div>
              {index !== 3 && index !== 8 && (
                <div
                  className="newExerciseEntry2 button-animate"
                  onClick={() => handleSectionClick()}
                  style={{ padding: "10px 20px" }}
                >
                  <img
                    src={currentIcons.secNa}
                    alt="subtest_secNa"
                    className="hhggvbgv"
                  />
                  <h3 className="cioiuu">{t(currentIcons.label1)}</h3>
                  <p className="mkjhg">{t(currentIcons.label2)}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ExerciseEntryPopup
        isOpen={isPopupOpen}
        onClose={handlePopupClose}
        subTestId={subTestId}
        subTestName={subTestName}
        onEntrySubmit={handleEntrySubmit} // Pass the function
        subTestIndex={index}
      />

      {editFact.index !== null && (
        <div className="popup">
          <div className="popup-inner">
            <h2 className="popupTitle">{t("Tatsache bearbeiten")}</h2>
            <form
              className="editForm"
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveFact();
              }}
            >
              <div className="formGroup">
                <label className="formLabel">{t("Tatsache")}:</label>
                <textarea
                  className="formTextarea"
                  value={editFact.text}
                  onChange={handleEditFactChange}
                  maxLength={200} // Set your desired character limit here
                />
                <div className="charCount">{editFact.text.length}/200</div>
              </div>
              <div className="formActions">
                <button
                  className="cancejlButton"
                  type="button"
                  onClick={() => setEditFact({ index: null, text: "" })}
                >
                  {t("Abbrechen")}
                </button>
                <button className="savgeButton" type="submit">
                  {t("Speichern")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isEditPopupOpen && (
        <div className="popup">
          <div className="popup-inner">
            <h2 className="popupTitle">{t("Merkmale bearbeiten")}</h2>
            <form
              className="editForm"
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveEdit();
              }}
            >
              <div className="formGroup">
                <label className="formLabel">{t("Dauer")}:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.duration}
                  onChange={(e) => handleInputChange(e, "duration")}
                  maxLength={50} // Set a character limit
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">{t("Fähigkeiten")}:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.skills}
                  onChange={(e) => handleInputChange(e, "skills")}
                  maxLength={50} // Set a character limit
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">{t("Punkte")}:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.points}
                  onChange={(e) => handleInputChange(e, "points")}
                  maxLength={50} // Set a character limit
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">{t("Protokolle")}:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.logs}
                  onChange={(e) => handleInputChange(e, "logs")}
                  maxLength={50} // Set a character limit
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">{t("Beendigung")}:</label>
                <input
                  type="text"
                  className="formInput"
                  value={tempContent.termination}
                  onChange={(e) => handleInputChange(e, "termination")}
                  maxLength={50} // Set a character limit
                />
              </div>
              <div className="formGroup">
                <label className="formLabel">{t("Beschreibung 1")}:</label>
                <textarea
                  className="formInput"
                  value={tempContent.description1}
                  onChange={(e) => handleInputChange(e, "description1")}
                  maxLength={150} // Set a higher character limit for descriptions
                />
              </div>

              <div className="formActions">
                <button
                  className="cancejlButton"
                  type="button"
                  onClick={handleEditPopupClose}
                >
                  {t("Abbrechen")}
                </button>
                <button className="savgeButton" type="submit">
                  {t("Speichern")}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubtestOverviewLearning;
