import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "../styles/journalPopup.css"; // Make sure to create and style this CSS file
import baseUrl from "../baseUrl";
import { IoIosCloseCircleOutline } from "react-icons/io";
import emoji1 from "../assets/images/Emoji1.svg";
import emoji2 from "../assets/images/Emoji2.svg";
import emoji3 from "../assets/images/Emoji3.svg";
import emoji4 from "../assets/images/Emoji4.svg";
import emoji5 from "../assets/images/Emoji5.svg";
import Cookies from "js-cookie";

// Emojis corresponding to each range value from 1 to 5
const moodEmojis = {
  1: emoji1,
  2: emoji2,
  3: emoji3,
  4: emoji4,
  5: emoji5,
};

const JournalPopup = ({ isOpen, onClose, setSuccessScreen }) => {
  const { t } = useTranslation();
  const [wellBeingFactor, setWellBeing] = useState(3);
  const [stressLevelFactor, setStressLevel] = useState(5);
  const [happinessFactor, setMood] = useState(5);
  const [emoji, setEmoji] = useState(moodEmojis[3]);
  const [note, setNote] = useState("");
  const token = Cookies.get("authToken");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [modalMessage, setModalMessage] = useState(""); // State for modal message
  const [loading, setLoading] = useState(false); // State for loader
  const [isAnimatingClose, setIsAnimatingClose] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const now = new Date().toLocaleString("en-US", {
        timeZone: "Europe/Berlin",
      });
      const currentDate = new Date(now).toISOString().split("T")[0];
      const lastSavedDate = localStorage.getItem("lastJournalDate");
      if (lastSavedDate === currentDate) {
        setIsSubmitDisabled(true);
        setModalMessage(t("You can only save one journal entry per day."));
      } else {
        setIsSubmitDisabled(false);
      }
    }
  }, [isOpen, t]);

  const handleSave = async () => {
    // Get current date in German time zone
    const now = new Date().toLocaleString("en-US", {
      timeZone: "Europe/Berlin",
    });
    const currentDate = new Date(now).toISOString().split("T")[0]; // Format as YYYY-MM-DD

    const lastSavedDate = localStorage.getItem("lastJournalDate");

    if (lastSavedDate === currentDate) {
      setModalMessage(t("You can only save one journal entry per day."));
      setIsModalOpen(true); // Show modal
      return;
    }

    const data = {
      wellBeingFactor,
      stressLevelFactor,
      happinessFactor,
      note,
    };
    console.log("data", data);
    setLoading(true); // Show loader

    try {
      const response = await axios.post(
        `${baseUrl}/support/post-daily-journal`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
        }
      );

      console.log("Journal entry response: ", response.data);
      const journalId = response.data.journalEntry.journalId;
      console.log("journalId: ", journalId);

      try {
        // Archive the journal entry
        const archiveResponse = await axios.post(
          `${baseUrl}/archive/post-archive`,
          { journalId },
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": token,
            },
          }
        );

        setIsModalOpen(true); // Show modal
        console.log("Archive response: ", archiveResponse.data);
        setModalMessage(t("Journal saved and archived successfully!"));
        localStorage.setItem("lastJournalDate", currentDate); // Save current date as last saved date
      } catch (archiveError) {
        console.error("Error archiving the journal entry!", archiveError);
        setModalMessage(t("There was an error archiving the journal entry."));
        setIsModalOpen(true); // Show modal
      }
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      setModalMessage(t("There was an error submitting the form."));
      setIsModalOpen(true); // Show modal
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  const getEmoji = (happinessFactor) => {
    return moodEmojis[happinessFactor];
  };

  useEffect(() => {
    setEmoji(getEmoji(happinessFactor));
  }, [happinessFactor]);

  const closeWithAnimation = () => {
    setIsAnimatingClose(true);
    setTimeout(() => {
      setIsAnimatingClose(false);
      onClose(); // Call the original close handler after the animation
    }, 300); // This timeout should match the animation duration
  };

  if (!isOpen) return null;

  return (
    <div className={`popupOverlay1 ${isAnimatingClose ? "popupClosing" : ""}`}>
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="successjj">
              {modalMessage.includes("error") ? t("Error!") : t("Success!")}
            </h2>
            <p className="kjhjj">{modalMessage}</p>
            <button className="yuioi" onClick={closeModal}>
              {t("Exit")}
            </button>
          </div>
        </div>
      )}
      <div className="popupContent1">
        <div className="flex-container">
          <h1 className="popupTitle">{t("Daily Journal Entry")}</h1>
          <div className="close-icon-container" onClick={closeWithAnimation}>
            <IoIosCloseCircleOutline size={"2rem"} />
            <span className="close-tooltip">{t("Close")}</span>
          </div>
        </div>
        <div className="popupBody1">
          <div className="ghjkl">
            <div className="wellBeingContainer">
              <h2 className="headij">{t("Today's Well-Being")}</h2>
              <div className="pojuu">
                <input
                  type="range"
                  min="1"
                  max="5"
                  value={wellBeingFactor}
                  onChange={(e) => setWellBeing(e.target.value)}
                  className="wellBeingSlider"
                />
                <span className="sliderValue">{wellBeingFactor}</span>
              </div>
            </div>
            <div className="stressLevelContainer">
              <h2 className="headij">{t("Today's Stress Level")}</h2>
              <div className="pojuu">
                <input
                  type="range"
                  min="1"
                  max="5"
                  value={stressLevelFactor}
                  onChange={(e) => setStressLevel(e.target.value)}
                  className="stressLevelSlider"
                />
                <span className="sliderValue">{stressLevelFactor}</span>
              </div>
            </div>
            <div className="emojiSliderContainer">
              <h2 className="headij">{t("Mood")}</h2>
              <div className="emojiDisplay">
                <img src={emoji} alt="Mood emoji" className="emojiImg" />
              </div>
              <div className="pojuu">
                <input
                  type="range"
                  min="1"
                  max="5"
                  value={happinessFactor}
                  onChange={(e) => setMood(e.target.value)}
                  className="emojiSlider"
                />
              </div>
            </div>
          </div>
          <hr className="jjjo" />
          <div className="noteContainer">
            <h2 className="writenote">{t("Write A Note:")}</h2>
            <textarea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              className="noteTextarea"
            ></textarea>
          </div>
        </div>
        <div className="oiuyt">
          <button
            className={`saveButton ${isSubmitDisabled ? "disabled" : ""}`}
            onClick={handleSave}
            disabled={isSubmitDisabled}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JournalPopup;
