// reducers/userReducer.js

import { SET_USER, SET_TOKEN , SET_MODE} from "../actions/userActions";

const initialState = {
  name: "",
  email: "",
  mode: 'light',
  token: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_MODE: 
      return {
        ...state, 
        mode: action.payload,
      }
    default:
      return state;
  }
};

export default userReducer;
