import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../styles/labLesson.css";
import DefaultImage from "../assets/images/frame6.png";
import axios from "axios";
import baseUrl from "../baseUrl";
import { FaPen, FaTrash } from "react-icons/fa";
import Popup from "../components/lessonPopup";
import ConfirmationDialog from "./ConfirmationDialog";
import { toast } from "sonner";
import CreateSubTestLessonPopup from "../components/createSubtestLabLessonPopup"; // Import the CreateSubTestLessonPopup component
import VideoImg from "../assets/images/video.png";
import TextImg from "../assets/images/text.png";
import error from "../assets/images/error.png";
import { IoMdArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";
import back from "../assets/images/back.svg";
import Cookies from "js-cookie";

const SubtestLabLesson = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subTestId } = useParams();
  const location = useLocation();
  const [lessons, setLessons] = useState(location.state?.lessons || []);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [currentSubTestName, setCurrentSubTestName] = useState("");
  const [lessonToDelete, setLessonToDelete] = useState(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const role = localStorage.getItem("Role"); // Get role from localStorage

  const togglePopup = (lesson) => {
    setCurrentLesson(lesson);
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleCreatePopup = () => {
    setIsCreatePopupOpen(!isCreatePopupOpen);
  };

  const fetchLessons = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/subtest/get-subtest/${subTestId}`
      );
      setLessons(response.data.lessons);
    } catch (error) {
      console.error("Error fetching lessons:", error);
    }
  };

  useEffect(() => {
    if (lessons.length === 0) {
      fetchLessons();
    }
  }, [subTestId, lessons.length]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleLessonClick = (lesson, index) => {
    if (index > 0 && !lessons[index - 1].completed) {
      // Show message if the previous lesson is not completed
      toast.success(
        t(
          "You have to complete the previous course units before you can open this one."
        )
      );
    } else {
      navigate(`/subtestCombinedLesson/${lesson._id}`, { state: { lesson } });
    }
  };

  const handleSave = (id, lessonTopic) => {
    setLessons((prevLessons) =>
      prevLessons.map((lesson) =>
        lesson._id === id ? { ...lesson, lessonTopic } : lesson
      )
    );
    setIsPopupOpen(false);
  };

  const handleCreate = (newLesson) => {
    setLessons([...lessons, newLesson]);
    toggleCreatePopup();
  };

  const handleAddLessonClick = (subTestName) => {
    setCurrentSubTestName(subTestName);
    setIsCreatePopupOpen(true);
  };

  const handleDelete = async (lessonId) => {
    const authToken = Cookies.get("authToken");
    try {
      await axios.delete(`${baseUrl}/skills/delete-lesson/${lessonId}`, {
        headers: {
          "auth-token": `${authToken}`,
        },
      });
      fetchLessons();
      toast.success(t("Lesson deleted successfully!")); // Show success toast
    } catch (error) {
      console.error("Error deleting lesson:", error);
      toast.error(t("Error deleting lesson.")); // Show error toast
    }
  };

  const confirmDelete = (lessonId, e) => {
    e.stopPropagation(); // Prevent the default behavior
    setLessonToDelete(lessonId);
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirm = () => {
    if (lessonToDelete) {
      handleDelete(lessonToDelete);
    }
    setIsConfirmationDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
        <h1 className="courseName">{location.state?.subTestName}</h1>
      </div>
      {role === "admin" && ( // Show add lesson button only if the role is admin
        <button
          className="addLessonButton"
          onClick={() => handleAddLessonClick(location.state?.subTestName)}
        >
          {t("Add Lesson")}
        </button>
      )}
      <div className="lessonCards">
        <div className="timeline">
          {lessons.map((lesson, index) => (
            <React.Fragment key={index}>
              <div
                className={`dot ${lesson.completed ? "completed" : ""}`}
              ></div>
              {index < lessons.length - 1 && (
                <div
                  className={`line ${
                    lessons[index].completed ? "completed" : ""
                  }`}
                ></div>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="lessoncards">
          {lessons.length === 0 && (
            <div className="no-lessons">
              <p>{t("No lessons available")}</p>
            </div>
          )}
          {lessons.map((lesson, index) => (
            <div
              className={`lessoncard button-animate ${
                index > 0 && !lessons[index - 1].completed ? "disabled" : ""
              }`}
              key={index}
              onClick={() => handleLessonClick(lesson, index)}
            >
              <img
                src={lesson.videoUrl ? VideoImg : lesson.text ? TextImg : error}
                alt={lesson.title}
                className="lessonimg"
              />
              <div className="cardContent">
                <p className="lessonuu">
                  {t("Lesson")} {index + 1}
                </p>
                <p className="lessonp">{lesson.lessonTopic}</p>
              </div>

              {role === "admin" && ( // Show edit and delete icons only if the role is admin
                <>
                  <FaPen
                    className="editIcon1"
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePopup(lesson);
                    }}
                  />
                  <FaTrash
                    className="deleteIcon1"
                    onClick={(e) => confirmDelete(lesson._id, e)}
                  />
                </>
              )}
            </div>
          ))}
          {isPopupOpen && currentLesson && (
            <Popup
              id={currentLesson._id}
              title={currentLesson.lessonTopic}
              onClose={() => setIsPopupOpen(false)}
              onSave={handleSave}
            />
          )}
          {isCreatePopupOpen && (
            <CreateSubTestLessonPopup
              subTestId={subTestId}
              subTestName={currentSubTestName}
              onClose={toggleCreatePopup}
              onCreate={handleCreate}
            />
          )}
        </div>
      </div>
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          message={t("Are you sure you want to delete this lesson?")}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default SubtestLabLesson;
