import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoginPage from "./components/loginPage";
import RegisterPage from "./components/registerPage";
import Dashboard from "./components/dashboard";
import SkillsLab from "./components/skillsLab";
import Subtests from "./components/subtests";
import Archive from "./components/archive";
import Statistcs from "./components/statistics";
import SidePanel from "./components/sidePanel";
import LabLesson from "./components/labLesson";
import VideoLesson from "./components/videoLessn";
import TextLesson from "./components/textLesson";
import StudyPlan from "./components/studyPlan";
import GenerateStudy from "./components/generateStudyPlan";
import Subsets from "./components/subsets";
import Personalization from "./components/personalization";
import Cashback from "./components/cashback";
import RaiseTicketForm from "./components/RaiseTicketForm";
import AssociationBox from "./components/associationBox";
import SubtestOverview from "./components/subtestOverview";
import SubsetOverviewLearning from "./components/subsetOverviewLearning";
import SubsetLabLesson from "./components/subsetLabLesson";
import SubsetVideoLesson from "./components/subsetVideoLesson";
import SubsetTextLesson from "./components/subsetTextLesson";
import menuIcon from "./assets/images/menu.png"; // Add a menu icon image
import "./App.css";
import { useSelector } from "react-redux";
import { MedicalLibrary } from "./components/MedicalLibrary";
import CombinedLesson from "./components/combinedLesson";
import SubtestCombineLesson from "./components/subsetsCombineLesson";
import Report from "./components/Report";
import ChangePassword from "./components/ChangePassword";
import { AdminPanel } from "./components/AdminPanel";
import ProtectedRoute from "./ProtectedRoute"; // Make sure this import is correct
import { DragDropContext } from "react-beautiful-dnd";
import baseUrl from "./baseUrl";
import axios from "axios";
import Cookies from "js-cookie";
import ViewStudyPlan from "./components/ViewStudyPlan";

const App = () => {
  const mode = useSelector((state) => state.user.mode);
  const location = useLocation();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1250);
  const role = localStorage.getItem("Role"); // Get role from localStorage
  const [studyPlanData, setStudyPlanData] = useState([]);
  const isLoginPage = location.pathname === "/";
  const isLabLesson = location.pathname.startsWith("/labLesson");
  const isViewStudyPlan = location.pathname.startsWith("/view-study-plan");
  const isVideoLesson = location.pathname.startsWith("/videoLesson");
  const isTextLesson = location.pathname.startsWith("/textLesson");
  const isStudyPlan = location.pathname === "/settings/generateStudy";
  const isSubsets = location.pathname === "/settings/subsets";
  const isAssociation = location.pathname === "/associationBox";
  const isAssociation2 = location.pathname === "/associationbox";
  const isOverview = location.pathname === "/subtestOverview";
  const isOverviewLearning = location.pathname === "/subtestOverviewLearning";
  const isSubsetLabLesson = location.pathname.startsWith("/subsetLabLesson");
  const isMedicalLibrary = location.pathname.startsWith("/medicallibrary");
  const isSubsetVideoLesson =
    location.pathname.startsWith("/subsetVideoLesson");
  const isSubsetTextLesson = location.pathname.startsWith("/subsetTextLesson");
  const isCombinedLesson = location.pathname.startsWith("/combinedLesson");
  const isSubtestCombinedLesson = location.pathname.startsWith(
    "/subtestCombinedLesson"
  );
  const token = Cookies.get("authToken");

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 1250);
      if (window.innerWidth >= 1250) {
        setIsSidePanelOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleCloseSidePanel = () => {
    setIsSidePanelOpen(false);
  };

  const updateSubtests = async ({ day, updatedSubtests }) => {
    try {
      const response = await axios.patch(
        `${baseUrl}/dashboard/update-studyplan`,
        {
          day,
          subtests: updatedSubtests,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      if (response && response.data) {
        console.log("Rearranged!");
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId !== source.droppableId) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }
    const day = source.droppableId.replace("dayPlan-", "");
    const updatedSubtests = Array.from(studyPlanData[day]);
    const [movedSubtest] = updatedSubtests.splice(source.index, 1);
    updatedSubtests.splice(destination.index, 0, movedSubtest);
    setStudyPlanData({
      ...studyPlanData,
      [day]: updatedSubtests,
    });
    await updateSubtests({ day, updatedSubtests });
  };

  return (
    <div className={`app-container ${mode}`}>
      {!isLoginPage &&
        !isLabLesson &&
        !isVideoLesson &&
        !isTextLesson &&
        !isStudyPlan &&
        !isSubsets &&
        !isAssociation &&
        !isOverview &&
        !isOverviewLearning &&
        !isSubsetLabLesson &&
        !isSubsetVideoLesson &&
        !isMedicalLibrary &&
        !isSubsetTextLesson &&
        !isSubtestCombinedLesson &&
        !isAssociation2 &&
        !isViewStudyPlan &&
        !isCombinedLesson && (
          <>
            {isScreenSmall && (
              <div className="menu-icon" onClick={toggleSidePanel}>
                <img src={menuIcon} alt="Menu Icon" className="kjhhh" />
              </div>
            )}
            <SidePanel
              isOpen={isSidePanelOpen}
              onClose={handleCloseSidePanel}
              isScreenSmall={isScreenSmall}
            />
          </>
        )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Routes location={location}>
          <Route path="/" element={<LoginPage />} />
          <Route element={<ProtectedRoute />}>
            {role === "admin" && (
              <Route path="/register" element={<RegisterPage />} />
            )}
            {role === "admin" && (
              <Route path="/admin-panel" element={<AdminPanel />} />
            )}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/report" element={<Report />} />
            <Route path="/raiseTicket" element={<RaiseTicketForm />} />
            <Route path="/skillsLab" element={<SkillsLab />} />
            <Route path="/subtests" element={<Subtests />} />
            <Route
              path="/view-study-plan"
              element={
                <ViewStudyPlan
                  studyPlanData={studyPlanData}
                  setStudyPlanData={setStudyPlanData}
                />
              }
            />
            <Route path="/archive" element={<Archive />} />
            <Route path="/statistics" element={<Statistcs />} />
            <Route path="/labLesson/:skillId" element={<LabLessonWrapper />} />
            <Route
              path="/settings/change-password"
              element={<ChangePassword />}
            />
            <Route
              path="/videoLesson/:lessonId"
              element={<VideoLessonWrapper />}
            />
            <Route
              path="/textLesson/:lessonId"
              element={<TextLessonWrapper />}
            />
            <Route path="/settings/studyPlan" element={<StudyPlanWrapper />} />
            <Route
              path="/settings/generateStudy"
              element={<GenerateStudyWrapper />}
            />
            <Route
              path="/settings/subsets"
              element={
                <SubsetsWrapper
                  studyPlanData={studyPlanData}
                  setStudyPlanData={setStudyPlanData}
                />
              }
            />
            <Route
              path="/settings/personalization"
              element={<Personalization />}
            />
            <Route path="/settings/cashbackPromotion" element={<Cashback />} />
            <Route
              path="/subtestOverview"
              element={<SubtestOverviewWrapper />}
            />
            <Route
              path="/subtestOverviewLearning"
              element={<SubtestOverviewLearningWrapper />}
            />

            <Route
              path="/subsetLabLesson/:subTestId"
              element={<SubsetLabLessonWrapper />}
            />
            <Route
              path="/subsetVideoLesson/:lessonId"
              element={<SubsetVideoLessonWrapper />}
            />
            <Route
              path="/subsetTextLesson/:lessonId"
              element={<SubsetTextLessonWrapper />}
            />
            <Route
              path="/medicallibrary/"
              element={<MedicalLibraryWrapper />}
            />
            <Route
              path="/associationBox/"
              element={<AssociationBoxWrapper />}
            />
            <Route
              path="/combinedLesson/:lessonId"
              element={<CombinedLessonWrapper />}
            />
            <Route
              path="/subtestCombinedLesson/:lessonId"
              element={<SubtestCombinedLessonWrapper />}
            />
            <Route path="/associationbox" element={<AssociationBoxWrapper />} />
          </Route>
        </Routes>
      </DragDropContext>
    </div>
  );
};

const LabLessonWrapper = () => {
  return (
    <div className="lessonWrapper">
      <LabLesson />
    </div>
  );
};

const VideoLessonWrapper = () => {
  return (
    <div className="lessonWrapper">
      <VideoLesson />
    </div>
  );
};

const TextLessonWrapper = () => {
  return (
    <div className="lessonWrapper">
      <TextLesson />
    </div>
  );
};

const StudyPlanWrapper = () => {
  return (
    <div className="lessonWrapper">
      <StudyPlan />
    </div>
  );
};

const SubsetsWrapper = ({ setStudyPlanData, studyPlanData }) => {
  return (
    <div className="lessonWrapper">
      <Subsets
        setStudyPlanData={setStudyPlanData}
        studyPlanData={studyPlanData}
      />
    </div>
  );
};

const GenerateStudyWrapper = () => {
  return (
    <div className="lessonWrapper">
      <GenerateStudy />
    </div>
  );
};

const AssociationBoxWrapper = () => {
  return (
    <div className="lessonWrapper">
      <AssociationBox />
    </div>
  );
};

const SubtestOverviewWrapper = () => {
  return (
    <div className="lessonWrapper">
      <SubtestOverview />
    </div>
  );
};

const SubtestOverviewLearningWrapper = () => {
  return (
    <div className="lessonWrapper">
      <SubsetOverviewLearning />
    </div>
  );
};

const SubsetLabLessonWrapper = () => {
  return (
    <div className="lessonWrapper">
      <SubsetLabLesson />
    </div>
  );
};

const SubsetVideoLessonWrapper = () => {
  return (
    <div className="lessonWrapper">
      <SubsetVideoLesson />
    </div>
  );
};

const SubsetTextLessonWrapper = () => {
  return (
    <div className="lessonWrapper">
      <SubsetTextLesson />
    </div>
  );
};

const MedicalLibraryWrapper = () => {
  return (
    <div className="lessonWrapper">
      <MedicalLibrary />
    </div>
  );
};

const CombinedLessonWrapper = () => {
  return (
    <div className="lessonWrapper">
      <CombinedLesson />
    </div>
  );
};

const SubtestCombinedLessonWrapper = () => {
  return (
    <div className="lessonWrapper">
      <SubtestCombineLesson />
    </div>
  );
};

export default App;
