import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import "../styles/studyPlan.css";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { ToolTip } from "./ToolTip";
import { useTranslation } from "react-i18next"; // Import useTranslation

const StudyPlan = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const navigate = useNavigate();
  const [tooltip, setTooltip] = useState(false);

  const handleView = () => {
    navigate("/view-study-plan");
  };

  const handleGenerate = () => {
    navigate("/settings/generateStudy");
  };

  const handleCustomize = () => {
    navigate("/settings/subsets", { state: { fromStudyPlan: true } });
  };
  const handleIconClick = (event) => {
    event.stopPropagation();
    setTooltip(true);
  };

  return (
    <div className="dashContainer">
      <Header title={t("Study Plan")} />
      <div className="studyPlan">
        <div className="view-btn button-animate" onClick={handleView}>
          {t("View")}
        </div>

        <div className="view-btn button-animate" onClick={handleGenerate}>
          {t("Generate")}
          <IoMdInformationCircleOutline
            style={{ zIndex: "10" }}
            onClick={handleIconClick}
            className="info-icon"
          />
        </div>

        <div className="view-btn button-animate" onClick={handleCustomize}>
          {t("Customize")}
        </div>

        {tooltip && <ToolTip onClose={() => setTooltip(false)} />}
      </div>
    </div>
  );
};

export default StudyPlan;
