import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
// import "../styles/createSkillPopup.css"; // Create and import a CSS file for styling
import baseUrl from "../baseUrl";
import Loader from "./loader"; // Import the Loader component
import { useTranslation } from "react-i18next"; // Import the useTranslation hook

const CreateSkillPopup = ({ onClose, onCreate }) => {
  const { t } = useTranslation(); // Use the useTranslation hook
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when saving starts

    try {
      const response = await axios.post(`${baseUrl}/skills/post-skill`, {
        skillTopic: title,
        skillDescription: description,
      });
      console.log("Skill created:", response.data);
      onCreate(response.data.skill); // Call the onCreate function to add the new skill card
      onClose(); // Close the popup
    } catch (error) {
      console.error("There was an error creating the skill!", error);
    } finally {
      setLoading(false); // Set loading to false when saving is complete
    }
  };

  return (
    <div className="popup">
      {loading && <Loader />} {/* Show loader when loading is true */}
      <div className={`popup-inner ${loading ? "blur-background" : ""}`}>
        <h2>{t("Create Skill")}</h2>
        <form onSubmit={handleSubmit}>
          <label className="labhjj">
            {t("Skill Title")}:
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="oiuyuu"
            />
          </label>
          <label className="labhjj">
            {t("Description")}:
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="oiuyuu"
            />
          </label>
          <button className="bito" type="submit">
            {t("Create")}
          </button>
          <button className="bito" type="button" onClick={onClose}>
            {t("Cancel")}
          </button>
        </form>
      </div>
    </div>
  );
};

CreateSkillPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateSkillPopup;
