import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import baseUrl from "../baseUrl";
import ExcelFile from "../assets/images/excel-file.png";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const EditQuotePopup = ({ onClose, onSave }) => {
  const { t } = useTranslation();
  const token = Cookies.get("authToken");
  const [quotes, setQuotes] = useState(Array(7).fill(""));
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    extractQuotesFromFile(file);
  };

  const extractQuotesFromFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const headerRow = jsonData[0];
      const quotesColumnIndex = headerRow.findIndex((header) =>
        ["QUOTES", "Quotes", "quotes"].includes(header)
      );

      if (quotesColumnIndex === -1) {
        console.error("No 'QUOTES' column found");
        return;
      }

      // Assuming quotes are in the first column, adjust as needed
      const extractedQuotes = jsonData
        .slice(1)
        .map((row) => row[quotesColumnIndex])
        .filter((quote) => quote !== undefined);
      console.log(extractedQuotes);
      setQuotes(extractedQuotes);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(
        `${baseUrl}/dashboard/update-quote`,
        { quotes },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": token,
          },
        }
      );
      console.log("Quote updated:", response.data);
      onSave(quotes[0]);
      onClose();
    } catch (error) {
      console.error("There was an error updating the quote!", error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-inner">
        <h2>{t("Edit Quote")}</h2>
        <form onSubmit={handleSubmit}>
          <div className="file-drop-container">
            {t("Drop excel file containing quotes")}
            <label className="custom-file-upload" style={{ width: "70%" }}>
              <input
                className="file-input"
                type="file"
                name="excel"
                accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={handleFileChange}
              />
              <div className="file-input-field2" style={{ minHeight: "25vh" }}>
                {selectedFile ? (
                  t("File Uploaded")
                ) : (
                  <img src={ExcelFile} alt={t("excel-file-logo")} />
                )}
              </div>
            </label>
          </div>
          <div className="buttons">
            <button className="bito" type="submit">
              {t("Save")}
            </button>
            <button className="bito" type="button" onClick={onClose}>
              {t("Cancel")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

EditQuotePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EditQuotePopup;
