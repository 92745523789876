import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "../styles/popup.css"; // Create and import a CSS file for styling
import baseUrl from "../baseUrl";
import Loader from "./loader"; // Import the Loader component
import { useTranslation } from "react-i18next";

const SubtestPopup = ({
  id,
  title: initialTitle,
  description: initialDescription,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Create a preview of the image
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when saving starts

    try {
      const formData = new FormData();
      formData.append("subTestName", title);
      formData.append("subTestDescription", description || "");
      if (image) {
        formData.append("image", image);
      }

      const response = await axios.patch(
        `${baseUrl}/subtest/subtests/${id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Subtest updated:", response.data.subTest.subTestImage);
      onSave(id, title, description, response.data.subTest.subTestImage); // Call the onSave function to update the card data
      onClose();
    } catch (error) {
      console.error("There was an error updating the subtest!", error);
    } finally {
      setLoading(false); // Set loading to false when saving is complete
    }
  };

  return (
    <div className="popup">
      {loading && <Loader />} {/* Show loader when loading is true */}
      <div className={`popup-inner ${loading ? "blur-background" : ""}`}>
        <h2>{t("Edit Subtest")}</h2>
        <form onSubmit={handleSubmit}>
          <label className="labhjj">
            {t("Subtest Title")}:
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="oiuyuu"
            />
          </label>
          <label className="labhjj">
            {t("Description")}:
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="oiuyuu"
            />
          </label>
          <label className="labhjj">
            {t("Upload Image")}:
            <input
              type="file"
              accept="image/*"
              name="image" // This name should match the field name in the multer middleware
              onChange={handleImageChange}
              className="oiuyuu"
            />
            {imagePreview && (
              <div className="image-preview">
                <img
                  src={imagePreview}
                  alt="Selected"
                  className="preview-img"
                />
              </div>
            )}
          </label>
          <button className="bito" type="submit">
            {t("Save")}
          </button>
          <button className="bito" type="button" onClick={onClose}>
            {t("Cancel")}
          </button>
        </form>
      </div>
    </div>
  );
};

SubtestPopup.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default SubtestPopup;
