import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../styles/videoLesson.css";
import axios from "axios";
import baseUrl from "../baseUrl";
import VideoSelectorPopup from "../components/videoPopup"; // Import the VideoSelectorPopup component
import { IoMdArrowBack } from "react-icons/io";
import back from "../assets/images/back.svg";

const VideoLesson = () => {
  const navigate = useNavigate();
  const { lessonId } = useParams();
  const location = useLocation();
  const [lesson, setLesson] = useState(
    location.state ? location.state.lesson : null
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const role = localStorage.getItem("Role"); // Get role from localStorage

  useEffect(() => {
    if (!lesson) {
      const fetchLesson = async () => {
        try {
          const response = await axios.get(
            `${baseUrl}/skills/get-lesson-video/${lessonId}`
          );
          console.log("Response data:", response.data);
          setLesson(response.data.lesson);
        } catch (error) {
          console.error("Error fetching lesson:", error);
        }
      };

      fetchLesson();
    }
  }, [lessonId, lesson]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleDoneClick = () => {
    navigate(`/subsetTextLesson/${lessonId}`, { state: { lesson } }); // Pass lesson data to TextLesson
  };

  const handleSave = (id, videoUrl) => {
    setLesson((prevLesson) => ({
      ...prevLesson,
      videoUrl,
    }));
    setIsPopupOpen(false); // Close the popup
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className=" button-animate" />
        </button>
        <h1 className="testName">
          {lesson ? lesson.lessonTopic : "Loading..."}
        </h1>{" "}
      </div>
      {lesson && lesson.videoUrl ? (
        <>
          <div className="videoContent">
            {role === "admin" && ( // Show edit button only if the role is admin
              <div className="editetextt">
                <button
                  className="editTextButton"
                  onClick={() => setIsPopupOpen(true)}
                >
                  Edit Video
                </button>
              </div>
            )}
            <div className="oiuyiit">
              <video controls key={lesson.videoUrl} className="ourg">
                <source src={lesson.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </>
      ) : (
        <div className="contextText">
          <div className="no-text">
            <p className="notext">No Video available</p>
            {role === "admin" && ( // Show add button only if the role is admin
              <div className="bhjkj">
                <button
                  className="addTextButton"
                  onClick={() => setIsPopupOpen(true)}
                >
                  Add Video
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <button className="doneButton" onClick={handleDoneClick}>
        Done
      </button>

      {isPopupOpen && (
        <VideoSelectorPopup
          id={lesson ? lesson._id : lessonId}
          onClose={() => setIsPopupOpen(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default VideoLesson;
