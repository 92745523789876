import React from 'react'
import { IoMdCloseCircleOutline } from "react-icons/io";

export const ReportingScreen = ({ onClose, onOkay, description, setDescription}) => {
    return (
    <div className='popup'>
        <div className="success-screen">
            <div className="popup-header">
                <h3>Report Content</h3>
                <IoMdCloseCircleOutline cursor={"pointer"} size={"1.5rem"} onClick={onClose}/>
            </div>

            <div className="popup-main-heading" style={{alignItems:"start"}} >
                <p>Give description about your issue </p>
                <textarea value={description} onChange={(e)=>setDescription(e.target.value)} placeholder='Enter brief description' type="text" />
            </div>
            <div className="btn-container-edit" style={{justifyContent:"space-evenly"}}>
                <button className="btn-submit" style={{width:"40%"}} onClick={onOkay}>
                    Yes
                </button> 
                <button className="btn-submit" style={{width:"40%"}} onClick={onClose}>
                    Cancel
                </button> 
            </div>
        </div>
    </div>
  )
}
