import React, { useState } from "react";
import "../styles/RequestView.css";
import { BsFillSendFill } from "react-icons/bs";
import { FaRegFlag, FaFlag } from "react-icons/fa6"; // Import FaFlag
import axios from "axios";
import baseUrl from "../baseUrl";
import { toast } from "sonner";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { MdDeleteOutline } from "react-icons/md";
import { ReportingScreen } from "./ReportingScreen";
// import profilePicture from "../assets/icon/profilepicture.svg"

export const RequestView = ({ onClose, selectedRequest }) => {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState(selectedRequest?.comments);
  const [flaggedComments, setFlaggedComments] = useState([]);
  const [description, setDescription] = useState("");
  const [isReporting, setIsReporting] = useState(false);
  const token = Cookies.get("authToken");
  const userId = Cookies.get("userId");
  const { t } = useTranslation();

  const handleComment = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/association/post-comment`,
        {
          content: comment,
          requestId: selectedRequest?.requestId,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      if (response.data) {
        setComments([...comments, response.data.comment]);
        setComment("");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const deleteComment = async (id) => {
    try {
      const response = await axios.post(
        `${baseUrl}/association/delete-comment/`,
        {
          requestId: selectedRequest._id,
          commentId: id,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );

      if (response.data) {
        console.log(response.data);
        setComments((comments) =>
          comments.filter((comment) => comment._id !== id)
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleCommentReport = async (requestId, commentId) => {
    try {
      const response = await axios.patch(
        `${baseUrl}/association/updateRequest/${requestId}`
      );
      toast.success("Reported successfully!");
      setFlaggedComments([...flaggedComments, commentId]);
    } catch (error) {
      toast.error("Failed to report. Please try again.");
      console.error("Error:", error);
    }
  };

  const handleReport = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/report/report-item`,
        {
          itemType: "association-request",
          itemId: selectedRequest._id,
          reportMessage: description,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      if (response.data) {
        setIsReporting(false);
        onClose();
        toast.success(t("Library item reported!"));
      }
    } catch (e) {
      console.log(e.message);
      toast.success(t("Library Reporting Failed!"));
    }
  };

  return (
    <div className="popup">
      <div className="inner-popup" style={{ padding: "18px 0px 18px 0px" }}>
        <div
          className="edit-header"
          style={{ width: "100%", padding: "20px", paddingTop: "0px" }}
        >
          <h3>{t("Request")}</h3>
          <div className="header-right">
            <button
              className="report-button button-animate"
              onClick={() => setIsReporting(true)}
              title={t("Report this Request")}
            >
              <FaRegFlag />
              {t("Report")}
            </button>
            <IoIosCloseCircleOutline
              className="close-btn button-animate"
              title={t("Close")}
              cursor={"pointer"}
              size={"2rem"}
              onClick={() => onClose()}
            />
          </div>
        </div>

        <div className="main-container-request">
          {selectedRequest?.imageUrl ? (
            <div className="left-request">
              <img src={selectedRequest?.imageUrl} alt="" />
            </div>
          ) : (
            <div
              className="left-request"
              style={{
                padding: "15px",
                background: "#cccccc78",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p>{selectedRequest?.facts}</p>
            </div>
          )}
          <div className="right-request">
            {comments?.length === 0 ? (
              <div className="full-container">
                <h3>{t("Be the first one to help out!")}</h3>
              </div>
            ) : (
              comments.map((comment, id) => (
                <div className="comment-container" key={id}>
                  <div className="comment-content">
                    <div className="comment-user">
                      <div className="user">
                        {/* <img src={profilePicture} alt="profile-icon" /> */}
                      </div>
                      <p>MedGangmember</p>
                    </div>

                    <p className="actual-comment">{comment.content}</p>
                  </div>
                  <div className="comment-flag">
                    {comment.userId === userId && (
                      <MdDeleteOutline
                        cursor="pointer"
                        size={"1.20rem"}
                        onClick={() => deleteComment(comment._id)}
                      />
                    )}
                    {flaggedComments.includes(comment._id) ? (
                      <FaFlag
                        cursor="pointer"
                        title={t("Reported")}
                        onClick={() =>
                          handleCommentReport(
                            selectedRequest.requestId,
                            comment._id
                          )
                        }
                      />
                    ) : (
                      <FaRegFlag cursor="pointer" title={t("Report")} />
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="comment-input-container">
          <div className="main-input-comment">
            <input
              type="text"
              value={comment}
              className="comment-input"
              placeholder={t("Write comments here...")}
              onChange={(e) => setComment(e.target.value)}
            />

            <button
              className="send-btn button-animate commentIcon"
              title={t("Send")}
              onClick={handleComment}
            >
              <BsFillSendFill color="white" />
            </button>
          </div>
        </div>
      </div>
      {isReporting && (
        <ReportingScreen
          onClose={() => setIsReporting(false)}
          onOkay={handleReport}
          description={description}
          setDescription={setDescription}
        />
      )}
    </div>
  );
};
