import React, { useState, useEffect } from 'react';
import axios from 'axios';
import baseUrl from '../baseUrl';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { toast } from 'sonner';
import { ConfirmationScreen } from './ConfirmationScreen';
import "../styles/Table.css";
import { IoMdSearch } from 'react-icons/io';
import Cookies from "js-cookie";

export const UserTable = () => {
    const [userList, setUserList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedUser, setSelectedUser] = useState(null);
    const [confirmation, setConfirmation] = useState(false);
    const [query, setQuery] = useState('');
    const authToken = Cookies.get("authToken");

    const itemsPerPage = 10;
    const { t } = useTranslation();

    const handleDelete = (user) => {
        setSelectedUser(user);
        setConfirmation(true);
    }

    const handleClose = () => {
        setSelectedUser(null);
        setConfirmation(false);
    }

    const getUsers = async () => {
        try {
            const response = await axios.get(`${baseUrl}/statistics/users-count`);
            if (response && response.data) {
                setUserList(response.data.users);
                setFilteredUserList(response.data.users); // Initialize filtered list
            }
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    const deleteUser = async () => {
        try {
            const response = await axios.delete(`${baseUrl}/admin/user`, {
                data: { id: selectedUser._id },
                headers: {
                    'auth-token': authToken
                }
            });
            if (response.data.success) {
                toast.success(t("User deleted successfully"));
                setUserList((userList) => userList.filter(user => user._id !== selectedUser?._id));
                setFilteredUserList((userList) => userList.filter(user => user._id !== selectedUser?._id));
            } else {
                toast.error(t("Failed to delete user"));
            }
        } catch (e) {
            toast.error(t("Failed to delete user"));
            console.log(e.message);
        } finally {
            setSelectedUser(null);
            setConfirmation(false);
        }
    }

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = (query) => {
        setQuery(query);
        if (query) {
            setFilteredUserList(userList.filter(user => user.email.toLowerCase().includes(query.toLowerCase())));
        } else {
            setFilteredUserList(userList);
        }
        setCurrentPage(1); // Reset to the first page after search
    }

    const currentUsers = filteredUserList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    return (
        <div className="userListContainer">
            <div className="user-table">
                <div className="table-heading">
                    <h2>
                        {t('User List')}
                    </h2>

                    <div className="library-input-container" style={{ background: "#e8e7e7" }}>
                        <IoMdSearch color='#ccc' fontWeight={"bold"} size={"1.5rem"} />
                        <input
                            type="text"
                            value={query}
                            className='comment-input lib-input'
                            placeholder='Search for Email'
                            onChange={(e) => handleSearch(e.target.value)}
                            style={{ width: "90%", background: "transparent", padding: "7px" }}
                        />
                    </div>
                </div>
                <table className="userList">
                    <thead>
                        <tr>
                            <th>{t('Name')}</th>
                            <th>{t('Email')}</th>
                            <th>{t('Password')}</th>
                            <th>{t('Last Login')}</th>
                            <th>{t('Days created')}</th>
                            <th>{t('Action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentUsers.map((user) => (
                            <tr key={user._id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>
                                    {user.changed
                                        ? 'Password Changed'
                                        : user.textPassword || 'Not Set'}
                                </td>
                                <td>
                                    {user.lastLogin
                                        ? new Date(user.lastLogin).toLocaleString()
                                        : t('Not logged')}
                                </td>
                                <td>
                                    {user.daysSinceCreation
                                        ? user.daysSinceCreation
                                        : 'NA'}
                                </td>
                                <td>
                                    {user.role !== 'admin' && (
                                        <MdDelete
                                            onClick={() => handleDelete(user)}
                                            color="red"
                                            size={'1.25rem'}
                                            cursor={'pointer'}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination">
                    <button
                        className="pagination-button"
                        onClick={() => paginate(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        {t('Previous')}
                    </button>
                    <span className="pagination-info">
                        {t('Page')} {currentPage} {t('of')} {' '}
                        {Math.ceil(filteredUserList.length / itemsPerPage)}
                    </span>
                    <button
                        className="pagination-button"
                        onClick={() => paginate(currentPage + 1)}
                        disabled={currentPage === Math.ceil(filteredUserList.length / itemsPerPage)}
                    >
                        {t('Next')}
                    </button>
                </div>
            </div>
            {
                confirmation &&
                <ConfirmationScreen onClose={handleClose} onOkay={deleteUser} data={selectedUser} />
            }
        </div>
    );
};
