import React, { useState } from "react";
import baseUrl from "../baseUrl.js";
import axios from "axios";
import { HiOutlineUpload } from "react-icons/hi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

export const UploadContent = ({ onClose, setSuccessScreen, setLibraries, libraries }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const token = Cookies.get("authToken");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const role = localStorage.getItem("Role");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== "application/pdf") {
      alert("Please upload a PDF file.");
      event.target.value = null; 
    } else {
      setSelectedFile(event.target.files[0]);
    }
  };

  const sendRequest = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      console.error(t("No file selected."));
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("pdf", selectedFile);
    formData.append("role", role ? role : "user");

    try {
      const response = await axios.post(`${baseUrl}/library/create`, formData, {
        headers: {
          "auth-token": token,
          "Content-Type": "multipart/form-data",
        },
      });
      if (response && response.data) {
        console.log(response.data);
        setLibraries([response.data.libraryItem, ...libraries]);
        setSuccessScreen(true);
        onClose();
      }
    } catch (error) {
      console.error(t("Error uploading file:"), error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="popup">
      <div className="edit-popup-inner">
        <div className="edit-header">
          <h3>{t("New Content")}</h3>
          <IoIosCloseCircleOutline
            cursor={"pointer"}
            size={"2rem"}
            onClick={() => onClose()}
            title="Close"
            className='close-btn'
          />
        </div>
        <hr />
        <div className="second-field">
          <span className="input-container">
            <h5 className="temp-label">{t("Title")}</h5>
          </span>
          <input
            className="input-field"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <span className="input-container" style={{marginTop:"0.5rem"}}>
            <h5 className="temp-label">{t("Description")}</h5>
          </span>
          <textarea
            className="input-field"
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <div className="horizontal-line"></div>
          <div className="image-uploader">
            <h4 className="temp-heading2">{t("Upload photo")}</h4>
            <h6 className="temp-heading2">{t("Max file size: 2mb")}</h6>

            <label className="custom-file-upload">
              <input
                className="file-input"
                type="file"
                name="pdf"
                accept=".pdf"
                onChange={handleFileChange}
              />
              <div className="file-input-field" style={{height:"15vh"}}>
                {selectedFile ? (
                  t("File Uploaded")
                ) : (
                  <div className="lib-upload-container">
                    {/* <img src={uploadIcon} alt="" /> */}
                    <HiOutlineUpload size={"2.4rem"} color="#cccccc" />
                    
                    <p style={{ wordSpacing: "1px", fontSize: "0.75rem", maxWidth: "200px" }}>
                      {t("Click here or drag and drop your file")}
                    </p>
                  </div>
                )}
              </div>
            </label>
          </div>
        </div>

        <div className="btn-container-edit">
          <button title={`Click to ${t("Publish")}`} className={`btn-submit ${(title.length === 0 || description.length===0 || !selectedFile) && 'disabled'}`} onClick={sendRequest} disabled={(title.length === 0 || description.length===0 || !selectedFile)}>
            {loading ? <span className="loader-video"></span> : t("Publish")}
          </button>
        </div>
      </div>
    </div>
  );
};
