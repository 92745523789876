import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../styles/textLesson.css";
import axios from "axios";
import baseUrl from "../baseUrl";
import TextEditorPopup from "../components/textPopup"; // Import the TextEditorPopup component
import { IoMdArrowBack } from "react-icons/io";
import back from "../assets/images/back.svg";
import Cookies from "js-cookie";

const TextLesson = () => {
  const navigate = useNavigate();
  const { lessonId } = useParams();
  const location = useLocation();
  const [lesson, setLesson] = useState(
    location.state ? location.state.lesson : null
  );
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    if (!lesson) {
      const fetchLesson = async () => {
        try {
          const response = await axios.get(
            `${baseUrl}/skills/get-lesson-text/${lessonId}`
          );
          console.log("Response data: ", response.data);
          setLesson(response.data.lesson);
        } catch (error) {
          console.error("Error fetching lesson:", error);
        }
      };

      fetchLesson();
    }
  }, [lessonId, lesson]);

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleSave = (id, updatedHeader, updatedContent) => {
    setLesson((prevLesson) =>
      prevLesson._id === id
        ? {
            ...prevLesson,
            text: { header: updatedHeader, content: updatedContent },
          }
        : prevLesson
    );
    setIsPopupOpen(false); // Close the popup
  };

  const handleNextClick = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/archive/post-archive`,
        { lessonId },
        {
          headers: {
            "auth-token": Cookies.get("authToken"),
          },
        }
      );
      console.log("Archive response:", response.data);
      navigate("/subtests");
    } catch (error) {
      console.error("Error archiving lesson:", error);
    }
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
        <h1 className="testName">
          {lesson ? lesson.lessonTopic : "Loading..."}
        </h1>
        <div className="markComplete">
          <input type="checkbox" id="markComplete" name="markComplete" />
          <label htmlFor="markComplete">Mark as complete</label>
        </div>
      </div>

      {lesson && lesson.text ? (
        <>
          <div className="oihbhu">
            <div className="editetextt">
              <button
                className="editTextButton"
                onClick={() => setIsPopupOpen(true)}
              >
                Edit Text
              </button>
            </div>
            <div className="loijuh">
              <h2 className="oiuyoi">{lesson.text.header}</h2>
              <div
                className="iuyt"
                dangerouslySetInnerHTML={{ __html: lesson.text.content }}
              ></div>
            </div>
          </div>
        </>
      ) : (
        <div className="contextText">
          <div className="no-text">
            <p className="notext">No text available</p>
            <div className="bhjkj">
              <button
                className="addTextButton"
                onClick={() => setIsPopupOpen(true)}
              >
                Add Text
              </button>
            </div>
          </div>
        </div>
      )}
      <button className="nextButton1" onClick={handleNextClick}>
        Next
      </button>

      {isPopupOpen && (
        <TextEditorPopup
          id={lesson._id}
          initialHeader={lesson.text?.header || ""}
          initialContent={lesson.text?.content || ""}
          onClose={() => setIsPopupOpen(false)}
          onSave={handleSave}
        />
      )}
    </div>
  );
};

export default TextLesson;
