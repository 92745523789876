import React, { useState, useEffect } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import "../styles/excersisePopup.css"; // Make sure to create and style this CSS file

const ExerciseEntryPopup = ({
  isOpen,
  onClose,
  subTestName,
  exerciseEntries,
}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;
  const totalPages = Math.ceil(exerciseEntries.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    setCurrentPage(1); // Reset to the first page whenever the popup is opened
  }, [isOpen]);

  if (!isOpen) return null;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedEntries = exerciseEntries.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <>
      <div className="popupOver3lay">
        <div className="popupwContent">
          <button className="closeButton" onClick={onClose}>
            <IoIosCloseCircleOutline cursor={"pointer"} size={"2rem"} />
          </button>
          <h1 className="popusphTithle">
            {t("Exercise Entries for")} {subTestName}
          </h1>
          <hr />
          <div className="pospupBody">
            {selectedEntries.map((entry, index) => (
              <div key={index} className="entryItem">
                <p>
                  <strong className="hano">{t("Result")}:</strong>{" "}
                  {entry.result}
                </p>
                <p>
                  <strong className="hano">{t("Test Time")}:</strong>{" "}
                  {entry.testTime}
                </p>
                {entry.questions.map((question, qIndex) => (
                  <div key={qIndex}>
                    <p>
                      <strong className="hano">
                        {t("Question")}:{" "}
                        <span className="mkjnjjj"> {question.question}</span>
                      </strong>
                    </p>
                    <p className="mgggg">
                      {t("Answer")}: {question.answer}
                    </p>
                  </div>
                ))}
              </div>
            ))}
            {totalPages > 1 && (
              <div className="pagination">
                <button
                  className="pagination-button"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  {t("Previous")}
                </button>
                <button
                  className="pagination-button"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  {t("Next")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExerciseEntryPopup;
