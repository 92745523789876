import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import baseUrl from "../baseUrl";
import VideoSelectorPopup from "../components/videoPopup";
import TextEditorPopup from "../components/textPopup";
import ReactPlayer from "react-player";
import { IoMdArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";
import back from "../assets/images/back.svg";
import Cookies from "js-cookie";

const CombinedLesson = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lessonId } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false); // State for loader
  const [lesson, setLesson] = useState(
    location.state ? location.state.lesson : null
  );
  const [isVideoPopupOpen, setIsVideoPopupOpen] = useState(false);
  const [isTextPopupOpen, setIsTextPopupOpen] = useState(false);
  const role = localStorage.getItem("Role");

  useEffect(() => {
    if (!lesson) {
      const fetchLesson = async () => {
        try {
          const response = await axios.get(
            `${baseUrl}/skills/get-lesson/${lessonId}`
          );
          console.log("Response data:", response.data);
          setLesson(response.data.lesson);
        } catch (error) {
          console.error("Error fetching lesson:", error);
        }
      };

      fetchLesson();
    }
  }, [lessonId, lesson]);

  useEffect(() => {
    const disableInspect = (e) => {
      if (e.keyCode === 123 || (e.ctrlKey && e.shiftKey && e.keyCode === 73)) {
        e.preventDefault();
      }
    };
    document.addEventListener("contextmenu", (e) => e.preventDefault());
    document.addEventListener("keydown", disableInspect);
    return () => {
      document.removeEventListener("contextmenu", (e) => e.preventDefault());
      document.removeEventListener("keydown", disableInspect);
    };
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleDoneClick = async () => {
    setLoading(true); // Show loader

    try {
      // Archive the lesson
      await axios.post(
        `${baseUrl}/archive/post-archive`,
        { lessonId },
        {
          headers: {
            "auth-token": Cookies.get("authToken"),
          },
        }
      );

      // Mark the lesson as completed
      await axios.post(
        `${baseUrl}/archive/mark-completed`,
        { lessonId },
        {
          headers: {
            "auth-token": Cookies.get("authToken"),
          },
        }
      );

      console.log("Lesson completed and archived");
      navigate(-1);
    } catch (error) {
      console.error("Error completing lesson:", error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleSaveVideo = (id, videoUrl) => {
    setLesson((prevLesson) => ({
      ...prevLesson,
      videoUrl,
      text: null,
    }));
    setIsVideoPopupOpen(false);
  };

  const handleSaveText = (id, updatedHeader, updatedContent) => {
    setLesson((prevLesson) => ({
      ...prevLesson,
      text: { header: updatedHeader, content: updatedContent },
      videoUrl: null,
    }));
    setIsTextPopupOpen(false);
  };

  return (
    <div className="labLessonContainer">
      {loading && (
        <div className="loader-overlay">
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </div>
      )}
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
        <h1 className="testName">
          {lesson ? lesson.lessonTopic : t("Loading...")}
        </h1>
      </div>
      {lesson && (
        <>
          {lesson.videoUrl ? (
            <div className="videoContent">
              {role === "admin" && (
                <div className="editetextt">
                  <button
                    className="editTextButton"
                    onClick={() => setIsVideoPopupOpen(true)}
                  >
                    {t("Edit Video")}
                  </button>
                </div>
              )}
              <div
                className="oiuyiit"
                onContextMenu={(e) => e.preventDefault()}
              >
                <ReactPlayer
                  url={lesson.videoUrl}
                  controls
                  className="ourg"
                  width="1121px"
                  height="529px"
                />
              </div>
              {lesson && (lesson.videoUrl || lesson.text) && (
                <div className="iuy">
                  <button className="doneButton" onClick={handleDoneClick}>
                    {t("Done")}
                  </button>
                </div>
              )}
            </div>
          ) : lesson.text ? (
            <div className="oihbhu">
              {role === "admin" && (
                <div className="editetextt">
                  <button
                    className="editTextButton"
                    onClick={() => setIsTextPopupOpen(true)}
                  >
                    {t("Edit Text")}
                  </button>
                </div>
              )}
              <div className="loijuh">
                <h2 className="oiuyoi">{lesson.text.header}</h2>
                <div
                  className="iuyt"
                  dangerouslySetInnerHTML={{ __html: lesson.text.content }}
                ></div>
              </div>
              {lesson && (lesson.videoUrl || lesson.text) && (
                <div className="iuy">
                  <button className="doneButton" onClick={handleDoneClick}>
                    {t("Done")}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="contextText1">
              <p className="notext">{t("No content available")}</p>
              {role === "admin" && (
                <div className="bhjkj1">
                  <button
                    className="addTextButton"
                    onClick={() => setIsVideoPopupOpen(true)}
                  >
                    {t("Add Video")}
                  </button>
                  <p className="or">{t("OR")}</p>
                  <button
                    className="addTextButton"
                    onClick={() => setIsTextPopupOpen(true)}
                  >
                    {t("Add Text")}
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}

      {isVideoPopupOpen && (
        <VideoSelectorPopup
          id={lesson ? lesson._id : lessonId}
          onClose={() => setIsVideoPopupOpen(false)}
          onSave={handleSaveVideo}
        />
      )}
      {isTextPopupOpen && (
        <TextEditorPopup
          id={lesson._id}
          initialHeader={lesson.text?.header || ""}
          initialContent={lesson.text?.content || ""}
          onClose={() => setIsTextPopupOpen(false)}
          onSave={handleSaveText}
        />
      )}
    </div>
  );
};

export default CombinedLesson;
