import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./header";
import Card from "./cards";
import "../styles/skillsLab.css";
import { useNavigate } from "react-router-dom";
import baseUrl from "../baseUrl";
import { FaPen, FaTrash } from "react-icons/fa";
import Popup from "../components/popup";
import CreateSkillPopup from "../components/createSkillPopup";
import { TailSpin } from "react-loader-spinner";
import ConfirmationDialog from "./ConfirmationDialog";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const SkillsLab = () => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCreatePopupOpen, setIsCreatePopupOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [skills, setSkills] = useState([]);
  const [currentSkill, setCurrentSkill] = useState(null);
  const [skillToDelete, setSkillToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [skillsPerPage] = useState(8);
  const [loading, setLoading] = useState(true); // Add loading state
  const role = localStorage.getItem("Role"); // Get role from localStorage
  const navigate = useNavigate();

  const togglePopup = (skill) => {
    setCurrentSkill(skill);
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleCreatePopup = () => {
    setIsCreatePopupOpen(!isCreatePopupOpen);
  };

  const fetchSkills = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const response = await axios.get(`${baseUrl}/skills/get-skills`);
      console.log("skills: ", response.data.skills);
      setSkills(response.data.skills);
    } catch (error) {
      console.error("Error fetching skills:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  const handleCardClick = async (skillId, skillTopic) => {
    console.log("my skill id: ", skillId);
    console.log("my skill topic: ", skillTopic);
    try {
      const response = await axios.get(
        `${baseUrl}/skills/get-lessons/${skillId}`
      );
      const lessons = response.data.lessons;
      console.log("lessons: ", lessons);
      navigate(`/labLesson/${skillId}`, { state: { lessons, skillTopic } });
    } catch (error) {
      console.error("Error fetching lessons:", error);
      // Navigate to LabLesson even if no lessons are fetched
      navigate(`/labLesson/${skillId}`, { state: { lessons: [], skillTopic } });
    }
  };

  const handleSave = (id, title, description, skillImage) => {
    console.log("skillImage : ", skillImage);
    setSkills(
      skills.map((skill) =>
        skill._id === id
          ? {
              ...skill,
              skillTopic: title,
              skillDescription: description,
              skillImage,
            }
          : skill
      )
    );
    togglePopup(null);
  };

  const handleDelete = async (skillId) => {
    // Get the auth token from localStorage
    const authToken = Cookies.get("authToken");

    try {
      await axios.delete(`${baseUrl}/skills/delete-skill/${skillId}`, {
        headers: {
          "auth-token": `${authToken}`,
        },
      });

      // Adjust current page if necessary
      const remainingItems =
        skills.filter(
          (skill) =>
            skill.skillTopic !== "Kreuzblick101" &&
            skill.skillTopic !== "Quanti101"
        ).length - 1;
      const totalPages = Math.ceil(remainingItems / skillsPerPage);
      if (currentPage > totalPages) {
        setCurrentPage(currentPage - 1);
      }

      fetchSkills();
      toast.success(t("Skill deleted successfully!"));
    } catch (error) {
      console.error("Error deleting skill:", error);
      toast.error(t("Error deleting skill. Please try again."));
    }
  };

  const confirmDelete = (skillId, e) => {
    e.stopPropagation(); // Prevent the default behavior
    setSkillToDelete(skillId);
    setIsConfirmationDialogOpen(true);
  };

  const handleCreate = (newSkill) => {
    setSkills([...skills, newSkill]);
    toggleCreatePopup();
  };

  const handleConfirm = () => {
    if (skillToDelete) {
      handleDelete(skillToDelete);
    }
    setIsConfirmationDialogOpen(false);
  };

  const handleCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  // Filter the skills to exclude the specified topics
  const filteredSkills = skills.filter(
    (skill) =>
      skill.skillTopic !== "Kreuzblick101" && skill.skillTopic !== "Quanti101"
  );

  // Pagination logic
  const indexOfLastSkill = currentPage * skillsPerPage;
  const indexOfFirstSkill = indexOfLastSkill - skillsPerPage;
  const currentSkills = filteredSkills.slice(
    indexOfFirstSkill,
    indexOfLastSkill
  );

  const totalPages = Math.ceil(filteredSkills.length / skillsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="dashContainer">
      <Header title={t("Skills Lab")} />

      {loading ? (
        <div className="loader-container">
          <TailSpin
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="testcont">
          {role === "admin" && ( // Show add skill button only if the role is admin
            <div className="uuiiii">
              <button className="addSkillButton" onClick={toggleCreatePopup}>
                {t("Add Skill")}
              </button>
            </div>
          )}

          <div className="labCards">
            {currentSkills.map((skill, index) => (
              <div
                onClick={() => handleCardClick(skill.skillId, skill.skillTopic)}
                key={index}
              >
                {role === "admin" && ( // Show edit and delete icons only if the role is admin
                  <>
                    <FaPen
                      className="editIcon"
                      onClick={(e) => {
                        e.stopPropagation();
                        togglePopup(skill);
                      }}
                    />{" "}
                    <FaTrash
                      style={{
                        color: "#f87171",
                        cursor: "pointer",
                        hover: "#dc2626",
                        marginLeft: "2px",
                      }}
                      onClick={(e) => confirmDelete(skill.skillId, e)}
                    />{" "}
                  </>
                )}
                <Card
                  title={skill.skillTopic}
                  description={skill.skillDescription}
                  image={skill.skillImage} // Use a default image if none is provided
                />
              </div>
            ))}

            {isPopupOpen && currentSkill && (
              <Popup
                id={currentSkill._id}
                title={currentSkill.skillTopic}
                description={currentSkill.skillDescription}
                onClose={() => togglePopup(null)}
                onSave={handleSave}
              />
            )}
            {isCreatePopupOpen && (
              <CreateSkillPopup
                onClose={toggleCreatePopup}
                onCreate={handleCreate}
              />
            )}
          </div>
          {totalPages > 1 && (
            <div className="pagination">
              <button
                className="pagination-button"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                {t("Previous")}
              </button>
              <span className="pagination-info">
                {t("Page")} {currentPage} {t("of")} {totalPages}
              </span>
              <button
                className="pagination-button"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                {t("Next")}
              </button>
            </div>
          )}
        </div>
      )}
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          message={t("Are you sure you want to delete this skill?")}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default SkillsLab;
