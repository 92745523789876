import React, { useEffect, useState } from 'react';
import baseUrl from '../baseUrl';
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from 'axios';
import Cookies from "js-cookie";
// CSS in Popup.css
export const AddSubtestPopup = ({onClose, passedSubsets, day, getStudyPlan}) => {

    const [subtests, setSubtests] = useState([]);
    const [selectedSubtests, setSelectedSubtests] = useState(passedSubsets);
    const token = Cookies.get("authToken");

    const getAllSubtests = async()=>{
        try {
            const response = await axios.get(`${baseUrl}/dashboard/get-all-subtests`);
            if(response && response.data){
                setSubtests(response.data.subtests);
            }else{
                console.log("Something went wrong");
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const updateSubtests = async() => {
        try {
            const response = await axios.patch(`${baseUrl}/dashboard/update-studyplan`, {
                day, 
                subtests: selectedSubtests
            },
            {
                headers: {
                    'auth-token': token
                }
            });

            if(response && response.data){
                onClose();
                getStudyPlan();
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(()=>{
        getAllSubtests();
    }, []);

    const firstFourSubtests = subtests.slice(0, 4);
    const nextFourSubtests = subtests.slice(4, 8);

    const handleSubtestClick = (subtest) => {
        setSelectedSubtests((prevSelectedSubtests) => {
            if (prevSelectedSubtests.includes(subtest)) {
                
                return prevSelectedSubtests.filter(item => item !== subtest);
            } else {
                return [...prevSelectedSubtests, subtest];
            }
        });
    };

    const isSelected = (subtest) => {
        return selectedSubtests.some(selectedSubtest => selectedSubtest.subTestId === subtest.subTestId);
    };

  return (
    <div className='popup'>
        <div className="popup-inner">
            <div className="popup-header">
                <h3>Add Subtests</h3>
                <IoIosCloseCircleOutline
                    cursor={"pointer"}
                    size={"2rem"}
                    onClick={() => onClose()}
                />
            </div>

            <div className="main-popup-component">
                <div className="popup-left">
                    {firstFourSubtests.map((subtest, idx) => (
                        <div key={idx} 
                         className={`subtestItem ${isSelected(subtest) ? 'selected' : ''}`}
                         onClick={() => handleSubtestClick(subtest)}>
                            <div className="first-list-item" style={{ width: "65%" }}>
                                <div className="subtestIcon">
                                    <img src={subtest.subTestImage} alt="" />
                                </div>
                                {subtest.subTestName}
                            </div>
                            <div className="first-list-item"></div>
                        </div>
                    ))}
                  
                </div>
                <div className="popup-left">
                        {nextFourSubtests.map((subtest, idx) => (
                            <div key={idx} 
                             className={`subtestItem ${isSelected(subtest) ? 'selected' : ''}`}
                             onClick={() => handleSubtestClick(subtest)}>
                                <div className="first-list-item" style={{ width: "65%" }}>
                                    <div className="subtestIcon">
                                        <img src={subtest.subTestImage} alt="" />
                                    </div>
                                    {subtest.subTestName}
                                </div>
                                <div className="first-list-item"></div>
                            </div>
                        ))}
                </div>
            </div>

            <div className="btn-container-edit">
                <button className="btn-submit" style={{width:"25%"}}  onClick={updateSubtests}>
                    Save
                </button>
            </div>
            
        </div>
    </div>
  )
}
