import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/generateStudyPlan.css";
import clockIcon from "../assets/icon/26.png"; // Assuming you have an icon
import axios from "axios";
import baseUrl from "../baseUrl";
import { useTranslation } from "react-i18next";
import { FailureScreen } from "./FailureScreen";
import back from "../assets/images/back.svg";
import Cookies from "js-cookie";

const GenerateStudyPlan = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = Cookies.get("authToken");
  const [failureScreen, setFailureScreen] = useState(false);
  const [missingSubTests, setMessingSubTests] = useState([]);

  const handleClose = () => {
    setFailureScreen(false);
    setMessingSubTests([]);
    navigate("/dashboard");
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleCancelClick = () => {
    navigate(-1); // Go back to the previous page
  };

  const generateStudyPlan = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/dashboard/generate-study-plan`,
        {},
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      if (response.data && response.data.success) {
        navigate("/settings/subsets");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const checkExercises = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/dashboard/check-exercises`,
        {},
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      if (response.data && response.data.success) {
        generateStudyPlan();
      } else {
        setMessingSubTests(response.data.missingSubtests);
        setFailureScreen(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    checkExercises();
  }, [token]);

  return (
    <div className="labLessonContainer">
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
        <h1 className="courseName">{t("Generate Study Plan")}</h1>
      </div>
      <div className="content">
        <img src={clockIcon} alt="Clock Icon" className="clockIcon" />
        <div className="animation-container">
          <h2 className="tyui">{t("Hold On Tight")}</h2>
          {!failureScreen && <h2 className="typing-animation"></h2>}
        </div>
        <div className="kjhg">
          <p className="tyukm">
            {t(
              "We are generating a study plan for you. It may take some time. Do not close this tab."
            )}
          </p>
        </div>
        <button
          className="cancelButton button-animate"
          onClick={handleCancelClick}
        >
          {t("Cancel")}
        </button>
      </div>
      {failureScreen && (
        <FailureScreen data={missingSubTests} onClose={handleClose} />
      )}
    </div>
  );
};

export default GenerateStudyPlan;
