import React from "react";
import PropTypes from "prop-types";

const ConfirmationDialog = ({ message, onConfirm, onCancel }) => {
  const dialogStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: "30px",
    boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
    zIndex: 1000,
    borderRadius: "8px",
    width: "300px",
    textAlign: "center",
  };

  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 999,
  };

  const buttonContainerStyles = {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    marginTop: "20px",
  };

  const buttonStyles = {
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "4px",
    border: "none",
    fontSize: "16px",
  };

  return (
    <>
      <div style={overlayStyles} />
      <div style={dialogStyles}>
        <p>{message}</p>
        <div style={buttonContainerStyles}>
          <button
            style={{ ...buttonStyles, backgroundColor: "#4CAF50", color: "#fff" }}
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            style={{ ...buttonStyles, backgroundColor: "#ccc" }}
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </>
  );
};

ConfirmationDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
