import React, { useEffect, useState } from "react";
import "../styles/subsets.css";
import axios from "axios";
import baseUrl from "../baseUrl";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import back from "../assets/images/back.svg";
import Cookies from "js-cookie";
import { SingleSubtestNormal } from "./SingleSubtestNormal";

const ViewStudyPlan = ({ studyPlanData, setStudyPlanData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [manageClicked, setManageClicked] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const token = Cookies.get("authToken");

  const getStudyPlan = async () => {
    try {
      const response = await axios.get(`${baseUrl}/dashboard/get-study-plan`, {
        headers: {
          "auth-token": token,
        },
      });
      if (response && response.data) {
        setStudyPlanData(response.data.studyPlan);
        console.log(response.data);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getStudyPlan();
  }, []);

  const getTodayDay = () => {
    const days = [
      t("Sunday"),
      t("Monday"),
      t("Tuesday"),
      t("Wednesday"),
      t("Thursday"),
      t("Friday"),
      t("Saturday"),
    ];
    const today = new Date().getDay();
    return days[today];
  };

  const translateDay = (day) => {
    const daysMapping = {
      Sunday: t("Sunday"),
      Monday: t("Monday"),
      Tuesday: t("Tuesday"),
      Wednesday: t("Wednesday"),
      Thursday: t("Thursday"),
      Friday: t("Friday"),
      Saturday: t("Saturday"),
    };
    return daysMapping[day] || day;
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer">
        <button className="backButton" onClick={handleBackClick}>
          <img src={back} alt="" className="backk button-animate" />
        </button>
        <h1 className="courseName">{t("Study Plan")}</h1>
      </div>
      <hr />

      <div className="studyPlanContainer">
        <div className="studyPlanGrid">
        {
        studyPlanData.map(({day, subtests}) => (
        <div
          key={day}
          className={`day-entry ${manageClicked && selectedDay !== day ? "overlay-applied" : ""}`}
        >
          {translateDay(day) === getTodayDay() && (
            <span className="todayLabel">{t("TODAY")}</span>
          )}
          <div className={`dayColumn ${translateDay(day) === getTodayDay() ? "today" : ""}`}>
            <div className="dayHeader" style={{ justifyContent: "center" }}>
              <span>{translateDay(day)}</span>
            </div>
            <ul className="subtestList">
              {subtests?.map((subtest, idx) => (
                <SingleSubtestNormal
                  subtest={subtest}
                  index={idx}
                  selectedDay={selectedDay}
                  day={day}
                  key={idx}
                />
              ))}
            </ul>
          </div>
        </div>
      ))}
        </div>
      </div>
    </div>
  );
};

export default ViewStudyPlan;
