import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Line, Bar } from "react-chartjs-2";
import "chart.js/auto";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import Header from "./header";
import "../styles/statistics.css";
import graph from "../assets/images/graph.svg";
import frame7 from "../assets/images/frame7.png";
import baseUrl from "../baseUrl";
import wave from "../assets/images/waves.png";
import { TailSpin } from "react-loader-spinner";
import { FaTrash } from "react-icons/fa";
import { toast } from "sonner";
import { ConfirmationScreen } from "./ConfirmationScreen";
import white from "../assets/images/whitebg.png";
import black from "../assets/images/black.png";
import bar from "../assets/images/bar.svg";
import Cookies from "js-cookie";
import "chartjs-plugin-crosshair";
import { Calendar } from "react-feather";
import { ThemeContext } from "../ThemeContext";

const Statistics = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    wellBeingFactors: [],
    stressLevelFactors: [],
    happinessFactors: [],
  });
  const { theme } = useContext(ThemeContext);
  let isDarkTheme = theme === "dark";
  const [ticketData, setTicketData] = useState([]);
  const [userCreationStats, setUserCreationStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showCharts, setShowCharts] = useState(false);
  const [users, setUsers] = useState(0);
  const [avgTime, setAvgTime] = useState();
  const [dailyActiveUsers, setDailyActiveUsers] = useState({});
  const [userList, setUserList] = useState([]);
  const [cashback, setCashback] = useState(0);
  const [cashbackList, setCashbackList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [avgHourWeekUser, setAvgHourWeekUser] = useState("");
  const [avgHourDailyUser, setAvgHourDailyUser] = useState("");
  const [chartDataUser, setChartDataUser] = useState({});
  const [wellBeingFactorsUser, setWellBeingFactorUser] = useState("");
  const [stressLevelFactorsUser, setstressLevelFactorUser] = useState("");
  const [happinessFactorsUser, setHapinessFactorUser] = useState("");
  const [daysSinceRegistration, setDaysSinceRegistration] = useState("");
  const [absolved, setAbsolved] = useState();
  const [strongestSubject, setStrongestSubject] = useState();
  const [strongestSubjectName, setStrongesSubjectName] = useState();
  const [weakestSubject, setWeakestSubject] = useState();
  const [weakestSubjectName, setWeakestSubjectName] = useState();
  const [avgScoreSubTest, setAvgScoreSubTest] = useState();
  const [fullScore, setFullScore] = useState();
  const [confirmation, setConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [subTestScores, setSubTestScores] = useState([]);
  const [subTestOptions, setSubTestOptions] = useState([]);
  const [entries, setEntries] = useState([]);
  const [selectedSubTest, setSelectedSubTest] = useState("");
  const [chartDataSubTest, setChartDataSubTest] = useState({
    labels: [],
    datasets: [],
  });
  const [status, setStatus] = useState("all");
  const [currentPageCashback, setCurrentPageCashback] = useState(1);
  const [itemsPerPageCashback, setItemsPerPageCashback] = useState(10);
  const authToken = Cookies.get("authToken");
  const personalized = JSON.parse(localStorage.getItem("personalize"));
  const role = localStorage.getItem("Role");

  const monthNames = [
    t("January"),
    t("February"),
    t("March"),
    t("April"),
    t("May"),
    t("June"),
    t("July"),
    t("August"),
    t("September"),
    t("October"),
    t("November"),
    t("December"),
  ];

  const usersPerPage = 10;

  const fetchData = async () => {
    try {
      const authToken = Cookies.get("authToken");
      const response = await axios.get(`${baseUrl}/statistics/journal-weekly`, {
        headers: {
          "auth-token": `${authToken}`,
        },
      });
      setData(response.data.list);

      setWellBeingFactorUser(response.data.avgWellBeing);
      setstressLevelFactorUser(response.data.avgStressLevel);
      setHapinessFactorUser(response.data.avgHappiness);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const fetchTicketData = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const response = await axios.get(
        `${baseUrl}/statistics/tickets/${currentYear}`
      );
      setTicketData(response.data.data);
    } catch (error) {
      console.error("Error fetching ticket data: ", error);
    }
  };

  const fetchUserCreationStats = async () => {
    try {
      const currentYear = new Date().getFullYear();
      const response = await axios.get(
        `${baseUrl}/statistics/users/${currentYear}`
      );
      setUserCreationStats(response.data.data);
    } catch (error) {
      console.error("Error fetching user creation stats:", error);
    }
  };

  const fetchCashbackCount = async () => {
    try {
      const response = await axios.get(`${baseUrl}/statistics/cashback`);
      setCashback(response.data.cashback);
    } catch (error) {
      console.error("Error fetching cashback count: ", error);
    }
  };

  const fetchUsersAndCount = async () => {
    try {
      const response = await axios.get(`${baseUrl}/statistics/users-count`);
      setUsers(response.data.count);
      setUserList(response.data.users);
    } catch (error) {
      console.error("Error fetching users count: ", error);
    }
  };

  const deleteUser = async () => {
    try {
      const response = await axios.delete(`${baseUrl}/admin/user`, {
        data: { id: selectedUser._id },
        headers: {
          "auth-token": authToken,
        },
      });
      if (response.data.success) {
        toast.success(t("User deleted successfully"));
        setUserList((userList) =>
          userList.filter((user) => user._id !== selectedUser?._id)
        );
        setSelectedUser(null);
        setConfirmation(false);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchDailyActiveUsers = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/statistics/daily-active-users`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setDailyActiveUsers(response.data);
    } catch (error) {
      console.error("Error fetching daily active users data: ", error);
    }
  };

  const fetchAverageSessionDuration = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/statistics/average-duration`
      );
      setAvgTime(response.data.avg);
    } catch (error) {
      console.error("Error fetching average session duration: ", error);
    }
  };

  const fetchSubTestUser = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/statistics/subtest-weak-strong`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setAbsolved(response.data.absolvedExercises);
      setAvgScoreSubTest(response.data.averageScore);
      setStrongestSubject(response.data.strongestImg);
      setStrongesSubjectName(response.data.strongestSubTestName);
      setWeakestSubject(response.data.weakestImg);
      setWeakestSubjectName(response.data.weakestSubTestName);
      setFullScore(response.data.fullScoreEntries);
    } catch (error) {
      console.error("Error fetching average session duration: ", error);
    }
  };

  const fetchSubTestScores = async (status) => {
    const subTestOrder = [
      "Muster Zuordnen",
      "MedNat",
      "Schlauchfiguren",
      "Quanti",
      "Figuren lernen",
      "Fakten lernen",
      "Textverständnis",
      "DiaTab",
    ];

    try {
      console.log("API CALL");
      const response = await axios.post(
        `${baseUrl}/statistics/subtest`,
        { status },
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      console.log("Subtest response:", response.data.data);

      // Map through the data and correct the names as needed
      const correctedData = response.data.data.map((subTest) => {
        if (subTest.subTestName.includes("Muster Zuordnendhmsbd")) {
          subTest.subTestName = "Muster Zuordnen";
        }
        // Add more conditions here if other subtest names need correction
        return subTest;
      });

      // Sort the subtest scores according to the predefined order
      const sortedSubTestScores = subTestOrder
        .map((subTestName) =>
          correctedData.find(
            (subTest) => subTest.subTestName.trim() === subTestName
          )
        )
        .filter((subTest) => subTest !== undefined);

      setSubTestScores(sortedSubTestScores);
    } catch (error) {
      console.error("Error fetching subtest scores:", error);
      toast.error(t("Failed to fetch subtest scores"));
    }
  };

  const fetchCashbacks = async () => {
    try {
      const response = await axios.get(`${baseUrl}/settings/cashback`);
      setCashbackList(response.data.cashbacks);
    } catch (error) {
      console.error("Error fetching cashback: ", error);
    }
  };

  const deleteCashback = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/settings/cashback/${id}`);
      toast.success(t("Cashback deleted successfully"));
      setCashbackList(cashbackList.filter((cashback) => cashback._id !== id));
      fetchCashbacks();
    } catch (error) {
      console.error("Error deleting cashback: ", error);
      toast.error(t("Error deleting cashback"));
    }
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/statistics/avg-users`, {
        headers: {
          "auth-token": authToken,
        },
      });
      const avgHoursPerDay =
        response.data.avgHoursPerDay != null && response.data.avgHoursPerDay !== undefined && response.data.avgHoursPerDay !== 0
          ? response.data.avgHoursPerDay
          : 0;

      const avgMinutesPerDay =
        response.data.avgMinutesPerDay != null && response.data.avgMinutesPerDay !== undefined && response.data.avgMinutesPerDay !== 0
          ? response.data.avgMinutesPerDay
          : 0;
      setAvgHourDailyUser(avgHoursPerDay + "," + avgMinutesPerDay + "h ");
      const dailyHours = response.data.dailyHours;
      const days = dailyHours.map((dayData) => dayData.day);
      const hours = dailyHours.map(
        (dayData) => dayData.hours + dayData.minutes / 60
      );

      setChartDataUser({
        labels: days,
        datasets: [
          {
            label: "Hours",
            data: hours,
            backgroundColor: "rgba(75, 192, 192, 0.6)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
            tension: 0.5,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };

  const fetchDaysRegistered = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/statistics/days-registration`,
        {
          headers: {
            "auth-token": authToken,
          },
        }
      );
      setDaysSinceRegistration(response.data.daysSinceRegistration);
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };
  const handleClose = () => {
    setSelectedUser(null);
    setConfirmation(false);
  };
  const fetchEntries = async () => {
    try {
      const authToken = Cookies.get("authToken");
      const response = await axios.get(`${baseUrl}/statistics/all-exercise`, {
        headers: {
          "auth-token": authToken,
        },
      });

      const data = response.data.entries;

      // Extract unique subTestNames for selection options
      const subTestNames = [...new Set(data.map((entry) => entry.subTestName))];
      setSubTestOptions(subTestNames);

      setEntries(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching entries:", error);
      toast.error(t("Failed to fetch data"));
      setLoading(false);
    }
  };

  const updateChartData = (subTestName) => {
    const filteredEntries = entries.filter(
      (entry) => entry.subTestName === subTestName
    );
    if (!filteredEntries || filteredEntries.length === 0) {
      setChartDataSubTest({
        labels: [],
        datasets: [],
      });
      return;
    }
    const labels = filteredEntries.map((entry) =>
      new Date(entry.createdAt).toLocaleDateString()
    );
    const dataset = filteredEntries.map((entry) =>
      parseFloat(entry.result.split("/")[0])
    );

    const chartData = {
      labels: labels,
      datasets: [
        {
          label: `${subTestName} ${t("Scores")}`,
          data: dataset,
          fill: false,
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "rgba(75, 192, 192, 0.2)",
          tension: 0.5,
        },
      ],
    };

    setChartDataSubTest(chartData);
  };

  useEffect(() => {
    if (role === "admin") {
      Promise.all([
        fetchData(),
        fetchTicketData(),
        fetchCashbackCount(),
        fetchUsersAndCount(),
        fetchUserCreationStats(),
        fetchDailyActiveUsers(),
        fetchAverageSessionDuration(),
        fetchCashbacks(),
      ])
        .then(() => setLoading(false))
        .catch((error) => {
          console.error("Error during data fetching:", error);
          setLoading(false);
        });
    } else {
      Promise.all([
        fetchUserData(),
        fetchData(),
        fetchDaysRegistered(),
        fetchSubTestUser(),
        fetchEntries(),
        fetchSubTestScores(status), // Initial fetch with default status
      ])
        .then(() => setLoading(false))
        .catch((error) => {
          console.error("Error during data fetching:", error);
          setLoading(false);
        });
    }
  }, []);

  // useEffect(() => {
  //   fetchSubTestScores(status);
  // }, [status]);

  // useEffect(() => {
  //   if (selectedSubTest) {
  //     updateChartData(selectedSubTest);
  //   }
  // }, [selectedSubTest, entries]);

  const handleDelete = (user) => {
    setSelectedUser(user);
    setConfirmation(true);
  };

  const chartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: t("Well Being Factors"),
        data: data.wellBeingFactors,
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
        tension: 0.5,
      },
      {
        label: t("Stress Level Factors"),
        data: data.stressLevelFactors,
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.2)",
        tension: 0.5,
      },
      {
        label: t("Happiness Factors"),
        data: data.happinessFactors,
        fill: false,
        backgroundColor: "rgb(255, 206, 86)",
        borderColor: "rgba(255, 206, 86, 0.2)",
        tension: 0.5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: theme === "dark" ? "white" : "black", // Dark theme: white labels, otherwise black
        },
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)", // Dark background for tooltips
        titleFontColor: theme === "dark" ? "white" : "black", // Conditionally change color
        bodyFontColor: theme === "dark" ? "white" : "black", // Conditionally change color
      },
    },
    scales: {
      x: {
        grid: {
          color:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.1)"
              : "rgba(0, 0, 0, 0.1)", // Lighter grey for dark theme, darker for light theme
        },
        ticks: {
          color: theme === "dark" ? "white" : "black", // Conditionally change color
        },
        title: {
          display: true,
          text: "Month",
          color: theme === "dark" ? "white" : "black", // Conditionally change color
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.1)"
              : "rgba(0, 0, 0, 0.1)", // Same as x-axis
        },
        ticks: {
          color: theme === "dark" ? "white" : "black", // Conditionally change color
        },
        title: {
          display: true,
          text: "Count",
          color: theme === "dark" ? "white" : "black", // Conditionally change color
        },
      },
    },
  };

  const ticketChartData = {
    labels: ticketData.map((item) => monthNames[item.month - 1]), // Use month names
    datasets: [
      {
        label: t("Number of Tickets"),
        data: ticketData.map((item) => item.count),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        borderColor: "rgba(53, 162, 235, 1)",
        borderWidth: 1,
        tension: 0.6,
      },
    ],
  };

  const userCreationChartData = {
    labels: userCreationStats.map((item) => monthNames[item.month - 1]), // Use month names
    datasets: [
      {
        label: t("User Creations Per Month"),
        data: userCreationStats.map((item) => item.count),
        borderColor: "rgba(255, 206, 86, 0.8)",
        backgroundColor: "rgba(255, 206, 86, 0.5)",
        fill: false,
        tension: 0.6,
      },
    ],
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = userList.slice(indexOfFirstItem, indexOfLastItem);

  const indexOfLastItemCashback = currentPageCashback * itemsPerPageCashback;
  const indexOfFirstItemCashback =
    indexOfLastItemCashback - itemsPerPageCashback;
  const currentCashbacks = cashbackList.slice(
    indexOfFirstItemCashback,
    indexOfLastItemCashback
  );

  const paginate = (pageNumber) => {
    if (
      pageNumber > 0 &&
      pageNumber <= Math.ceil(users.length / itemsPerPage)
    ) {
      setCurrentPage(pageNumber);
    }
  };

  const paginateCashback = (pageNumber) => {
    if (
      pageNumber > 0 &&
      pageNumber <= Math.ceil(cashbackList.length / itemsPerPageCashback)
    ) {
      setCurrentPageCashback(pageNumber);
    }
  };

  const getResponsiveImgStyles = () => {
    const width = window.innerWidth;
    if (width < 600) {
      return {
        height: "45px",
        width: "110px",
        cursor: "pointer",
        marginRight: "20px",
      };
    }
    return {
      height: "45px",
      width: "120px",
      cursor: "pointer",
      marginRight: "20px",
    };
  };

  // for the activation period card
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const isLeapYear =
    (currentYear % 4 === 0 && currentYear % 100 !== 0) ||
    currentYear % 400 === 0;
  const totalDaysInYear = isLeapYear ? 366 : 365;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const toggleCompletion = async (cashbackId) => {
    // First, optimistically update the state
    setCashbackList((prevCashbacks) => {
      return prevCashbacks.map((cb) =>
        cb.Id === cashbackId ? { ...cb, completed: !cb.completed } : cb
      );
    });

    try {
      const response = await axios.post(
        `${baseUrl}/settings/complete-cashback/${cashbackId}`
      );
      if (response.status === 200) {
        // Confirm the update was successful, no need to do anything if the optimistic update was correct
        toast.success("Cashback status updated");
      } else {
        // If the response status is not successful, revert the change
        throw new Error("Failed to update cashback");
      }
    } catch (error) {
      console.error("Error updating cashback:", error);
      toast.error("Error updating cashback");
      // Revert the optimistic update if there was an error
      setCashbackList((prevCashbacks) => {
        return prevCashbacks.map((cb) =>
          cb.Id === cashbackId ? { ...cb, completed: !cb.completed } : cb
        );
      });
    }
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    console.log(newStatus);
    setStatus(newStatus);
    await fetchSubTestScores(newStatus);
  };

  const subTestChartData = {
    labels: Array.from(
      {
        length: Math.max(
          ...subTestScores.map((subTest) => subTest.scores.length)
        ),
      },
      (_, i) => i + 1
    ), // X-axis labels as 1, 2, 3, etc.
    datasets: subTestScores.map((subTest, index) => ({
      label: subTest.subTestName.trim(),
      data: subTest.scores.map((score) => parseInt(score, 10)), // Y-axis as actual scores
      borderColor: `hsl(${index * 40}, 70%, 50%)`,
      backgroundColor: `hsla(${index * 40}, 70%, 50%, 0.5)`,
      fill: false,
    })),
  };

  const subTestChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          font: {
            family: "Montserrat",
          },
          color: isDarkTheme ? "white" : "gray",
        },
      },
      tooltip: {
        mode: "index",
        intersect: false,
        axis: "x",
        bodyFont: {
          family: "Montserrat",
          color: isDarkTheme ? "white" : "gray",
        },
        titleFont: {
          family: "Montserrat",
          color: isDarkTheme ? "white" : "gray",
        },
      },
      crosshair: {
        line: {
          color: "blue",
          width: 1,
        },
        sync: {
          enabled: false,
          group: 1,
          suppressTooltips: false,
        },
        zoom: {
          enabled: false,
          zoomboxBackgroundColor: "rgba(66,133,244,0.2)",
          zoomboxBorderColor: "#48F",
          zoomButtonText: "Reset Zoom",
          zoomButtonClass: "reset-zoom",
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Übungsnummer",
          font: {
            family: "Montserrat",
          },
          color: isDarkTheme ? "white" : "gray",
          padding: 25,
        },
        ticks: {
          font: {
            family: "Montserrat",
          },
          color: isDarkTheme ? "white" : "gray",
        },
        grid: {
          display: true,
          color: isDarkTheme ? "gray" : "#d3d3d3",
        },
      },
      y: {
        title: {
          display: true,
          text: "Punktzahl",
          font: {
            family: "Montserrat",
          },
          color: isDarkTheme ? "white" : "gray",
        },
        ticks: {
          stepSize: 4,
          color: isDarkTheme ? "white" : "gray",
          font: {
            family: "Montserrat",
          },
        },
        grid: {
          display: true,
          color: isDarkTheme ? "gray" : "#d3d3d3",
        },
        beginAtZero: true,
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    elements: {
      line: {
        borderColor: isDarkTheme ? "white" : "gray",
        tension: 0.4,
      },
    },
    layout: {
      backgroundColor: isDarkTheme ? "gray" : "white",
    },
  };

  return (
    <>
      <div className="dashContainer">
        <Header title={t("Statistics")} />
        {loading ? (
          <div className="loader-container">
            <TailSpin
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <div className="archive">
            <div className="star1">
              <div></div>
              <div className="starfilter">
                <button
                  className="numberf button-animate"
                  onClick={() => setShowCharts(!showCharts)}
                  style={{
                    backgroundColor: theme === "dark" ? "black" : "white",
                    borderColor: theme === "dark" ? "white" : "#D8D9DA",
                    color: theme === "dark" ? "white" : "black",
                  }}
                >
                  <img
                    src={bar}
                    alt=""
                    className="bar"
                    style={{
                      filter: theme === "dark" ? "invert(100%)" : "none",
                    }}
                  />
                  <span className="kuu">
                    {showCharts ? t("Numbers") : t("Graphs")}
                  </span>
                </button>
              </div>
            </div>
            {!showCharts ? (
              <>
                {role === "admin" ? (
                  <>
                    <div className="statisticsList">
                      <div className="staisticsCard">
                        <div className="fgj">
                          <h1 className="wo">{t("Total Users")}</h1>
                          <h1 className="hr">
                            {users ? users : <p>{t("Loading")}</p>}
                          </h1>
                        </div>
                        <img src={wave} alt="" className="wave" />
                      </div>
                      <div className="staisticsCard">
                        <div className="fgj">
                          <h1 className="wo">{t("Total Cashback")}</h1>
                          <h1 className="hr">
                            {cashback ? cashback : <p>{t("Loading")}</p>}
                          </h1>
                        </div>
                        <img src={wave} alt="" className="wave" />
                      </div>
                      <div className="staisticsCard">
                        <div className="fgj">
                          <h1 className="wo">{t("Active Users")}</h1>
                          <h1 className="hr">
                            {dailyActiveUsers.dailyCount ? (
                              dailyActiveUsers.dailyCount
                            ) : (
                              <p>{t("Loading")}</p>
                            )}
                          </h1>
                        </div>
                        <img src={wave} alt="" className="wave" />
                      </div>
                      <div className="staisticsCard">
                        <div className="fgj">
                          <h1 className="wo">{t("Weekly Users")}</h1>
                          <h1 className="hr">
                            {dailyActiveUsers.weeklyCount ? (
                              dailyActiveUsers.weeklyCount
                            ) : (
                              <p>{t("Loading")}</p>
                            )}
                          </h1>
                        </div>
                        <img src={wave} alt="" className="wave" />
                      </div>
                      <div className="staisticsCard">
                        <div className="fgj">
                          <h1 className="wo">{t("Monthly Users")}</h1>
                          <h1 className="hr">
                            {dailyActiveUsers.monthlyCount ? (
                              dailyActiveUsers.monthlyCount
                            ) : (
                              <p>{t("Loading")}</p>
                            )}
                          </h1>
                        </div>
                        <img src={wave} alt="" className="wave" />
                      </div>
                    </div>

                    <div className="userListContainer">
                      <div className="user-table">
                        <h2 style={{ padding: "10px", margin: "0 30px" }}>
                          {t("User List")}
                        </h2>
                        <table className="userList">
                          <thead>
                            <tr>
                              <th>{t("Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Password")}</th>
                              <th>{t("Last Login")}</th>
                              <th>{t("Days created")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((user) => (
                              <tr key={user._id}>
                                <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>
                                  {user.changed
                                    ? "Password Changed"
                                    : user.textPassword || "Not Set"}
                                </td>
                                <td>
                                  {user.lastLogin
                                    ? new Date(user.lastLogin).toLocaleString()
                                    : t("Not logged")}
                                </td>
                                <td>
                                  {user.daysSinceCreation
                                    ? user.daysSinceCreation
                                    : "NA"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="pagination">
                          <button
                            className="pagination-button"
                            onClick={() => paginate(currentPage - 1)}
                          >
                            {t("Previous")}
                          </button>
                          <span className="pagination-info">
                            {t("Page")} {currentPage} {t("of")}{" "}
                            {Math.ceil(userList.length / itemsPerPage)}
                          </span>
                          <button
                            className="pagination-button"
                            onClick={() => paginate(currentPage + 1)}
                          >
                            {t("Next")}
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Cashback */}
                    <div className="userListContainer">
                      <div className="user-table">
                        <h2 style={{ padding: "10px", margin: "0 30px" }}>
                          {t("Cashback List")}
                        </h2>
                        <table className="userList">
                          <thead>
                            <tr>
                              <th>{t("Name")}</th>
                              <th>{t("Email")}</th>
                              <th>{t("Url")}</th>
                              <th>{"Date"}</th>
                              <th>{t("Delete")}</th>
                              <th>{t("Complete")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentCashbacks.map((cashback) => (
                              <tr key={cashback._id}>
                                <td>{cashback.name}</td>
                                <td>{cashback.email}</td>
                                <td>{cashback.url}</td>
                                <td>
                                  {new Date(
                                    cashback.createdAt
                                  ).toLocaleString()}
                                </td>
                                <td>
                                  <div
                                    style={{ cursor: "pointer", color: "red" }}
                                    onClick={() => {
                                      deleteCashback(cashback._id);
                                    }}
                                  >
                                    <FaTrash />
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    checked={cashback.completed}
                                    onChange={() =>
                                      toggleCompletion(cashback.Id)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="pagination">
                          <button
                            className="pagination-button"
                            onClick={() =>
                              paginateCashback(currentPageCashback - 1)
                            }
                          >
                            {t("Previous")}
                          </button>
                          <span className="pagination-info">
                            {t("Page")} {currentPageCashback} {t("of")}{" "}
                            {Math.ceil(
                              cashbackList.length / itemsPerPageCashback
                            )}
                          </span>
                          <button
                            className="pagination-button"
                            onClick={() =>
                              paginateCashback(currentPageCashback + 1)
                            }
                          >
                            {t("Next")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <div className="statisticsList">
                      {personalized["Ø Hours/Day"] && (
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">
                              {t("Average Daily Sessions")}
                            </h1>
                            <h1
                              className="hr"
                              style={{ margin: "0px 6px", fontSize: "35px" }}
                            >
                              {avgHourDailyUser || avgHourDailyUser === 0 ? (
                                avgHourDailyUser
                              ) : (
                                <p>{t("Loading")}</p>
                              )}
                            </h1>
                            <p>&nbsp;</p>
                          </div>
                          <img src={wave} alt="" className="wave" />
                        </div>
                      )}

                      {personalized["Well-being"] && (
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">
                              {t("Average Well-Being Level")}
                            </h1>
                            <h1
                              className="hr"
                              style={{ margin: "0px 6px", fontSize: "35px" }}
                            >
                              {wellBeingFactorsUser ||
                                wellBeingFactorsUser === 0 ? (
                                Number.isInteger(
                                  parseFloat(wellBeingFactorsUser)
                                ) ? (
                                  parseInt(wellBeingFactorsUser)
                                ) : (
                                  wellBeingFactorsUser
                                    .toString()
                                    .replace(".", ",")
                                )
                              ) : (
                                <p>{t("Loading")}</p>
                              )}
                            </h1>
                            <p>&nbsp;</p>
                          </div>
                          <img src={wave} alt="" className="wave" />
                        </div>
                      )}

                      {personalized["Stress Level"] && (
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">{t("Average Stress Level")}</h1>
                            <h1
                              className="hr"
                              style={{ margin: "0px 6px", fontSize: "35px" }}
                            >
                              {stressLevelFactorsUser ||
                                stressLevelFactorsUser == 0 ? (
                                Number.isInteger(
                                  parseFloat(stressLevelFactorsUser)
                                ) ? (
                                  parseInt(stressLevelFactorsUser)
                                ) : (
                                  stressLevelFactorsUser
                                    .toString()
                                    .replace(".", ",")
                                )
                              ) : (
                                <p>{t("Loading")}</p>
                              )}
                            </h1>
                            <p>&nbsp;</p>
                          </div>
                          <img src={wave} alt="" className="wave" />
                        </div>
                      )}

                      {personalized["Absolved Exercises"] && (
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">{t("Absolved Exercises")}</h1>
                            <h1
                              className="hr"
                              style={{ margin: "0px 6px", fontSize: "35px" }}
                            >
                              {absolved ? absolved : <p>{t("Loading")}</p>}
                            </h1>
                          </div>
                          <p>&nbsp;</p>
                          <img
                            src={theme === "dark" ? black : white}
                            alt=""
                            className={`wave`}
                          />
                        </div>
                      )}

                      {personalized["Strength"] && (
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">{t("Strength")}</h1>
                            {/* <h1
                              className="hr"
                              style={{ margin: "0px 6px", fontSize: "35px" }}
                            >
                              {strongestSubject || strongestSubject == 0 ? (
                                strongestSubject
                              ) : (
                                <p>{t("Loading")}</p>
                              )}
                            </h1> */}
                            <p
                              style={{ margin: "0px 6px", fontWeight: "bold" }}
                            >
                              {strongestSubjectName === "" ? (
                                strongestSubjectName === "No data" ? (
                                  " "
                                ) : (
                                  strongestSubjectName
                                )
                              ) : (
                                <p></p>
                              )}
                            </p>
                            <div className="center-img">
                              <img
                                src={strongestSubject}
                                alt=""
                                className="responsive-img"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {personalized["Weakness"] && (
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">{t("Weakness")}</h1>
                            {/* <h1
                              className="hr"
                              style={{ margin: "0px 6px", fontSize: "35px" }}
                            >
                              {weakestSubject || weakestSubject == 0 ? (
                                weakestSubject
                              ) : (
                                <p>{t("Loading")}</p>
                              )}
                            </h1> */}
                            <p
                              style={{ margin: "0px 6px", fontWeight: "bold" }}
                            >
                              {weakestSubjectName === "" ? (
                                weakestSubjectName === "No data" ? (
                                  " "
                                ) : (
                                  weakestSubjectName
                                )
                              ) : (
                                <p></p>
                              )}
                            </p>
                            <div className="center-img">
                              <img
                                src={weakestSubject}
                                alt=""
                                className="responsive-img"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {personalized["Ø/24"] && (
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">
                              {t("Average Exercise Score")}
                            </h1>
                            <h1
                              className="hr"
                              style={{ margin: "0px 6px", fontSize: "35px" }}
                            >
                              {avgScoreSubTest || avgScoreSubTest === 0 ? (
                                Number.isInteger(
                                  parseFloat(avgScoreSubTest)
                                ) ? (
                                  parseInt(avgScoreSubTest)
                                ) : (
                                  avgScoreSubTest.toString().replace(".", ",")
                                )
                              ) : (
                                <p>{t("Loading")}</p>
                              )}
                            </h1>
                            <p>&nbsp;</p>
                          </div>
                          <img src={wave} alt="" className="wave" />
                        </div>
                      )}

                      {personalized["24/24"] && (
                        <div className="staisticsCard">
                          <div className="fgj">
                            <h1 className="wo">{t("Full Score exercises")}</h1>
                            <h1
                              className="hr"
                              style={{ margin: "0px 6px", fontSize: "35px" }}
                            >
                              {fullScore || fullScore == 0 ? (
                                fullScore
                              ) : (
                                <p>{t("Loading")}</p>
                              )}
                            </h1>
                            <p>&nbsp;</p>
                          </div>
                          <img
                            src={theme === "dark" ? black : white}
                            alt=""
                            className={`wave`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div>
                {role === "admin" ? (
                  <div>
                    <div className="chartContainer">
                      <h1 style={{ margin: "10px", marginBottom: "20px" }}>
                        {t("Ticket Statistics")}
                      </h1>
                      <Bar data={ticketChartData} options={options} />
                    </div>
                    <br />
                    <br />
                    <div className="chartContainer">
                      <h1 style={{ margin: "10px", marginBottom: "20px" }}>
                        {t("User Creation Statistics")}
                      </h1>
                      <Line data={userCreationChartData} options={options} />
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="chart-and-filter-container">
                      <div
                        className="filter-container"
                        style={{
                          backgroundColor: theme === "dark" ? "#333" : "white",
                          color: theme === "dark" ? "white" : "black",
                          borderColor:
                            theme === "dark"
                              ? "#555"
                              : "var(--select-border-color)",
                        }}
                      >
                        <div className="filter-inner">
                          <Calendar className="calendar-icon" />
                          <select
                            id="status-select"
                            value={status}
                            onChange={handleStatusChange}
                            style={{
                              backgroundColor:
                                theme === "dark" ? "#333" : "white",
                              color: theme === "dark" ? "white" : "black",
                              borderColor:
                                theme === "dark"
                                  ? "#555"
                                  : "var(--select-border-color)",
                            }}
                          >
                            <option value="This Week">
                              {t("Diese Woche")}
                            </option>
                            <option value="This Month">
                              {t("Dieser Monat")}
                            </option>
                            <option value="all">{t("Gesamt")}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {showCharts && (
                      <div className="chart" style={{ paddingBottom: 0 }}>
                        <div className="chart-inner">
                          <Line
                            data={subTestChartData}
                            options={subTestChartOptions}
                          />
                        </div>
                        <br />
                        <br />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {confirmation && (
              <ConfirmationScreen
                onClose={() => setConfirmation(false)}
                onOkay={deleteUser}
                data={selectedUser}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Statistics;
