import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/MedicalLibrary.css";
import { FiPlusCircle } from "react-icons/fi";
import { UploadContent } from "./UploadContent";
import { LibraryView } from "./LibraryView";
import axios from "axios";
import { IoMdSearch } from "react-icons/io";
import baseUrl from "../baseUrl";
import { toast } from "sonner";
import { FaFlag } from "react-icons/fa";
import { MdVerified } from "react-icons/md";
import { SuccessScreen } from "./SuccessScreen";
import MedicLogo from "../assets/icon/32.png";
import { VscVerified, VscVerifiedFilled } from "react-icons/vsc";
import { ReportingScreen } from "./ReportingScreen";
import { useTranslation } from "react-i18next";
import back from "../assets/images/back.svg";
import Cookies from "js-cookie";

export const MedicalLibrary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [clicked, setClicked] = useState(false);
  const [selectedLibrary, setSelectedLibrary] = useState(null);
  const [libraries, setLibraries] = useState([]);
  const role = localStorage.getItem("Role");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [filteredLibraries, setFilteredLibraries] = useState([]);
  const [showOnlyVerified, setShowOnlyVerified] = useState(false);
  const [verifyingLibraryId, setVerifyingLibraryId] = useState(null);
  const [successScreen, setSuccessScreen] = useState(false);
  const [description, setDescription] = useState("");
  const token = Cookies.get("authToken");

  const [libForReporting, setLibForReporting] = useState(null);

  const getLibraries = async () => {
    try {
      const response = await axios.get(`${baseUrl}/library/`);
      if (response && response.data) {
        setLibraries(response.data.libraryItems);
        setFilteredLibraries(response.data.libraryItems);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getLibraries();
  }, []);

  const handleSearch = (searchText) => {
    let filteredLibraries;
    if (searchText.trim() === "") {
      filteredLibraries = libraries;
    } else {
      filteredLibraries = libraries.filter((library) => {
        const title = library?.title?.toLowerCase();
        const description = library?.description?.toLowerCase();
        const search = searchText?.toLowerCase();
        return title?.includes(search) || description?.includes(search);
      });
    }

    setFilteredLibraries(filteredLibraries);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}.${month}.${year}`;
  }

  function isLibraryNew(updatedAt) {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const today = new Date();
    const libraryDate = new Date(updatedAt);
    const ageInDays = Math.round((today - libraryDate) / oneDay);
    return ageInDays <= 7;
  }

  const handleVerification = async (event, libraryId) => {
    event.stopPropagation();

    try {
      setVerifyingLibraryId(libraryId); // Set the current library ID being verified
      setVerifyLoading(true);
      const response = await axios.patch(
        `${baseUrl}/library/toggle-verification`,
        {
          libraryId,
        }
      );
      if (response.data) {
        setLibraries(
          libraries.map((library) =>
            library.libraryId === libraryId
              ? { ...library, verified: !library.verified }
              : library
          )
        );
        setFilteredLibraries(
          filteredLibraries.map((library) =>
            library.libraryId === libraryId
              ? { ...library, verified: !library.verified }
              : library
          )
        );
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setVerifyLoading(false);
      setVerifyingLibraryId(null);
    }
  };

  const handleVerify = () => {
    setShowOnlyVerified(!showOnlyVerified);
    if (!showOnlyVerified) {
      setFilteredLibraries(libraries.filter((library) => library.verified));
    } else {
      setFilteredLibraries(libraries);
    }
  };
  const handleBackClick = () => {
    navigate(-1);
  };

  const handleReporting = (libraryId, e) => {
    e.preventDefault();
    e.stopPropagation();
    setLibForReporting(libraryId);
  };

  const handleReport = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/report/report-item`,
        {
          itemId: libForReporting,
          itemType: "medical-library",
          reportMessage: description,
        },
        {
          headers: {
            "auth-token": token,
          },
        }
      );
      if (response.status === 200) {
        toast.success(t("Library item reported!"));
      }
      console.log("Content Reported!");
    } catch (error) {
      console.error("Error:", error);
      toast.error(t("Failed to update library item report flag"));
    } finally {
      setLibForReporting(false);
    }
  };

  const handleClose = () => {
    setLibForReporting(null);
    setDescription("");
  };

  return (
    <div className="labLessonContainer">
      <div className="headerContainer" style={{ justifyContent: "start" }}>
        <button
          className="backButton"
          onClick={handleBackClick}
          title={t("Go back to the previous page")}
        >
          <img src={back} alt="" className="backk button-animate" />
        </button>
      </div>

      <div className="medical-library">
        <div className="medical-top">
          <div className="logo-or-img">
            <img src={MedicLogo} alt="medic-logo" />
          </div>
          <h1 className="lkjlk">{t("Medical Library")}</h1>

          <div className="query-input-container">
            <div className="main-query-container">
              <div className="main-input-query">
                <div className="library-input-container">
                  <IoMdSearch
                    color="#ccc"
                    fontWeight={"bold"}
                    className="font-icon"
                  />
                  <input
                    type="text"
                    value={query}
                    className="comment-input lib-input"
                    placeholder={t("Search for library")}
                    onChange={(e) => {
                      setQuery(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    style={{ width: "70%" }}
                    title={t("Enter search text")}
                  />
                </div>

                <button
                  className={`send-query-btn padding ${
                    showOnlyVerified ? "filter" : "filtering"
                  }`}
                  onClick={() => handleVerify()}
                  title={t("Toggle verified items")}
                >
                  <VscVerifiedFilled
                    className="verified-icon"
                    color={showOnlyVerified ? "#5592ff" : "white"}
                  />
                </button>
                <button
                  className="send-query-btn button-animate"
                  onClick={() => setClicked(true)}
                  title={t("Add new content")}
                >
                  <FiPlusCircle color="white" />
                  {t("Add Content")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="cards-container">
          {filteredLibraries?.map((library, id) => (
            <div
              className="card1 medi-card button-animate"
              key={id}
              onClick={() => setSelectedLibrary(library)}
              title={t("Click to view details")}
            >
              <div className="lib-icon-container">
                {role === "admin" ? (
                  verifyingLibraryId === library.libraryId && verifyLoading ? (
                    <span className="medi-loader"></span>
                  ) : library.verified ? (
                    <VscVerifiedFilled
                      className="verified-icon-medic in-card"
                      color="#3897F0"
                      onClick={(event) =>
                        handleVerification(event, library.libraryId)
                      }
                      title={t("Unverify this library item")}
                    />
                  ) : (
                    <VscVerified
                      className="verified-icon-medic"
                      onClick={(event) =>
                        handleVerification(event, library.libraryId)
                      }
                      title={t("Verify this library item")}
                    />
                  )
                ) : (
                  library.verified === true && (
                    <MdVerified
                      className="verified-icon-medic"
                      color="#3897F0"
                      title={t("Verified library item")}
                    />
                  )
                )}
                <div>
                  <FaFlag
                    color={library.report ? "#3897F0" : "#cac3c3"}
                    cursor={"pointer"}
                    onClick={(e) => handleReporting(library._id, e)}
                    className="flag-icon"
                    title={t("Report this library item")}
                  />
                </div>
              </div>

              {isLibraryNew(library.updatedAt) && (
                <div
                  className="cardNewBadge new-badge"
                  title={t("New library item")}
                >
                  {t("NEW")}
                </div>
              )}
              <div className="card-content">
                <h3 style={{ fontWeight: "700" }}>{library.title}</h3>
                <h6>
                  {library.role === "admin" ? "MedGang" : "MedGangmember"}{" "}
                </h6>
                <h6>{formatDate(library.updatedAt)}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
      {libForReporting && (
        <ReportingScreen
          onClose={handleClose}
          onOkay={handleReport}
          description={description}
          setDescription={setDescription}
        />
      )}
      {clicked && (
        <UploadContent
          onClose={() => setClicked(false)}
          setSuccessScreen={() => setSuccessScreen(true)}
          setLibraries={setFilteredLibraries}
          libraries={filteredLibraries}
        />
      )}
      {selectedLibrary && (
        <LibraryView
          selectedLibrary={selectedLibrary}
          onClose={() => setSelectedLibrary(null)}
        />
      )}
      {successScreen && (
        <SuccessScreen
          onClose={() => setSuccessScreen(false)}
          title={t("New Medical Entry")}
        />
      )}
    </div>
  );
};
