import React, { useEffect, useState } from "react";
import axios from "axios";
import baseUrl from "../baseUrl";
import "../styles/Report.css";
import { TailSpin } from "react-loader-spinner";
import { FaTrash } from "react-icons/fa";
import { toast } from "sonner"
import { useTranslation } from "react-i18next"; // Import useTranslation

const Report = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const [reportedData, setReportedData] = useState({
    reportedRequests: [],
    reportedMedicalLibraries: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReportedData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/statistics/reported`);
        const response2 = await axios.get(`${baseUrl}/report/all-reports`);
        console.log(response2.data);
        // console.log(response.data);
        if (response.data.success) {
          setReportedData({
            reportedRequests: response.data.data.reportedRequests,
            reportedMedicalLibraries: response2.data.reports,
          });
        }
      } catch (error) {
        console.error("Error fetching reported content:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReportedData();
  }, []);

  const handleRequestReportFalse = async (requestId) => {
    try {
      await axios.patch(
        `${baseUrl}/association/updateRequestAdmin/${requestId}`
      );
      toast.success(t("Request report flag updated successfully"));
      setReportedData((prevState) => ({
        ...prevState,
        reportedRequests: prevState.reportedRequests.filter(
          (request) => request.requestId !== requestId
        ),
      }));
    } catch (error) {
      console.error("Error updating request report flag:", error);
      toast.error(t("Error updating request report flag"));
    }
  };

  const handleLibraryReportFalse = async (reportId) => {
    try {
      await axios.delete(`${baseUrl}/report`, {
        data: {
          _id: reportId,
        },
      });
      toast.success(t("Library report flag updated successfully"));
      setReportedData((prevState) => ({
        ...prevState,
        reportedMedicalLibraries: prevState.reportedMedicalLibraries.filter(
          (library) => library._id !== reportId
        ),
      }));
    } catch (error) {
      console.error("Error updating library report flag:", error);
      toast.error(t("Error updating library report flag"));
    }
  };

  return (
    <div className="dashContainer">
      <div className="reported-content-container">
        <h2 style={{ padding: "30px 0px" }}>{t("Reported Content")}</h2>
        <div className="reported-section">
          <h3 style={{ margin: "20px 0px" }}>
            {t("Reported Association Content")}
          </h3>
          <table>
            <thead>
              <tr>
                <th>{t("Request ID")}</th>
                <th>{t("User Email")}</th>
                <th>{t("Title")}</th>
                <th>{t("Description")}</th>
                <th>{t("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {reportedData.reportedRequests.length > 0 ? (
                reportedData.reportedRequests.map((request, index) => (
                  <tr
                    key={request._id}
                    className={index % 2 === 0 ? "even-row" : "odd-row"}
                  >
                    <td data-label={t("Request ID")}>
                      {request.requestId || "N/A"}
                    </td>
                    <td data-label={t("User Email")}>
                      {request.userEmail || "N/A"}
                    </td>
                    <td data-label={t("Title")}>{request.title || "N/A"}</td>
                    <td data-label={t("Description")}>
                      {request.description || "N/A"}
                    </td>
                    <td data-label={t("Action")}>
                      <FaTrash
                        className="action-icon"
                        onClick={() =>
                          handleRequestReportFalse(request.requestId)
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">{t("No reported requests found")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="reported-section">
          <h3 style={{ margin: "30px 0px" }}>
            {t("Reported Medical Libraries")}
          </h3>
          <table>
            <thead>
              <tr>
                <th>{t("Library ID")}</th>
                <th>{t("Title")}</th>
                <th>{t("Description")}</th>
                <th>{t("User Email")}</th>
                <th>{t("PDF URL")}</th>
                <th>{t("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {reportedData.reportedMedicalLibraries?.length > 0 ? (
                reportedData.reportedMedicalLibraries?.map((library, id) => (
                  <tr key={id}>
                    <td data-label={t("Library ID")}>
                      {library?.itemId || "N/A"}
                    </td>
                    <td data-label={t("Title")}>{library?.library?.[0]?.title || "N/A"}</td>
                    <td data-label={t("Description")}>
                      {library?.reportMessage || "N/A"}
                    </td>
                    <td data-label={t("User Email")}>
                      {library?.user?.[0]?.email || "N/A"}
                    </td>
                    <td data-label={t("PDF URL")}>
                      <a
                        href={library?.library?.[0]?.pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("PDF Link")}
                      </a>
                    </td>
                    <td data-label={t("Action")}>
                      <FaTrash
                        className="action-icon"
                        onClick={() =>
                          handleLibraryReportFalse(library._id)
                        }
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">
                    {t("No reported medical libraries found")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Report;
